import { ActionTypes } from "./../../Constants/ActionTypes";
import {layoutTypes, layoutModeTypes, layoutWidthTypes, topBarThemeTypes, leftBarThemeImageTypes, leftSidebarTypes, leftSideBarThemeTypes} from "./../../Constants/Layouts";
const initialState = {
    layoutType: layoutTypes.VERTICAL,
    layoutModeType: layoutModeTypes.LIGHT,
    layoutWidth: layoutWidthTypes.FLUID,
    leftSideBarTheme: leftSideBarThemeTypes.DARK,
    leftSideBarThemeImage: leftBarThemeImageTypes.NONE,
    leftSideBarType: leftSidebarTypes.DEFAULT,
    topbarTheme: topBarThemeTypes.LIGHT,
    isMobile: false,
    showSidebar: true,
    leftMenu: false,
    scrollable:false
};
const showRightSidebarState = {
    showRightSidebar: false
};


export function layout(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LAYOUT_OPTIONS:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}
export function showRight(state = showRightSidebarState, action) {
    switch (action.type) {
        case ActionTypes.SHOW_RIGHT_SIDEBAR:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}
