import { ActionTypes } from "../../Constants/ActionTypes";

const websiteState={
    websiteList:[],
    websitename:"",
    editwebsitename:"",
    id:""
}

export function website(state=websiteState,action){
    switch(action.type){
        case ActionTypes.WEBSITE_LIST:
            return{
                ...state,
                ...action.payload
            };
            default:
                return state;
    }
}