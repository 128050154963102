export const ActionTypes = {
    ABOUT: 'ABOUT',
    LAYOUT_OPTIONS:'LAYOUT_OPTIONS',
    SHOW_RIGHT_SIDEBAR:'SHOW_RIGHT_SIDEBAR',
    LAYOUT_TYPE:'LAYOUT_TYPE',
    LAYOUT_MODE_TYPE:'LAYOUT_MODE_TYPE',
    LAYOUT_WIDTH:'LAYOUT_WIDTH',
    LEFT_SIDEBAR_THEME:'LEFT_SIDEBAR_THEME',
    LEFT_SIDEBAR_THEME_IMAGE:'LEFT_SIDEBAR_THEME_IMAGE',
    LEFT_SIDEBAR_TYPE:'LEFT_SIDEBAR_TYPE',
    TOPBAR_THEME:'TOPBAR_THEME',
    IS_PRELOADER:'IS_PRELOADER',
    IS_MOBILE:'IS_MOBILE',
    SHOW_SIDEBAR:'SHOW_SIDEBAR',
    LEFT_MENU:'LEFT_MENU',
    LOGIN:'LOGIN',
    SIGNUP:'SIGNUP',
    PAGES:'PAGES',
    BLOG_ADD:'BLOG_ADD',
    EDIT_BLOG:'EDIT_BLOG',
    BLOG_CATEGORY:'BLOG_CATEGORY',
    CSR:'CSR',
    CSR_PAGES:'CSR_PAGES',
    CSR_BLOG_CATEGORY:'CSR_BLOG_CATEGORY',
    CSR_BLOG_ADD:'CSR_BLOG_ADD',
    CSR_EDIT_BLOG:'CSR_EDIT_BLOG',
    WEBSITE_LIST:'WEBSITE_LIST',
    DASHBOARD:'DASHBOARD',
    LANG:"LANG"
}