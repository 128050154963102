import React, { useState } from "react";
import { Button, Card, CardBody } from "reactstrap";
import ApiGateWay from "../../../Utils/ApiCall";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { removeCookie } from "../../../Utils";
import { enLang, arLang } from "../../../Constants/en_lang";
import { getCookie } from "../../../Utils/index";
import { reloadWindowToPath } from "gfdu";
export const ActiveStep = () => {
  const currentLang = getCookie("foloosi_lang") === "ar" ? arLang : enLang;
  const Navigate = useNavigate();
  const { dashboardlist } = useSelector((state) => state);

  const handlesubmit = () => {
    const activestate =
      dashboardlist.businessListInfo?.aggregator_signup_status;
    if (
      activestate?.step_1_status !== "completed" ||
      activestate?.step_2_status !== "completed" ||
      activestate?.step_3_status !== "completed" ||
      activestate?.step_4_status !== "completed"
    ) {
      Navigate("/business-details");
    } else {
      let data = {
        merchant_id: dashboardlist.valueauth?.user_no,
      };
      console.log(data, "data");
      // return false;
      ApiGateWay.post(`proceed-verification`, data, (response) => {
        if (response?.message === "Documents sent for verification process.") {
          toast.success(
            "Documents have been sent for verification. Kindly contact info@foloosi.com"
          );
          setTimeout(() => {
            removeCookie("authentication_auth");
            reloadWindowToPath("/login");
          }, 2000);
        } else {
          toast.error(response?.response?.data?.message);
        }
      });
    }
  };
  
  return (
    <div className="container">
      
      <div className="with_100 add-form">
        <Card>
          <CardBody>
            <div>
              <div className="proceed_verify">
                {currentLang.fp_lang_131} <br /> {currentLang.fp_lang_132}
              </div>
              <p className="thank_process">
                {currentLang.fp_lang_133}
                <br />
                <br />
                {currentLang.fp_lang_134}
              </p>
              <Button onClick={handlesubmit} className="my-4 submit_process">
                {currentLang.fp_lang_135}
              </Button>
              <p className="thank_process mt-2">{currentLang.fp_lang_136}</p>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
