import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import {
  disableSubmit,
  getCookie,
  removeToast,
  toastShow,
} from "../../../../../Utils/index";
import { enLang, arLang } from "../../../../../Constants/en_lang";
export const StepOneUnified = (props) => {
  const currentLang = getCookie("foloosi_lang") === "ar" ? arLang : enLang;

  const {
    dashboardList,
    handleOnData,
    handleFileChange,
    unified,
    setUnified,
    clearSelection,
    handlechangeCurrency,
    setAuthorizedType,
    setUploadIndex,
  } = props;

  const fileInputRefEmiratesFront = useRef(null);
  const fileInputRefEmiratesBack = useRef(null);
  const gccCountries = [
    "Bahrain",
    "Saudi Arabia",
    "Kuwait",
    "Qatar",
    "Oman",
    "United Arab Emirates",
  ];
  const SelectBackground = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#007bff" : "white",
      color: state.isSelected ? "white" : "black",
      cursor: "pointer",
    }),
  };

  const currencyList = require("../../../../../assets/images/currencyList.json");

  const selectedCurrency =
    currencyList?.map((country) => ({
      value: country?.country,
      label: country?.country,
    })) || [];
  const handleAddClick = () => {
    removeToast();
    const maxItems = 4;
    setUnified((prevUnified) => {
      if (prevUnified.length >= maxItems) {
        toastShow("beneficiary count cannot exceed more than 4", "maxvalue");
        return prevUnified;
      }
      let newObj = { nationality: "United Arab Emirates" };
      return [...prevUnified, newObj];
    });
  };
  const handleRemoveClick = (primaryIndex) => {
    const newData = unified?.filter((_, i) => i !== primaryIndex);
    setUnified(newData);
    setAuthorizedType({
      label: "(Choose one)",
      value: "",
    });
  };

  return (
    <>
      <Row className="justify-content-end">
        <Col xs="9" sm="6" md="8" lg="5">
          <div className="white_box_title">
          Unified beneficiary Owner details
          </div>
        </Col>
        <Col className="d-block d-md-block d-lg-none mb-2 ar_float_left" xs="3" sm="6" md="4" >
          <Button
            onClick={(e) => {
              handleAddClick();
            }}
            className="blue_bg float-end"
          >
            {currentLang?.fp_lang_182}
          </Button>
        </Col>
        <Col>
          <div className="max_value">
            <div name="maxvalue"></div>
          </div>
        </Col>
        <Col className="d-none d-sm-none d-md-none d-lg-block mb-2" md="3">
          <Button
            onClick={(e) => {
              handleAddClick();
            }}
            className="blue_bg float-end"
          >
            {currentLang?.fp_lang_182}
          </Button>
        </Col>
      </Row>
      <div className="with_100">
        {unified?.map((list, i) =>
          list?.user_type !== "manager" ? (
            <Card>
              <CardBody>
                <Row>
                  <Col md="6" lg="4" className="mb-4">
                    <p className="control_labels control_labels_star">
                      {currentLang.fp_lang_71}
                    </p>
                    <Input
                      type="text"
                      placeholder="First Name"
                      value={list?.first_name}
                      name="first_name"
                      onChange={(e) => handleOnData(e, i, "first_name")}
                    />
                  </Col>
                  <Col md="6" lg="4" className="mb-4">
                    <p className="control_labels">{currentLang.fp_lang_72}</p>
                    <Input
                      type="text"
                      placeholder="Middle Name"
                      value={list?.middle_name}
                      onChange={(e) => handleOnData(e, i, "middle_name")}
                    />
                  </Col>
                  <Col md="6" lg="4" className="mb-4">
                    <p className="control_labels control_labels_star">
                      {currentLang.fp_lang_73}
                    </p>
                    <Input
                      type="text"
                      placeholder="Last Name"
                      value={list?.last_name}
                      onChange={(e) => handleOnData(e, i, "last_name")}
                      name="last_name"
                    />
                  </Col>
                  <Col md="6" lg="4" className="mb-4">
                    <p className="control_labels control_labels_star">
                      {currentLang.fp_lang_74}
                    </p>
                    <Input
                      type="date"
                      value={list?.dob}
                      onChange={(e) => handleOnData(e, i, "dob")}
                      name="dob"
                    />
                  </Col>
                  <Col md="6" lg="4" className="mb-4">
                    <p className="control_labels control_labels_star">
                      {currentLang.fp_lang_75}
                    </p>
                    <Select
                      styles={SelectBackground}
                      options={selectedCurrency}
                      onChange={(e) =>
                        handlechangeCurrency(e, i, "nationality")
                      }
                      value={{
                        label: list?.nationality || "United Arab Emirates",
                        value: list?.nationality || "United Arab Emirates",
                      }}
                      name="nationality"
                    />
                  </Col>
                  <Col md="6" lg="4" className="mb-4">
                    <p className="control_labels control_labels_star">
                      {currentLang.fp_lang_76}
                    </p>
                    <Input
                      type="text"
                      placeholder="Passport"
                      value={list?.passport_number}
                      onChange={(e) => handleOnData(e, i, "passport_number")}
                      name="passport_number"
                    />
                  </Col>
                  <Col md="6" lg="4" className="mb-4">
                    <p className="control_labels control_labels_star">
                      {currentLang.fp_lang_77}
                    </p>
                    <Input
                      type="date"
                      placeholder=""
                      value={list?.passport_exp_date}
                      onChange={(e) => handleOnData(e, i, "passport_exp_date")}
                      name="passport_exp_date"
                    />
                  </Col>
                  <Col md="6" lg="4" className="mb-4">
                    <FormGroup>
                      <p className="control_labels control_labels_star">
                        {currentLang.fp_lang_78}
                      </p>
                      <div style={{ position: "relative" }} key={i}>
                        <label
                          htmlFor={`passport_copy_${i}`}
                          className="upload_img_banner"
                          name="passport_doc"
                        >
                          Upload Passport Copy
                          <Input
                            type="file"
                            id={`passport_copy_${i}`}
                            className="d-none"
                            onChange={(e) =>
                              handleFileChange(e, i, "passport_doc")
                            }
                          />
                        </label>
                      </div>
                      {!list?.passport_doc ? (
                        <FormText color="muted" className="font_choose">
                         {currentLang?.fp_lang_145}
                        </FormText>
                      ) : (
                        <FormText color="blue">
                          <a
                            href={list?.passport_doc}
                            target="_blank"
                            alt="Preview"
                            className="img_down"
                          >
                           {currentLang.fp_lang_52}
                          </a>
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <div>
                  <hr></hr>
                </div>
                <div>
                  <hr></hr>
                </div>
                <Row>
                  <Col md="6" lg="4" className="mb-4">
                    <p className="control_labels control_labels_star">
                      {currentLang.fp_lang_79}
                    </p>
                    <Input
                      type="text"
                      placeholder="Emirates Number"
                      value={list?.emirates_number}
                      onChange={(e) => handleOnData(e, i, "emirates_number")}
                      name="emirates_number"
                    />
                  </Col>
                  <Col md="6" lg="4" className="mb-4">
                    <p className="control_labels control_labels_star">
                      {currentLang.fp_lang_80}
                    </p>
                    <Input
                      type="date"
                      placeholder="emiratesExpiryDate"
                      value={list?.emirates_exp_date}
                      onChange={(e) => handleOnData(e, i, "emirates_exp_date")}
                      name="emirates_exp_date"
                    />
                  </Col>
                  <Col md="6" lg="4" className="mb-4">
                    <FormGroup>
                      <p className="control_labels control_labels_star">
                        {currentLang.fp_lang_81}
                      </p>
                      <div style={{ position: "relative" }}>
                        <label
                          htmlFor={`emirate_id_front_${i}`}
                          className="upload_img_banner"
                          name="emirates_id_front_doc"
                        >
                          Upload Emirates ID (Front Side)
                        </label>
                        <Input
                          type="file"
                          id={`emirate_id_front_${i}`}
                          ref={fileInputRefEmiratesFront}
                          className="d-none"
                          onChange={(e) =>
                            handleFileChange(e, i, "emirates_id_front_doc")
                          }
                        />
                      </div>
                      {!list?.emirates_id_front_doc ? (
                        <FormText color="muted" className="font_choose">
                         {currentLang?.fp_lang_145}
                        </FormText>
                      ) : (
                        <FormText color="blue">
                          <a
                            href={list?.emirates_id_front_doc}
                            target="_blank"
                            alt="Preview"
                            className="img_down"
                          >
                           {currentLang.fp_lang_52}
                          </a>
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6" lg="4" className="mb-4">
                    <FormGroup>
                      <p className="control_labels control_labels_star">
                        {currentLang.fp_lang_82}
                      </p>

                      <div style={{ position: "relative" }}>
                        <label
                          htmlFor={`emirate_id_back_${i}`}
                          className="upload_img_banner"
                          name="emirates_id_back_doc"
                        >
                          Upload Emirates ID (Back Side)
                        </label>
                        <Input
                          type="file"
                          id={`emirate_id_back_${i}`}
                          className="d-none"
                          onChange={(e) =>
                            handleFileChange(e, i, "emirates_id_back_doc")
                          }
                          ref={fileInputRefEmiratesBack}
                        />
                      </div>
                      {!list?.emirates_id_back_doc ? (
                        <FormText color="muted" className="font_choose">
                         {currentLang?.fp_lang_145}
                        </FormText>
                      ) : (
                        <FormText color="blue">
                          <a
                            href={list?.emirates_id_back_doc}
                            target="_blank"
                            alt="Preview"
                            className="img_down"
                          >
                           {currentLang.fp_lang_52}
                          </a>
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  {list?.nationality &&
                  !gccCountries.includes(list?.nationality) ? (
                    <>
                      <Col md="6" lg="4" className="mb-4">
                        <FormGroup>
                          <p className="control_labels">
                            {currentLang.fp_lang_83}
                          </p>
                          <div style={{ position: "relative" }}>
                            <label
                              htmlFor={`visa_copy_front_${i}`}
                              className="upload_img_banner"
                              name="visa_id_doc"
                            >
                              Upload Visa Copy
                            </label>
                            <Input
                              type="file"
                              className="d-none"
                              id={`visa_copy_front_${i}`}
                              onChange={(e) =>
                                handleFileChange(e, i, "visa_id_doc")
                              }
                              ref={fileInputRefEmiratesBack}
                            />
                          </div>
                          {!list?.visa_id_doc ? (
                            <FormText color="muted" className="font_choose">
                              {currentLang?.fp_lang_145}
                            </FormText>
                          ) : (
                            <FormText color="blue">
                              <a
                                href={list?.visa_id_doc}
                                target="_blank"
                                alt="Preview"
                                className="img_down"
                              >
                           {currentLang.fp_lang_52}
                              </a>
                            </FormText>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6" lg="4" className="mb-4">
                        <p className="control_labels">
                          {currentLang.fp_lang_84}
                        </p>
                        <Input
                          type="date"
                          placeholder="emiratesExpiryDate"
                          value={list?.visa_exp_date}
                          onChange={(e) => handleOnData(e, i, "visa_exp_date")}
                          name="visa_exp_date"
                        />
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>

                <div>
                  <hr></hr>
                </div>
                <Row>
                  <Col md="6" lg="4" className="mb-4">
                    <p className="control_labels control_labels_star">
                      {currentLang.fp_lang_86}
                    </p>
                    <Input
                      type="text"
                      placeholder="Enter % of share in the company"
                      value={list?.company_share}
                      onChange={(e) => handleOnData(e, i, "company_share")}
                      name="company_share"
                    />
                  </Col>
                </Row>
                {i > 0 && (
                  <Button
                    className="mt-2 float-end"
                    onClick={() => handleRemoveClick(i)}
                  >
                    Remove
                  </Button>
                )}
              </CardBody>
            </Card>
          ) : (
            ""
          )
        )}
      </div>
    </>
  );
};
