import React, { useEffect, useState } from "react"
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label, Toast, Button } from "reactstrap";
import { Link } from 'react-router-dom';
import { enLang, arLang } from '../Constants/en_lang';
import { getCookie } from "../Utils";

function Footer() {
    const currentLang = getCookie('foloosi_lang') === 'ar' ? arLang : enLang;
    return (
        <React.Fragment>
            <footer className="fp_footer">
                <div className="container">
                    <Row>
                        <Col>
                            <a href="https://www.foloosi.com">
                                <img src="https://www.foloosi.com/images/logo_new.png" alt="logo" title="logo" />
                                <img src="https://www.foloosi.com/images/arabiclogo.png" alt="logo" title="logo" height="70" className="footerlogo m-l-20" />
                            </a>
                            <p className="fp_logoUnderCont">{currentLang.fp_lang_146}</p>
                            <p className="fp_logoUnderCont">{currentLang.fp_lang_147}</p>
                            <div className="mt-30">
                                <a className="footericons pull-left fp_margin_align_ft" href="https://secure.trust-provider.com/ttb_searcher/trustlogo?v_querytype=W&v_shortname=CL1&v_search=https://www.foloosi.com/&x=6&y=5" target="_blank"><img className="my-2" src="https://www.foloosi.com/images/ssl-trust.png" alt="Sectigo" title="Sectigo" width="150" /></a>
                                <a className="footericons pull-left m-l-10 fp_margin_align_ft" href="https://verify.valuementor.com/index.php/show_certificate?cert_id=CERTOY08304295" onClick="window.open(this.href,'Valuementor Certificate','height=600,width=600,scrollbar=yes,status=no,menubar=no,toolbar=no,resizable=0'); return false"><img className="my-2" src="https://www.foloosi.com/images/pci_dss.svg" height="32" /></a>
                            </div>
                            <h3 className="fp_footer_pay_logo_head">{currentLang.fp_lang_148}</h3>
                            <ul className="fp_ul-footer-folloe">
                                <li>
                                    <a href="https://www.facebook.com/foloosi.technology" target="_blank"><img src="https://www.foloosi.com/images/fp_facebook.svg" alt="facebook" title="facebook" /></a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/foloosi" target="_blank"><img src="https://www.foloosi.com/images/fp_linkedin.svg" alt="linkedin" title="linkedin" /></a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/foloosi" target="_blank"><img src="https://www.foloosi.com/images/fp_twiitter.svg" alt="twitter" title="twitter" /></a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/foloosi" target="_blank"><img src="https://www.foloosi.com/images/fp_insta.svg" alt="instagram" title="instagram" /></a>
                                </li>
                            </ul>
                        </Col>
                        <Col>
                            <Row>
                                <Col className="p-0 fp_foothead" md="6">
                                    <h2 className="mt-25">{currentLang.fp_lang_149}</h2>
                                    <ul className="fp_ul_footer">
                                        <li><a href="https://www.foloosi.com/about-us">{currentLang.fp_lang_150}</a></li>
                                        <li><a href="https://www.foloosi.com/contact-us">{currentLang.fp_lang_151}</a></li>
                                        <li><a href="https://docs.foloosi.com/">{currentLang.fp_lang_152}</a></li>
                                        <li><a target="_blank" href="https://www.foloosi.com/blogs">{currentLang.fp_lang_153}</a></li>
                                    </ul>
                                    <h2>{currentLang.fp_lang_154}</h2>
                                    <ul className="fp_ul_footer">
                                        <li><a href="https://www.foloosi.com/terms">{currentLang.fp_lang_155}</a></li>
                                        <li><a href="https://www.foloosi.com/policy">{currentLang.fp_lang_156}</a></li>
                                        <li><a href="https://www.foloosi.com/cancellation-refund">{currentLang.fp_lang_157}</a></li>
                                        <li><a href="https://www.foloosi.com/delivery-services">{currentLang.fp_lang_158}</a></li>
                                        <li><a href="https://www.foloosi.com/compliance">{currentLang.fp_lang_159}</a></li>
                                        <li><a href="https://www.foloosi.com/merchant-checklist">{currentLang.fp_lang_160}</a></li>
                                    </ul>

                                </Col>
                                <Col className="fp_foothead" md="6">
                                    <h2>{currentLang.fp_lang_161}</h2>
                                    <ul className="fp_ul_footer">
                                        <li><a href="https://www.foloosi.com/qr-code-payment-uae">{currentLang.fp_lang_162}</a></li>
                                        <li><a href="https://www.foloosi.com/payment-links-in-uae">{currentLang.fp_lang_163}</a></li>
                                        <li><a href="https://www.foloosi.com/product-links-in-uae">{currentLang.fp_lang_164}</a></li>
                                        <li><a href="https://www.foloosi.com/subscription-payments-uae">{currentLang.fp_lang_165}</a></li>
                                        <li><a href="https://www.foloosi.com/accept_international_payments">{currentLang.fp_lang_166}</a></li>

                                    </ul>
                                    <h2>{currentLang.fp_lang_167}</h2>
                                    <ul className="fp_ul_footer">
                                        <li><a href="https://www.foloosi.com/point-of-sales-software-uae">{currentLang.fp_lang_168}</a></li>
                                        <li><a href="https://www.foloosi.com/retail-solution">{currentLang.fp_lang_169}</a></li>
                                        <li><a href="https://www.foloosi.com/payment-gateway-in-uae">{currentLang.fp_lang_171}</a></li>
                                        <li><a href="https://www.foloosi.com/app-in-store-uae">{currentLang.fp_lang_172}</a></li>
                                        <li><a href="https://www.foloosi.com/online-store">{currentLang.fp_lang_173}</a></li>
                                    </ul>

                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="fp_footer_btm">
                    <div className="container">
                        <span className="fp_copy_con pul-right ar_float_left">{currentLang.fp_lang_178}</span>
                        <ul className="fp_privacy_term_list pul-left xs_right">
                            <li><a href="https://www.foloosi.com/sitemap.xml">{currentLang.fp_lang_174}</a></li>
                            <li><a href="https://www.foloosi.com/terms">{currentLang.fp_lang_175}</a></li>
                            <li><a href="https://www.foloosi.com/media">{currentLang.fp_lang_176}</a></li>
                            <li><a href="https://www.foloosi.com/demo">{currentLang.fp_lang_177}</a></li>
                        </ul>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
}
export default Footer