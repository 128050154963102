import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import FreshChat from 'react-freshchat'
import { StepTwo } from "../Components/BusinessDetails/subComponents/stepTwo";
import { StepThree } from "../Components/BusinessDetails/subComponents/stepThree";
import { StepFour } from "../Components/BusinessDetails/subComponents/stepFour";
import { StepOne } from "../Components/BusinessDetails/subComponents/StepOneFlow/StepOne";
import { ActiveStep } from "../Components/BusinessDetails/subComponents/activeStep";
import Layout from "./Layout";
import Error from "./Error";
var leftSideBarType = "default";
const PrivateRouter = () => {
  const dispatch = useDispatch();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const updateState = (actionType, value) => (dispatch) => {
    dispatch({ type: actionType, payload: value });
    return Promise.resolve();
  };
  const { layout } = useSelector((state) => state);
  useEffect(() => {
    changeBodyAttribute("data-sidebar", layout.leftSideBarTheme);
    changeBodyAttribute("data-topbar", layout.topbarTheme);
    changeBodyAttribute("data-sidebar-image", layout.leftSideBarThemeImage);
    changeBodyAttribute("data-layout-mode", layout.layoutModeType);
    if (layout.layoutWidth === "boxed") {
      changeBodyAttribute("data-layout-size", layout.layoutWidth);
      changeBodyAttribute("data-layout-scrollable", layout.scrollable);
    } else if (layout.layoutWidth === "scrollable") {
      changeBodyAttribute("data-layout-scrollable", !layout.scrollable);
    } else {
      changeBodyAttribute("data-layout-size", layout.layoutWidth);
      changeBodyAttribute("data-layout-scrollable", layout.scrollable);
    }
    switch (layout.leftSideBarType) {
      case "compact":
        changeBodyAttribute("data-sidebar-size", "small");
        manageBodyClass("sidebar-enable", "remove");
        manageBodyClass("vertical-collpsed", "remove");
        break;
      case "icon":
        changeBodyAttribute("data-sidebar-size", "");
        changeBodyAttribute("data-keep-enlarged", "true");
        manageBodyClass("vertical-collpsed", "add");
        break;
      case "condensed":
        manageBodyClass("sidebar-enable", "add");
        if (window.screen.width >= 992) {
          manageBodyClass("vertical-collpsed", "remove");
          manageBodyClass("sidebar-enable", "remove");
          manageBodyClass("vertical-collpsed", "add");
          manageBodyClass("sidebar-enable", "add");
        } else {
          manageBodyClass("sidebar-enable", "add");
          manageBodyClass("vertical-collpsed", "add");
        }
        break;
      default:
        changeBodyAttribute("data-sidebar-size", "");
        manageBodyClass("sidebar-enable", "remove");
        if (!isMobile) manageBodyClass("vertical-collpsed", "remove");
        break;
    }
  }, []);
  const changeBodyAttribute = (attribute, value) => {
    if (document.body) document.body.setAttribute(attribute, value);
    return true;
  };
  const manageBodyClass = (cssClass, action = "toggle") => {
    switch (action) {
      case "add":
        if (document.body) document.body.classList.add(cssClass);
        break;
      case "remove":
        if (document.body) document.body.classList.remove(cssClass);
        break;
      default:
        if (document.body) document.body.classList.toggle(cssClass);
        break;
    }
    return true;
  };
  
  
  return (
    <React.Fragment>
      <div id="layout-wrapper">
          <Routes>
            <Route element={<Layout/>}>
            <Route path="/" exact element={< StepOne />} />
              <Route path="/business-details" exact element={< StepOne />} />
              <Route path="/business-service" exact element={< StepTwo />} />
              <Route path="/transaction-information" exact element={< StepThree />} />
              <Route path="/bank-details" exact element={< StepFour />} />
              <Route path="/proceed-verification" exact element={< ActiveStep />} />
              <Route path="/*" element={<Error />} />
            </Route>
          </Routes>
          <FreshChat
            token="2ec0313d-718c-47db-8711-0bf43b7b74dd"
          />
      </div>
    </React.Fragment>
  );
};

export default PrivateRouter;
