import React, { useState } from "react";
import {
  disableSubmit,
  getCookie,
  removeToast,
  setCookie,
  toastShow,
} from "../../Utils";
import { Link } from "react-router-dom";
import { enLang, arLang } from "../../Constants/en_lang";
import { reloadWindowToPath } from "gfdu";
import axios from "axios";
import { toast } from "react-toastify";
import ippopay from "./../../assets/images/logo.png";
import "react-toastify/dist/ReactToastify.css";
import {
  Row,
  Col,
  CardBody,
  Card,
  Button,
  FormFeedback,
  Input,
} from "reactstrap";

function ForgetPassword() {
  const [userlogin, setUserlogin] = useState({});
  const currentLang = getCookie("foloosi_lang") === "ar" ? arLang : enLang;
  const [isLogin, setIsLogin] = useState(false);
  const [spinLoader, setSpinLoader] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserlogin({
      ...userlogin,
      [name]: value,
    });
  };
  const [formErrors, setFormErrors] = useState({});

  const handleEmail = (e) => {
    removeToast();
    e.preventDefault();
    if (!userlogin.email) {
      toastShow("Please Enter the Email", "email");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userlogin.email)
    ) {
      toastShow("Please Enter a valid Email", "email");
    } else {
      let data = {
        email: userlogin.email,
      };
      const headers = {
        "Content-Type": "application/json",
        platform: "web",
      };
      axios
        .post("https://api.foloosi.com/onboard/forget-password", data, {
          headers,
        })
        .then((response) => {
          if (
            response.data?.message ===
            "New password sent to your registered email"
          ) {
            toast.success("OTP sent to your email id successfully");
            setIsLogin(true);
          }
        })
        .catch((error) => {
          setSpinLoader(false);
          const errorMessage =
            error?.response?.data?.message ||
            "An error occurred during the API call.";
          toast.error(errorMessage);
        });
    }
  };
  console.log(formErrors, "formerror");
  const changeLang = (id) => {
    setCookie(id, "foloosi_lang");
    window.location.reload();
  };
  const handleconfirmLogin = (e) => {
    removeToast();
    e.preventDefault();
    if (!userlogin.otp) {
      toastShow("Please Enter OTP","otp");
    } else if (!userlogin.New_Password) {
      toastShow("Please enter the New password", "New_Password");
    } else if (!userlogin.Confirm_Password) {
      toastShow("Please enter the Confirmation password", "Confirm_Password");
    } else if (userlogin.Confirm_Password.length < 12) {
      toastShow(
        "Password must contain at least 12 characters",
        "Confirm_Password"
      );
    } else if (
      !/[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/.test(userlogin.Confirm_Password)
    ) {
      toastShow(
        "Password should contain at least 1 special character",
        "Confirm_Password"
      );
    } else if (!/[a-z]/.test(userlogin.Confirm_Password)) {
      toastShow(
        "Password should contain at least 1 lowercase letter",
        "Confirm_Password"
      );
    } else if (!/[A-Z]/.test(userlogin.Confirm_Password)) {
      toastShow(
        "Password should contain at least 1 uppercase letter",
        "Confirm_Password"
      );
    } else if (!/\d/.test(userlogin.Confirm_Password)) {
      toastShow(
        "Password should contain at least 1 number",
        "Confirm_Password"
      );
    } else if (userlogin.New_Password !== userlogin.Confirm_Password) {
      toastShow(
        "The new password and confirmation password does not match, Please confirm the password correctly",
        "Confirm_Password"
      );
    } else {
      let data = {
        otp: userlogin.otp,
        email: userlogin.email,
        password: userlogin.Confirm_Password,
      };
      const headers = {
        "Content-Type": "application/json",
        platform: "web",
      };
      axios
        .post("https://api.foloosi.com/onboard/new-password", data, { headers })
        .then((response) => {
          console.log(response.data?.message);
          if (response.data?.message === "Password changed successfully") {
            toast.success(response.data?.message);
            setIsLogin(true);
            setTimeout(() => {
              reloadWindowToPath("/login");
            }, 2000);
          } else {
            setSpinLoader(false);
            toast.error("Please enter a valid OTP");
          }
        })
        .catch((error) => {
          setSpinLoader(false);
          toast.error("Please enter a valid OTP");
        });
    }
  };

  return (
    <React.Fragment>
      <Card className="m-0 h-100 login_bg">
        <CardBody className="container h-100">
          <Row className="justify-content-center h-100 align-items-center">
            <div className="login_box_wrap">
              <div className="login_box">
                <div className="full_width">
                  <a className="login_logo float-start">
                    <img src={ippopay} alt="" />
                  </a>
                  <div className="d-flex float-end sign_up_left_margin mt-2 ar_float_right">
                    {getCookie("foloosi_lang") == "ar" ? (
                      <a
                        onClick={() => changeLang("en")}
                        className="arabic_a ar_float_right"
                      >
                        English
                      </a>
                    ) : (
                      <a onClick={() => changeLang("ar")} className="arabic_a">
                        العربية
                      </a>
                    )}
                  </div>
                </div>

                {!isLogin && (
                  <>
                    <div className="full_width you_acc_margin d-flex justify-content-between">
                      <p className="creat_acc_header">
                        {currentLang.fp_lang_22}
                      </p>
                      <Link to="/login">{currentLang.fp_lang_26}</Link>
                    </div>
                    <Row className="input_mar">
                      <Col md="12">
                        <div>
                          <div className="form-group">
                            <label className="ar_float_right">
                              {currentLang.fp_lang_20}
                            </label>
                            <Input
                              type="text"
                              className="form-control input-lg"
                              placeholder="Enter the Email"
                              onChange={handleChange}
                              name="email"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="full_width">
                      <Button
                        className="sign_up_button"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") handleEmail();
                        }}
                        onClick={(e) => {
                          handleEmail(e);
                          disableSubmit(e);
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </>
                )}
                {isLogin && (
                  <>
                    <div className="full_width you_acc_margin d-flex justify-content-between">
                      <p className="creat_acc_header">
                        {currentLang.fp_lang_183}
                      </p>
                      <Link to="/login">{currentLang.fp_lang_26}</Link>
                    </div>
                    <Row className="input_mar">
                      <Col md="12">
                        <div>
                          <div className="form-group">
                            <Input
                              type="text"
                              className="form-control input-lg"
                              placeholder="OTP"
                              onChange={handleChange}
                              name="otp"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="input_mar">
                      <Col md="12">
                        <div>
                          <div className="form-group">
                            <Input
                              type="text"
                              className="form-control input-lg"
                              placeholder="New Password"
                              onChange={handleChange}
                              name="New_Password"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className="input_mar">
                      <Col md="12">
                        <div>
                          <div className="form-group">
                            <Input
                              type="text"
                              className="form-control input-lg"
                              placeholder="Confirm Password"
                              onChange={handleChange}
                              name="Confirm_Password"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="full_width">
                      <Button
                        className="sign_up_button"
                        onClick={(e) => {
                          handleconfirmLogin(e);
                          disableSubmit(e);
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </>
                )}
              </div>
              <div className="tems-login add-form">
                <a href="https://www.foloosi.com/policy" target="_blank" className="ar_float_left">
                  {currentLang?.fp_lang_143}
                </a>
                <a href="https://www.foloosi.com/terms" target="_blank" className="ar_float_left">
                  {currentLang?.fp_lang_144}
                </a>
              </div>
            </div>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
export default ForgetPassword;
