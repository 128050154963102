import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from "reactstrap";
import ApiGateWay from "../../../Utils/ApiCall";
import axios from "axios";
import { enLang, arLang } from "../../../Constants/en_lang";
import {
  disableSubmit,
  getCookie,
  removeToast,
  toastShow,
} from "../../../Utils/index";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes } from "../../../Constants/ActionTypes";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader";
export const StepFour = () => {
  const dispatch = useDispatch();
  const updateState = (actionType, value) => () => {
    dispatch({ type: actionType, payload: value });
    return Promise.resolve();
  };
  const currentLang = getCookie("foloosi_lang") === "ar" ? arLang : enLang;
  const Navigate = useNavigate();
  const [spinLoader, setSpinLoader] = useState(false);
  const { dashboardlist } = useSelector((state) => state);
  const [loading, setLoading] = useState(true);
  const [addForm, setaddForm] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemmodal, setitemmodal] = useState(false);
  const handleOnAdddata = (fieldName, value) => {
    console.log(fieldName, value, "value");
    setaddForm({
      ...addForm,
      [fieldName]: value,
    });
    console.log(addForm);
  };
  useEffect(() => {
    setaddForm(dashboardlist?.businessListInfo);
    getBusinessinfo();
  }, []);
  const [isAgree, setIsAgree] = useState(false);
  const customStyles = {
    position: "absolute",
    top: "40px",
    left: "40px",
    right: "40px",
    bottom: "40px",
    backgroundColor: "papayawhip",
  };
  const handlesubmit = () => {
    removeToast();
    const nameRegex = /^[A-Za-z0-9_ ]+$/;
    const specialCharacter = /^[a-zA-Z0-9]*$/;
    const specialCharacterspace = /^[a-zA-Z0-9\s]*$/;
    const activestate =
      dashboardlist.businessListInfo?.aggregator_signup_status;
    if (!addForm.bank_name) {
      toastShow("Please enter your bank", "bank_name");
    } else if (addForm.bank_name == 0) {
      toastShow("Please enter valid your bank", "bank_name");
    } else if (!nameRegex.test(addForm.bank_name)) {
      toastShow(
        "Please enter a valid bank , special characters not allowed",
        "bank_name"
      );
    } else if (!addForm.account_holder_name) {
      toastShow("Please enter your account title", "account_holder_name");
    } else if (addForm.account_holder_name == 0) {
      toastShow("Please enter your valid account title", "account_holder_name");
    } else if (!nameRegex.test(addForm.account_holder_name)) {
      toastShow(
        "Please enter a valid account tittle, special characters not allowed",
        "account_holder_name"
      );
    } else if (!addForm.account_no) {
      toastShow("Please enter your account number", "account_no");
    } else if (addForm.account_no == 0) {
      toastShow("Please enter your valid account number", "account_no");
    } else if (isNaN(addForm.account_no)) {
      toastShow("Please enter your account number, Numbers only", "account_no");
    } else if (!addForm.iban_number) {
      toastShow("Please enter your IBAN", "iban_number");
    } else if (addForm.iban_number == 0) {
      toastShow("Please enter your valid IBAN", "iban_number");
    } else if (!specialCharacterspace.test(addForm.iban_number)) {
      toastShow(
        "Please enter a valid IBAN, special characters not allowed",
        "iban_number"
      );
    } else if (!addForm.swift_code) {
      toastShow("Please enter swift code", "swift_code");
    } else if (addForm.swift_code == 0) {
      toastShow("Please enter valid swift code", "swift_code");
    } else if (!specialCharacterspace.test(addForm.swift_code)) {
      toastShow(
        "Please enter a valid swift code, special characters not allowed",
        "swift_code"
      );
    } else if (!addForm.branch_location) {
      toastShow("Please enter branch location", "branch_location");
    } else if (addForm.branch_location == 0) {
      toastShow("Please enter valid branch location", "branch_location");
    } else if (!specialCharacterspace.test(addForm.branch_location)) {
      toastShow(
        "Please enter a valid branch location, special characters not allowed",
        "branch_location"
      );
    } else if (!bankStatement) {
      toastShow("Please upload the Bank statement document", "bankStatement");
    } else if (!isAgree) {
      toastShow("Please agree to the terms and conditions", "isAgree");
    } else {
      let data = {
        account_type: "onBoarding-v2",
        merchant_id: dashboardlist.valueauth?.id,
        bank_name: addForm.bank_name,
        account_number: addForm.account_no,
        account_name: addForm.account_holder_name,
        iban_number: addForm.iban_number,
        swift_code: addForm.swift_code,
        branch_location: addForm.branch_location,
        statement: bankStatement,
      };
      console.log(data, "data");
      // return false
      setSpinLoader(true);
      ApiGateWay.post("merchant/update-bank-details", data, (response) => {
        setSpinLoader(false);
        if (response?.success === 1) {
          toast.success("Bank details updated successfully");
          if (activestate?.step_1_status !== "completed") {
            Navigate("/business-details");
          } else if (activestate?.step_2_status !== "completed") {
            Navigate("/business-service");
          } else if (activestate?.step_3_status !== "completed") {
            Navigate("/transaction-information");
          } else {
            Navigate("/proceed-verification");
            getBusinessinfo();
          }
        } else {
          toast.error(response.message);
        }
      });
    }
  };
  const validImageTypes = [
    "image/jpeg",
    "image/gif",
    "image/png",
    "application/pdf",
  ];
  const [bankStatement, setbankStatement] = useState(
    dashboardlist.businessListInfo?.bank_statement
  );
  const handleCertify = (event) => {
    removeToast();
    const file = event.target.files[0];
    const fileType = file.type;
    if (!validImageTypes.includes(fileType)) {
      toastShow("Please upload jpeg, gif, png, or pdf format", "bankStatement");
    } else if (file.size < 5120) {
      toastShow("Min upload size should be greater than 5 KB", "bankStatement");
    } else if (file.size > 2048000) {
      toastShow("Max upload size should be less than 2 MB", "bankStatement");
    } else {
      let data = new FormData();
      data.append("file", file);
      data.append("upload_preset", "ifslewdl");
      data.append("api_key", "724539947133222");
      axios
        .post("https://api.cloudinary.com/v1_1/dzoacacm1/raw/upload", data)
        .then((response) => {
          setbankStatement(response?.data?.secure_url);
        });
    }
  };

  const [bottomReached, setBottomReached] = useState(false);
  const scrollDivRef = useRef(null);
  const [atBottom, setAtbottom] = useState(false);
  const handleScroll = (e) => {
    const atBottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 1;
    console.log(
      e.target.scrollHeight - e.target.scrollTop,
      e.target.clientHeight,
      "atBottom"
    );
    if (atBottom) {
      setBottomReached(true);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(!isModalOpen);
    // setitemmodal(false);
    setBottomReached(false);
  };
  const handleAgree = (e) => {
    let { checked } = e.target;
    if (checked) {
      setIsModalOpen(true);
    } else {
      setIsAgree(false);
      setIsModalOpen(false);
    }
    setTimeout(() => {
      if (scrollDivRef.current && itemmodal) {
        scrollDivRef.current.scrollTo({
          top: scrollDivRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }, 10);
  };
  const handleCancelApprove = () => {
    setBottomReached(true);
    setitemmodal(true);
    if (scrollDivRef.current) {
      scrollDivRef.current.scrollTop = scrollDivRef.current.scrollHeight;
    }
  };

  const handleAgreeClose = () => {
    setIsAgree(true);
    setAtbottom(true);
    setIsModalOpen(!isModalOpen);
  };
  console.log(scrollDivRef, "scrollDivRef");

  const getBusinessinfo = () => {
    const userNo = dashboardlist.valueauth?.user_no;
    ApiGateWay.get(
      `aggregator-details?q={"user_no":"${userNo}"}`,
      (response) => {
        if (response?.success === 1) {
          dispatch(
            updateState(ActionTypes.DASHBOARD, {
              businessListInfo: response?.data,
              businessData: response?.data?.aggregator_busines,
            })
          );
          setLoading(false);
        } else {
          toast.error(response.message);
          setLoading(false);
        }
      }
    );
  };
  return (
    <div className="container">
      {loading && <Loader />}
      <div className="float-start arr_full_width">
        <p className="right_part_title float_right_arabic">
          {currentLang.fp_lang_120}
        </p>
        <p className="right_part_desc">{currentLang.fp_lang_121}</p>
        <div className="white_box_title">{currentLang.fp_lang_122}</div>
      </div>
      <div className="with_100">
        <Card>
          <CardBody>
            <Row>
              <Col md="6" lg="4" className="mb-4">
                <p className="control_labels control_labels_star">
                  {currentLang.fp_lang_123}
                </p>
                <Input
                  type="text"
                  placeholder="Enter Bank"
                  value={addForm?.bank_name}
                  onChange={(e) => handleOnAdddata("bank_name", e.target.value)}
                  name="bank_name"
                />
              </Col>
              <Col md="6" lg="4" className="mb-4">
                <p className="control_labels control_labels_star">
                  {currentLang.fp_lang_124}
                </p>
                <Input
                  type="text"
                  placeholder="Enter Account Title"
                  value={addForm?.account_holder_name}
                  onChange={(e) =>
                    handleOnAdddata("account_holder_name", e.target.value)
                  }
                  name="account_holder_name"
                />
              </Col>
              <Col md="6" lg="4" className="mb-4">
                <p className="control_labels control_labels_star">
                  {currentLang.fp_lang_125}
                </p>
                <Input
                  type="text"
                  placeholder="Enter Account Number"
                  value={addForm?.account_no}
                  onChange={(e) =>
                    handleOnAdddata("account_no", e.target.value)
                  }
                  name="account_no"
                />
              </Col>
              <Col md="6" lg="4" className="mb-4">
                <p className="control_labels control_labels_star">
                  {currentLang.fp_lang_126}
                </p>
                <Input
                  type="text"
                  placeholder="Enter IBAN"
                  value={addForm?.iban_number}
                  onChange={(e) =>
                    handleOnAdddata("iban_number", e.target.value)
                  }
                  name="iban_number"
                />
              </Col>
              <Col md="6" lg="4" className="mb-4">
                <p className="control_labels control_labels_star">
                  {currentLang.fp_lang_127}
                </p>
                <Input
                  type="text"
                  placeholder="Enter Swift Code"
                  value={addForm?.swift_code}
                  onChange={(e) =>
                    handleOnAdddata("swift_code", e.target.value)
                  }
                  name="swift_code"
                />
              </Col>
              <Col className="mb-4">
                <p className="control_labels control_labels_star">
                  {currentLang.fp_lang_128}
                </p>
                <Input
                  type="text"
                  placeholder="Enter Branch location"
                  value={addForm?.branch_location}
                  onChange={(e) =>
                    handleOnAdddata("branch_location", e.target.value)
                  }
                  name="branch_location"
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <p className="control_labels control_labels_star">
                {currentLang.fp_lang_129}
              </p>
              <Col md="6" lg="4" className="mb-4">
                <FormGroup>
                  <div style={{ position: "relative" }}>
                    <label
                      htmlFor="exampleFile"
                      className="upload_img_banner"
                      name="bankStatement"
                    >
                      {!bankStatement
                        ? "Upload bank statement"
                        : "Bank Statement File"}
                    </label>
                    <Input
                      type="file"
                      name="file"
                      id="exampleFile"
                      className="d-none"
                      onChange={(e) => handleCertify(e)}
                    />
                  </div>
                  {!bankStatement ? (
                    <FormText color="muted" className="font_choose">
                      {currentLang.fp_lang_145}
                    </FormText>
                  ) : (
                    <FormText color="blue">
                      <a
                        href={bankStatement}
                        target="_blank"
                        alt="Preview"
                        className="img_down"
                      >
                        {currentLang.fp_lang_52}
                      </a>
                    </FormText>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <label
                  htmlFor="check"
                  className="float-start float_right_arabic d-flex"
                >
                  <Input
                    id="check"
                    type="checkbox"
                    className="float-start"
                    checked={isAgree}
                    onChange={handleAgree}
                  />
                  <span
                    className="mt-2 mx-2 float-start margin_agree"
                    style={{ fontSize: "12px", color: "#1654c2" }}
                  >
                    {currentLang.fp_lang_130}
                  </span>
                </label>
              </Col>
            </Row>
            <div name="isAgree"></div>
          </CardBody>
        </Card>
        {!spinLoader ? (
          <Button
            className="my-4 submit_process"
            onClick={(e) => {
              handlesubmit();
              disableSubmit(e);
            }}
          >
            {currentLang.fp_lang_92}
          </Button>
        ) : (
          <Button className="my-4 submit_process">
            <i class="fa fa-spinner fa-pulse" aria-hidden="true"></i>
          </Button>
        )}
      </div>

      <Modal
        ariaHideApp={false}
        isOpen={isModalOpen}
        style={customStyles}
        overlayClassName="Overlay"
      >
        <div className="d-flex justify-content-between modal_padding">
          <p className="float-end agreement_head m-0">
            Read the Agreement and Approve the Terms
          </p>
          <a onClick={handleCancel}>X</a>
        </div>
        <div
          className="pdf_view_frame agreement_scroll pdf_view_scroll"
          onScroll={handleScroll}
          ref={scrollDivRef}
          id="scrollto"
        >
          <img src={require("../../../assets/images/001.jpg")} />
          <img src={require("../../../assets/images/002.jpg")} />
          <img src={require("../../../assets/images/003.jpg")} />
          <img src={require("../../../assets/images/004.jpg")} />
          <img src={require("../../../assets/images/005.jpg")} />
          <img src={require("../../../assets/images/006.jpg")} />
          <img src={require("../../../assets/images/007.jpg")} />
          <img src={require("../../../assets/images/008.jpg")} />
          <img src={require("../../../assets/images/009.jpg")} />
          <img src={require("../../../assets/images/010.jpg")} />
          <img src={require("../../../assets/images/011.jpg")} />
          <img src={require("../../../assets/images/012.jpg")} />
          <img src={require("../../../assets/images/013.jpg")} />
          <img src={require("../../../assets/images/014.jpg")} />
          <img src={require("../../../assets/images/015.jpg")} />
          <img src={require("../../../assets/images/016.jpg")} />
          <img src={require("../../../assets/images/017.jpg")} />
          <img src={require("../../../assets/images/018.jpg")} />
        </div>
        {bottomReached && !itemmodal && (
          <div className="text-center no-padding">
            <span onClick={handleCancelApprove} className="approve_btn_bg">
              Close
            </span>
          </div>
        )}

        {itemmodal && (
          <div className="text-center no-padding" id="approve_link_to_show">
            <span onClick={handleAgreeClose} className="approve_btn_bg">
              Click here to approve Terms and Conditions
            </span>
          </div>
        )}
      </Modal>
    </div>
  );
};
