import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Toast,
  Button,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiGateWay from "../../Utils/ApiCall";
import {
  disableSubmit,
  getCookie,
  removeToast,
  setCookie,
  toastShow,
} from "../../Utils";
import { Link } from "react-router-dom";
import { reloadWindowToPath } from "gfdu";
import { enLang, arLang } from "../../Constants/en_lang";
import ippopay from "./../../assets/images/logo.png";
import { useSelector, useDispatch } from "react-redux";
import { ActionTypes } from "../../Constants/ActionTypes";

const Login = () => {

  const { login} = useSelector((state) => state);
    const dispatch = useDispatch();

  const updateState = (actionType, value) => () => {
        dispatch({ type: actionType, payload: value });
        return Promise.resolve();
    };
    useEffect(() => {
        dispatch(updateState(ActionTypes.LOGIN, { email: "", password: "", merchant_id:""  }));
    }, []);

  const currentLang = getCookie("foloosi_lang") === "ar" ? arLang : enLang;
  const [addForm, setaddForm] = useState({});
  const [checkIcon, setCheckIcon] = useState(false);
  const mailformat =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const [hidden, setHidden] = useState(false);
  const handleOnAdddata = (fieldName, value) => {
    setaddForm({
      ...addForm,
      [fieldName]: value,
    });
    console.log(addForm);
        dispatch(updateState(ActionTypes.LOGIN, { email: addForm?.email_address }));
  };
  const handleLogin = async (e) => {
    removeToast();
    setCheckIcon(false);
    e.preventDefault();
   
    var data = {
      email: addForm.email_address,
      password: addForm.password,
      platform: "web",
    };
    if (!addForm.email_address) {
      toastShow("please Enter Email", "email_address");
    } else if (!mailformat.test(addForm.email_address)) {
      toastShow("Invalid email address", "email_address");
    } else if (!addForm.password) {
      setCheckIcon(true);
      toastShow("Please Enter Password", "password");
    } else {
      ApiGateWay.post("merchant/login", data, (response) => {
        if (response?.data) { 
          let { authentication } = response.data;
          setCookie(authentication, "authentication_auth");
          dispatch(updateState(ActionTypes.LOGIN, { merchant_id:response?.data?.customer_id }));
          if (response.data.email_otp_verify ==="No") {
            reloadWindowToPath("/otp-screen");
          }else{
            reloadWindowToPath("/business-details");
          }
          
        } else {
          toast.error(response?.message);
        }
      });
    }
  };
  const changeLang = (id) => {
    setCookie(id, "foloosi_lang");
    window.location.reload();
  };

  return (
    <>
      <Card className="m-0 h-100 login_bg">
        <CardBody className="container h-100">
          <Row className="justify-content-center h-100 align-items-center">
            <div className="login_box_wrap">
              <div className="login_box">
                <div className="full_width">
                  <a className="login_logo float-start">
                    <img src={ippopay} alt="" />
                  </a>
                  <div className="d-flex float-end sign_up_left_margin mt-2 ar_float_right">
                    {getCookie("foloosi_lang") == "ar" ? (
                      <a
                        onClick={() => changeLang("en")}
                        className="arabic_a ar_float_right"
                      >
                        English
                      </a>
                    ) : (
                      <a onClick={() => changeLang("ar")} className="arabic_a">
                        العربية
                      </a>
                    )}
                  </div>
                </div>
                <div className="full_width you_acc_margin">
                  <p className="creat_acc_header">{currentLang.fp_lang_19}</p>
                  <p className="create_acc_p_process">
                    {currentLang.fp_lang_23}
                  </p>
                </div>
                <Row className="input_mar">
                  <Col md="12">
                    <div>
                      <div className="form-group">
                        <label className="ar_float_right">
                          {currentLang.fp_lang_20}
                        </label>
                        <Input
                          type="text"
                          className="form-control input-lg"
                          placeholder="Enter the Email"
                          value={addForm.email_address}
                          onChange={(e) =>
                            handleOnAdddata("email_address", e.target.value)
                          }
                          name="email_address"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="input_mar">
                  <Col md="12">
                    <div>
                      <div className="form-group">
                        <label className="ar_float_right">
                          {currentLang.fp_lang_21}
                        </label>
                        <Input
                          type={hidden ? "text" : "password"}
                          className="form-control input-lg"
                          placeholder="Enter the password"
                          value={addForm.password}
                          onChange={(e) =>
                            handleOnAdddata("password", e.target.value)
                          }
                          name="password"
                        />

                        {hidden ? (
                          <i
                            class={`hide_show fa fa-eye ${
                              checkIcon ? "hide_show_pos" : ""
                            }`}
                            aria-hidden="true"
                            onClick={() => setHidden(!hidden)}
                          ></i>
                        ) : (
                          <i
                            class={`hide_show fa fa-eye-slash ${
                              checkIcon ? "hide_show_pos" : ""
                            }`}
                            onClick={() => setHidden(!hidden)}
                          />
                        )}
                      </div>
                      <Link to={"/forgot-password"} className="forgot_password">
                        {currentLang.fp_lang_22}
                      </Link>
                    </div>
                  </Col>
                </Row>
                <div className="full_width">
                  <Button
                    className="sign_up_button"
                    onClick={(e) => {
                      handleLogin(e);
                      disableSubmit(e);
                    }}
                  >
                    {currentLang.fp_lang_18}
                  </Button>
                </div>
                <div className="full_width">
                  <p className="account_register">
                    {currentLang.fp_lang_24}{" "}
                    <Link to="/signup" className="register_no">
                      {currentLang.fp_lang_25}
                    </Link>
                  </p>
                </div>
              </div>
              <div className="tems-login add-form">
                <a href="https://www.foloosi.com/policy" target="_blank" className="ar_float_left">
                  {currentLang?.fp_lang_143}
                </a>
                <a href="https://www.foloosi.com/terms" target="_blank" className="ar_float_left">
                  {currentLang?.fp_lang_144}
                </a>
              </div>
            </div>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
export default Login;
