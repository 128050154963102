import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap";
import ApiGateWay from "../../../Utils/ApiCall";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes } from "../../../Constants/ActionTypes";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { enLang, arLang } from "../../../Constants/en_lang";
import {
  disableSubmit,
  getCookie,
  removeToast,
  toastShow,
} from "../../../Utils/index";
import Loader from "../../Loader";

export const StepTwo = () => {
  const currentLang = getCookie("foloosi_lang") === "ar" ? arLang : enLang;
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const updateState = (actionType, value) => () => {
    dispatch({ type: actionType, payload: value });
    return Promise.resolve();
  };
  const [spinLoader, setSpinLoader] = useState(false);
  const { dashboardlist } = useSelector((state) => state);
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checkedModules, setCheckedModules] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    getCheckList();
    getBusinessinfo();
  }, []);
  const getBusinessinfo = () => {
    const userNo = dashboardlist.valueauth?.user_no;
    ApiGateWay.get(
      `aggregator-details?q={"user_no":"${userNo}"}`,
      (response) => {
        if (response?.success === 1) {
          dispatch(
            updateState(ActionTypes.DASHBOARD, {
              businessListInfo: response?.data,
              businessData: response?.data?.aggregator_busines,
            })
          );
        }
      }
    );
  };
  //   useEffect(() => {
  //     const isAllTrue = Object.values(checkedModules).every(
  //       (obj) => obj === false
  //     );
  //     console.log(isAllTrue, "udhaycheck");
  //     setSelectAll(isAllTrue ? true : false);
  // }, []);

  const getCheckList = () => {
    ApiGateWay.get(`service/module-list`, (response) => {
      if (response?.success === 1) {
        const moduleList = response?.data?.module_list;
        dispatch(updateState(ActionTypes.DASHBOARD, { checkList: moduleList }));
        setLoading(false);

        const services =
          dashboardlist.businessListInfo?.aggregator_business_services?.map(
            (serviceObj) => serviceObj?.service
          ) || [];
        console.log(services, "services");

        const updatedCheckModules =
          moduleList?.reduce(
            (acc, module) => ({
              ...acc,
              [module.module_name]: services.includes(module?.module_name),
            }),
            {}
          ) || {};

        const isAllTrue = Object.values(updatedCheckModules).find(
          (obj) => obj === false
        );
        console.log(
          isAllTrue,
          Object.values(updatedCheckModules),
          "udhaycheck"
        );

        setCheckedModules(updatedCheckModules);
        setSelectAll(isAllTrue === false ? true : false);
      }
      updateModules(response?.data?.module_list);
    });
  };

  const handleCheckboxChange = (module_name) => {
    setCheckedModules((prevState) => {
      const newState = {
        ...prevState,
        [module_name]: !prevState[module_name],
      };

      const isAllTrue = Object.values(newState).every(Boolean);
      console.log(isAllTrue, "isAllTrue");

      setSelectAll(isAllTrue ? false : true);
      return newState;
    });
  };
  const handleStepTwo = () => {
    removeToast();
    const isValid = Object.values(checkedModules)?.some(
      (value) => value === true
    );
    const selectedServices = Object.entries(checkedModules)
      .filter(([key, value]) => value)
      .map(([key, _]) => key);
    let data = {
      merchant_id: dashboardlist?.valueauth?.id,
      select_service: selectedServices,
    };
    console.log(data, "data");
    if (!isValid) {
      toastShow("Please select type services required", "steptwo");
    } else {
      setSpinLoader(true);
      ApiGateWay.post("business-service", data, (response) => {
        setSpinLoader(false);
        if (response?.success === 1) {
          Navigate("/transaction-information");
          toast.success("Service updated succssfully");
          getBusinessinfo();
        } else {
          toast.error(response.message);
        }
      });
    }
  };
  console.log(checkedModules, "checkedModules");
  const toggleSelectAll = () => {
    const isAllTrue = Object.values(checkedModules).every(
      (obj) => obj === true
    );

    setCheckedModules((prevCheckedModules) => {
      const updatedCheckedModules = {};

      dashboardlist?.checkList?.forEach((check) => {
        updatedCheckedModules[check.module_name] = !isAllTrue;
      });

      setSelectAll(isAllTrue);

      return updatedCheckedModules;
    });
  };

  console.log(checkedModules, "checkedmodule");
  const updateModules = (modules) => {
    console.log(modules, "original modules");

    const updatedModules = modules?.map((module, index) => {
      const nameTextKey = `fp_lang_${93 + index}`;
      const descriptionTextKey = `fp_lang_${103 + index}`;
      const nameText = currentLang[nameTextKey] || "Default Name";
      const descriptionText =
        currentLang[descriptionTextKey] || "Default Description";

      console.log(
        nameText,
        descriptionText,
        "Updated Values for Index:",
        index
      );
      return {
        ...module,
        module_name_show: nameText,
        description_show: descriptionText,
      };
    });
    setModules(updatedModules);
    dispatch(
      updateState(ActionTypes.DASHBOARD, {
        checkList: updatedModules,
      })
    );
  };
  return (
    <div className="container">
      {loading && <Loader />}
      <div className="float-start arr_full_width">
        <p className="right_part_title arr_full_width">
          {currentLang.fp_lang_87}
        </p>
        <p className="right_part_desc">{currentLang.fp_lang_88}</p>
        <div className="white_box_title" style={{ fontSize: "18px" }}>
          {currentLang.fp_lang_89}
        </div>
      </div>
      <div className="with_100">
        <Card>
          <CardBody>
            <Row>
              <Col>
                <div className="float-end ar_float_left">
                  <button className="select_all" onClick={toggleSelectAll}>
                    {selectAll
                      ? currentLang.fp_lang_90
                      : currentLang.fp_lang_91}
                  </button>
                </div>
                <>
                  {dashboardlist.checkList?.map((check, i) => (
                    <React.Fragment key={i}>
                      <div
                        className="d-flex mt-3"
                        style={{ width: "100%", float: "left" }}
                      >
                        <Input
                          type="checkbox"
                          className="check_input"
                          id={`check_${i}`}
                          value={check?.module_name}
                          checked={checkedModules[check?.module_name] || false} // Ensure each checkbox is controlled individually
                          onChange={() =>
                            handleCheckboxChange(check?.module_name)
                          }
                        />
                        <div
                          className="sub_check_text"
                          style={{ fontWeight: "600" }}
                        >
                          {check?.module_name_show}
                        </div>
                      </div>
                      <div className="sub_check_text check_box_check">
                        {`${check?.description_show}`}
                      </div>
                    </React.Fragment>
                  ))}
                  <div className="max_value full_width mt-3">
                    <div name="steptwo"></div>
                  </div>
                </>

                {/* <Button
                  onClick={(e) => {
                    handleStepTwo();
                    disableSubmit(e);
                  }}
                  className="mt-4 submit_process"
                >
                  {spinLoader ? (
                    <i class="fa fa-spinner fa-pulse" aria-hidden="true"></i>
                  ) : (
                    currentLang.fp_lang_92
                  )}
                </Button> */}
                {!spinLoader ? (
                  <Button
                    className="mt-4 submit_process"
                    onClick={(e) => {
                      handleStepTwo();
                      disableSubmit(e);
                    }}
                  >
                    {currentLang.fp_lang_92}
                  </Button>
                ) : (
                  <Button className="mt-4 submit_process">
                    <i class="fa fa-spinner fa-pulse" aria-hidden="true"></i>
                  </Button>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};
