import { ActionTypes } from "../../Constants/ActionTypes"

const signupstate = {
    firstname: "",
    lastname: "",
    businessname: "",
    websiteurl: "",
    businessemail: "",
    secondaryemail: "",
    mobilenumber: "",
    secondarynumber: "",
    country: "",
    city: "",
    password: "",
    confirmpassword: "",
    merchant_id:""
}

const langstate = {
    Lang: {}
}

export function signup(state = signupstate, action) {
    switch (action.type) {
        case ActionTypes.SIGNUP:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}
export function lang(state = langstate, action) {
    switch (action.type) {
        case ActionTypes.LANG:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }

}

