import { ActionTypes } from "../../Constants/ActionTypes"

const loginstate = {
    email: "",
    password: "",
    merchant_id:""
}



export function login(state = loginstate,action){
    switch(action.type){
        case ActionTypes.LOGIN:
            return{
                ...state,
                ...action.payload
            }
            default:
                return state;
    }
}