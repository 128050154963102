import React from "react";
import PrivateRouter from "./PrivateRouter";
import PublicRouter from "./PublicRouter";
import { isLoggedIn } from "../Utils";

function Routers() {
  return isLoggedIn() ? (
    <PrivateRouter />
  ) : (
    <PublicRouter />
  );
}

export default Routers;