import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import Login from "./../Components/Login/index";
import Error from "./../Route/Error";
import Signup from "../Components/Login/signup";
import ForgetPassword from "../Components/Login/forgotPassword";
import SecureLogin from "../Components/Login/secureLogin";
import { isLoggedIn } from "../Utils";
import Otp from "../Components/Login/otp";
import Otpresend from "../Components/Login/otpresend";


function PublicRouter() {
  const paths = [
    "/business-details",
    "/business-service",
    "/transaction-information",
    "/bank-details",
    "/proceed-verification",
  ];
  let location = useLocation();
  console.log(location, "location");
  let routeNavigate = useNavigate();
  useEffect(() => {
    const currentPath = location?.pathname;
    const isPathIncluded = paths.some((path) => currentPath.includes(path));

    if (!isLoggedIn() && isPathIncluded) {
      routeNavigate("/login");
    }
  }, []);
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" exact element={<Signup />} />
        <Route path="/signup" exact element={<Signup />} />
        <Route path="/otp-screen" exact element={<Otp />} />
        <Route path="/email-change" exact element={<Otpresend />} />
        <Route path="/login" exact element={<Login />} />
        <Route path="/forgot-password" exact element={<ForgetPassword />} />
        <Route path="/secure-login/admin" exact element={<SecureLogin />} />
        {/* <Route path="/*" element={<Navigate to="/login" replace />} /> */}
        <Route path="/*" element={<Error />} />
      </Routes>
    </React.Fragment>
  );
}

export default PublicRouter;
