import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import Recaptcha from "react-google-invisible-recaptcha";
import axios from "axios";
import ApiGateWay from "../../Utils/ApiCall";
import { Link, useNavigate } from "react-router-dom";
import { ActionTypes } from "../../Constants/ActionTypes"
import {
  disableSubmit,
  getCookie,
  removeToast,
  setCookie,
  showToast,
  toastShow,
} from "../../Utils/index";
import { enLang, arLang } from "../../Constants/en_lang";
import Footer from "../Footer";
import { message } from "antd";
import { useSelector, useDispatch } from "react-redux";

const Signup = () =>{
  document.title = "Sign Up";
  const {signup} =useSelector((state)=> state);
  const dispatch = useDispatch();

  const updateState = (actionType, value) => () => {
      dispatch({ type: actionType, payload: value });
      return Promise.resolve();
  }

  useEffect(() => {
    dispatch(updateState(ActionTypes.SIGNUP, { businessemail:"",merchant_id:"" }));
}, []);

  const navigate = useNavigate();
  const [phonehide, setPhonehide] = useState(true);
  const [hidden, setHidden] = useState(false);
  const [hide, setHide] = useState(false);
  const [checkIcon, setCheckIcon] = useState(false);
  const [eyeicon, setEyeIcon] = useState(false);
  const recaptchaRef = useRef(null);
  const [captchaToken, setCaptchaToken] = useState("");
  const [phoneselect, setPhoneselect] = useState("");
  const [pincode, setPincode] = useState("");
  const [phoneselectsecond, setPhoneselectsecond] = useState("");
  const [pincodesecond, setPincodesecond] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const selectcountry = [
    { label: "United Arab Emirates", value: "United Arab Emirates" },
    { label: "India", value: "India" },
    { label: "America", value: "America" },
  ];
  const [country, setCountry] = useState(selectcountry[0]);
  const [signUpLoader, setSignupLoader] = useState(false);
  const SelectBackground = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#007bff" : "white",
      color: state.isSelected ? "white" : "black",
      cursor: "pointer",
    }),
    menu: (provided, state) => ({
      ...provided,
      maxHeight: "500px",
      position: "absolute", // or "relative" or "fixed"
      zIndex: 9999,
    }),
  };
  const selectcity = [
    { value: "", label: "Choose City" },
    { value: "Dubai", label: "Dubai" },
    { value: "Sharjah", label: "Sharjah" },
    { value: "Abu Dhabi", label: "Abu Dhabi" },
    { value: "Ajmān", label: "Ajmān" },
    { value: "Al Fujayrah", label: "Al Fujayrah" },
    { value: "Umm al Qaywayn", label: "Umm al Qaywayn" },
  ];
  const [addForm, setaddForm] = useState({ agree_check: false });
  const [city, setCity] = useState(selectcity[0]);

  const [phoneValue, setPhoneValue] = useState("");
  const [phoneValuesecond, setPhoneValuesecond] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const handleChange = (phone, country) => {
    setPhonehide(false);
    if (!country?.dialCode) {
      return;
    }
    const reducedPhone = phone.replace(country.dialCode, "").trim();
    const fullPhoneNumber = `${country.dialCode}-${reducedPhone}`;
    setPhoneselect(reducedPhone);
    setPincode(country.dialCode);

    setPhoneValue(fullPhoneNumber);
  };
  console.log(phoneValue, "phone");
  const handleonCityChange = (e) => {
    setCity(e);
  };
  const currentLang = getCookie("foloosi_lang") === "ar" ? arLang : enLang;

  const handleChange2 = (phone, country) => {
    if (!country?.dialCode) {
      return;
    }
    const reducedPhone = phone.replace(country.dialCode, "").trim();
    const fullPhoneNumber = `${country.dialCode}-${reducedPhone}`;
    setPhoneselectsecond(reducedPhone);
    setPincodesecond(country.dialCode);
    setPhoneValuesecond(fullPhoneNumber);
  };
  const handleOnAdddata = (fieldName, value) => {
    setaddForm({
      ...addForm,
      [fieldName]: value,
    });
    if (fieldName ==="agree_check") {
      setIsDisabled(false);
    }
    console.log(addForm);
  };
  const validateName = (name, fieldName) => {
    console.log(fieldName, "typing");
    if (!name?.trim()) {
      return `Please enter ${fieldName}`;
    }
    if (!/^[A-Za-z_ ]+$/.test(name)) {
      return `Please enter a valid ${fieldName}, special characters not allowed`;
    }
    return null;
  };
  const validateWebSite = (name, fieldName) => {
    if (!name?.trim()) {
      return `Please enter ${fieldName}`;
    }
    if (/\s/.test(name.trim())) {
      return ` Please enter valid website url`;
    }
    if (
      !/^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/.test(
        name.replace(/ /g, "")
      )
    ) {
      return ` Please enter valid website url`;
    }
    return null;
  };
  const [captchaTimeout, setCaptchaTimeout] = useState(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      console.log("Captcha resolution timed out");
    }, 60000);
    setCaptchaTimeout(timeout);

    return () => clearTimeout(timeout);
  }, []);
  const validateEmail = (email, fieldName) => {
    if (!email) {
      return `Please enter ${fieldName}`;
    }
    if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)) {
      return `Please enter a valid ${fieldName}`;
    }
    return null;
  };
  const validatePassword = (password, fieldName) =>{
   console.log(checkIcon,"setCheckIcon(true);");
   
    if(!password){
      setCheckIcon(true);
      return `please enter ${fieldName}`
    }    
    if (password.length < 8 || password.length > 12 ) {
      setCheckIcon(true);
      return `Password must contain minimum 8 to maximum 12 character ${fieldName}`
    }
    return null;
  }
  const validateConfirmPassword = (confirmpassword, fieldName) =>{
    if(!confirmpassword){
      return `please enter ${fieldName}`
    }
    if (confirmpassword.length < 8 || confirmpassword.length > 12) {
      return `Password must contain minimum 8 to maximum 12 character ${fieldName}`    }
    return null;
  }
  const nutrinoPhoneverify = (mobile, phoneCode) => {
    const phNumber = `${phoneCode?.trim()}${mobile?.trim()}`;
    return axios
      .get(
        `https://api.foloosi.com/merchant/bulk-link/phone-verify?q={"phone_number":"${phNumber}"}`
      )
      .then((response) => {
        return response?.data?.data?.is_mobile_verified;
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  };
  const nutrinoEmailverify = (email) => {
    const emailID = email.trim();
    return axios
      .get(
        `https://api.foloosi.com/merchant/bulk-link/phone-verify?q={"email":"${emailID}","type":"email"}`
      )
      .then((response) => {
        return response?.data?.data?.is_email_verified;
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  };
  const nutrinoPhoneExit = async (mobile) => {
    return axios
      .get(`https://aflsuser.foloosi.com/v2/check-mobile-exist/${mobile}`)
      .then((response) => {
        return response?.data?.data?.user;
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  };
  const nutrinoEmailExit = async (email) => {
    return axios
      .get(`https://aflsuser.foloosi.com/v2/check-email-exist/${email}`)
      .then((response) => {
        return response?.data?.data?.user;
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  };

  const signInProcess = async () => {
    setIsDisabled(true);   
     setCheckIcon(false);
    setEyeIcon(false)
    setSignupLoader(true);
    removeToast();
    recaptchaRef.current.execute();
    const errors = [
      {
        key: "first_name",
        message: validateName(addForm.first_name, "first name"),
      },
      {
        key: "last_name",
        message: validateName(addForm.last_name, "last name"),
      },
      {
        key: "business_name",
        message: validateName(addForm.business_name, "business name"),
      },
      {
        key: "website_url",
        message: validateWebSite(addForm.website_url, "website"),
      },
      {
        key: "business_email",
        message: validateEmail(addForm.business_email, "primary email"),
      },
      {
        key: "second_email",
        message: validateEmail(addForm.second_email, "secondary email"),
      },
      {
        key: "second_email",
        message:
          addForm.business_email === addForm.second_email &&
          "Primary and secondary email should not be the same",
      },
      {
        key: "phoneselect",
        message: !phoneselect && "Please enter mobile number",
      },
      {
        key: "phoneselectsecond",
        message: !phoneselectsecond && "Please enter secondary mobile number",
      },
      {
        key: "phoneselectsecond",
        message:
          phoneselect === phoneselectsecond &&
          "Primary and secondary mobile number should not be the same",
      },
      !city?.value && { key: "city", message: "Please select city" },
      {
        key: "password" ,
        message: validatePassword(addForm.password, 'password'),
      },

      {
        key: "confirm_password",
        message: validateConfirmPassword(addForm.confirm_password, 'confirm password'),
      },
      {
        key: "confirm_password",
        message: addForm.password !== addForm.confirm_password &&
        "password and Confirm Password should be the same"
      },
      !addForm.agree_check && {
        key: "agree_check",
        message: "Please agree to the terms and conditions",
      },
    ];
    console.log(errors, "errorss");
    const errorMessage = errors.find((error) => error?.message);
    console.log(errorMessage, "errorMessage");
    if (errorMessage) {
      setSignupLoader(false);
      setIsDisabled(false);
      toastShow(errorMessage?.message, errorMessage?.key);
      setSignupLoader(false);
      return;
    }
    if (addForm?.business_email) {
      const isEmailExit = await nutrinoEmailExit(addForm.business_email);
      if (!isEmailExit) {
        setSignupLoader(false);
        setIsDisabled(false);
        toastShow("Email ID is already registered", "business_email");
        return;
      }
    }

    const isEmailValid = await nutrinoEmailverify(addForm.business_email);
    if (addForm?.business_email) {
      if (!isEmailValid) {
        setSignupLoader(false);
        setIsDisabled(false);
        toastShow("Please enter valid primary email id", "business_email");
        return;
      }
    }
    const isPhoneValid = await nutrinoPhoneverify(phoneselect, pincode);
    if (!isPhoneValid) {
      setSignupLoader(false);
      setIsDisabled(false);
      toastShow("Please enter a valid Mobile number", "phoneselect");
      return;
    }
    const isPhoneExit = await nutrinoPhoneExit(phoneselect);
    if (phoneselectsecond && !isPhoneExit) {
      setSignupLoader(false);
      setIsDisabled(false);
      toastShow("mobile number is already registered", "phoneselect");
      return;
    }
    if (!errorMessage) {
      let data = {
        first_name: addForm.first_name,
        last_name: addForm.last_name,
        business_name: addForm.business_name,
        email: addForm.business_email.toLowerCase(),
        secondary_email: addForm.second_email.toLowerCase(),
        mobile: phoneselect,
        phone_code: `+${pincode}`,
        secondary_mobile: phoneselectsecond,
        secondary_phone_code: `+${pincodesecond}`,
        website: addForm.website_url,
        country: country.value,
        city: city.value,
        password:addForm.password,
        confirm_password:addForm.confirm_password

      };
      console.log(data, "data");
      // return false;
      setSignupLoader(true);
      dispatch(updateState(ActionTypes.SIGNUP, { businessemail:addForm.business_email }));
      ApiGateWay.post("v2/merchant/business-signup", data, (response) => {
        if (response?.data) {
          toast.success(response?.message);
          dispatch(updateState(ActionTypes.SIGNUP, { merchant_id:response.data.merchant_id }));
          setSignupLoader(false);
          setTimeout(() => {
            navigate("/otp-screen");
          }, 3000);
        } else {
          setIsDisabled(false);
          setSignupLoader(false);
          toast.error(response?.data?.message);
          toastShow(response?.error?.message,response?.error?.field);
          // console.log(response ,"response?.data");
        
        }
      });
    }
  };
  console.log(formErrors, "formerrors");
  const changeLang = (id) => {
    setCookie(id, "foloosi_lang");
    window.location.reload();
  };

  const onResolved = () => {
    clearTimeout(captchaTimeout);
    setCaptchaToken(recaptchaRef.current.getResponse());
  };
  const defaultCountry = "us";

  const [showHints, setShowHints] = useState(false);
  const [isHintHovered, setIsHintHovered] = useState(false);
  
  const handleMouseOver = () => setShowHints(true);
  const handleMouseOut = () => {
    if (!isHintHovered) {
      setShowHints(false);
    }
  };
  const handleBlur = () => {
    if (!isHintHovered) {
      setShowHints(false);
    }
  };
  const handleFocus = () => setShowHints(true);

  
  return (
    <>
      <div className="container-fluid">
        <Card>
          <CardBody className="p-0 overflow_hidden_x">
            <Recaptcha
              ref={recaptchaRef}
              sitekey="6Lcid84ZAAAAAMfYOPzXid-iZmoRtyH1pTfAxZiG"
              onResolved={onResolved}
            />
            <Row>
              <Col className="margin_right_side float_left_arabic login_box_sign_up">
                <div className="full_width d-flex justify-content-between">
                  <div>
                    <img src={require("../../assets/images/logo_new.png")} />
                  </div>
                  <div className="d-flex float-end sign_up_left_margin">
                    <Link to="/login" className="sign_in">
                      {currentLang.fp_lang_18}
                    </Link>
                    {getCookie("foloosi_lang") == "ar" ? (
                      <a
                        onClick={() => changeLang("en")}
                        className="arabic_a_alt mt-2"
                      >
                        English
                      </a>
                    ) : (
                      <a
                        onClick={() => changeLang("ar")}
                        className="arabic_a_alt mt-2"
                      >
                        العربية
                      </a>
                    )}
                  </div>
                </div>
                <div className="full_width you_acc_margin">
                  <p className="creat_acc_header m-0">
                    {currentLang.fp_lang_1}
                  </p>
                  <p className="create_acc_p_process">
                    {currentLang.fp_lang_2}
                  </p>
                </div>
                <Row>
                  <Col className="input_mar_alt p_0_sm" md="6">
                    <div>
                      <div className="form-group">
                        <label className="control_labels_star control_labels margin_sign_up">
                          {currentLang.fp_lang_3}
                        </label>
                        <Input
                          type="text"
                          className="form-control input-lg"
                          value={addForm.first_name}
                          onChange={(e) =>
                            handleOnAdddata("first_name", e.target.value)
                          }
                          placeholder="Enter First Name"
                          name="first_name"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md="6" className="input_mar_alt p_0_sm">
                    <div>
                      <div className="form-group">
                        <label className="control_labels_star control_labels margin_sign_up">
                          {currentLang.fp_lang_4}
                        </label>
                        <Input
                          type="text"
                          className="form-control input-lg"
                          value={addForm.last_name}
                          onChange={(e) =>
                            handleOnAdddata("last_name", e.target.value)
                          }
                          placeholder="Enter Last Name"
                          name="last_name"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="input_mar_alt p_0_sm" md="6">
                    <div>
                      <div className="form-group">
                        <label className="control_labels_star control_labels margin_sign_up">
                          {" "}
                          {currentLang.fp_lang_5}
                        </label>
                        <Input
                          value={addForm.business_name}
                          onChange={(e) =>
                            handleOnAdddata("business_name", e.target.value)
                          }
                          type="text"
                          className="form-control input-lg"
                          placeholder="Enter Business Name"
                          name="business_name"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md="6" className="input_mar_alt p_0_sm">
                    <div>
                      <div className="form-group">
                        <label className="control_labels_star control_labels margin_sign_up">
                          {" "}
                          {currentLang.fp_lang_6}
                        </label>
                        <Input
                          type="text"
                          className="form-control input-lg"
                          value={addForm.website_url}
                          onChange={(e) =>
                            handleOnAdddata("website_url", e.target.value)
                          }
                          placeholder="Enter website url"
                          name="website_url"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="input_mar_alt p_0_sm" md="6">
                    <div>
                      <div className="form-group">
                        <label className="control_labels_star control_labels margin_sign_up">
                          {" "}
                          {currentLang.fp_lang_7}
                        </label>
                        <Input
                          value={addForm.business_email}
                          onChange={(e) =>
                            handleOnAdddata("business_email", e.target.value)
                          }
                          type="text"
                          name="business_email"
                          className="form-control input-lg"
                          placeholder="Enter Business Email"
                        />
                        <div name="Email"></div>
                      </div>
                    </div>
                  </Col>
                  <Col md="6" className="input_mar_alt p_0_sm">
                    <div>
                      <div className="form-group">
                        <label className="control_labels_star control_labels margin_sign_up">
                          {" "}
                          {currentLang.fp_lang_8}
                        </label>
                        <Input
                          type="text"
                          value={addForm.second_email}
                          onChange={(e) =>
                            handleOnAdddata("second_email", e.target.value)
                          }
                          className="form-control input-lg"
                          placeholder="Enter Secondary Email"
                          invalid={formErrors?.key === "second_email"}
                          name="second_email"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    className="contact_us_rtl mobile_alt input_mar_alt p_0_sm  form-group "
                    md="6"
                  >
                    <label className="control_labels control_labels_star float-none margin_sign_up">
                      {currentLang.fp_lang_9}
                    </label>
                    <div>
                      <PhoneInput
                        value={phoneValue}
                        onChange={handleChange}
                        countryCodeEditable={false}
                        country={"ae"}
                        extraProps={{
                          placeholder: "Your Placeholder Text",
                        }}
                        className="marginBottom phone_num postion_arabic_left your-input-class ltr"
                        enableSearch={true}
                      />
                      <div name="phoneselect"></div>
                      <div name="phone number"></div>
                    </div>
                  </Col>
                  <Col md="6" className="input_mar_alt p_0_sm">
                    <div className="mobile_alt form-group">
                      <label className="control_labels_star control_labels margin_sign_up float-none">
                        {" "}
                        {currentLang.fp_lang_10}
                      </label>
                      <PhoneInput
                        country={"ae"}
                        className={`marginBottom phone_num ltr ${
                          formErrors?.key === "phoneselectsecond"
                            ? "is-invalid"
                            : ""
                        }`}
                        enableSearch={true}
                        value={phoneValuesecond}
                        onChange={handleChange2}
                        placeholder={"Enter Secondary Mobile"}
                        countryCodeEditable={false}
                      />
                      <div name="phoneselectsecond"></div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="input_mar_alt p_0_sm" md="6">
                    <div className="mobile_alt form-group">
                      <label>{currentLang.fp_lang_11}</label>

                      <Select
                        options={selectcountry}
                        value={country}
                        isDisabled={true}
                      />
                    </div>
                  </Col>
                  <Col className="input_mar_alt p_0_sm">
                    <div className="mobile_alt form-group">
                      <label className="control_labels_star control_labels margin_sign_up">
                        {currentLang.fp_lang_12}
                      </label>
                      <Select
                        options={selectcity}
                        value={city}
                        onChange={handleonCityChange}
                        menuPlacement="top"
                        styles={SelectBackground}
                        name="city"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                <Col className="input_mar_alt p_0_sm" md="6">
                    <div>
                      <div className="form-group">
                        <label className="control_labels_star control_labels margin_sign_up">
                          {" "}
                          {currentLang.fp_lang_21}
                        </label>
                        {showHints &&
                        !addForm.password &&<div className="fls-position">
                           <div className="arrow"></div>
                                 <div className="fls-popup">
                                 Password must contain minimum 8 to maximum 12 character
                                 </div>
                             </div>}
                        <Input
                          value={addForm.password}
                          onChange={(e) =>
                            handleOnAdddata("password", e.target.value)
                          }
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          onMouseOver={handleMouseOver}
                           onMouseOut={handleMouseOut}
                          type={hidden ? "text" : "password"}
                          name="password"
                          className="form-control input-lg"
                          placeholder="Enter Password"
                        />
                        
                           {hidden ? (
                          <i
                            class={`hide_show fa fa-eye ${
                              checkIcon ? "hide_show_poc" : ""
                            }`}
                            aria-hidden="true"
                            onClick={() => setHidden(!hidden)}
                          ></i>
                        ) : (
                          <i
                            class={`hide_show fa fa-eye-slash ${
                              checkIcon ? "hide_show_poc" : ""
                            }`}
                            onClick={() => setHidden(!hidden)}
                          />
                        )}
                        <div name="Password"></div>
                      </div>
                    </div>
                  </Col>
                  <Col md="6" className="input_mar_alt p_0_sm">
                    <div>
                      <div className="form-group">
                        <label className="control_labels_star control_labels margin_sign_up">
                          {" "}
                          {currentLang.fp_lang_184}
                        </label>
                        <Input
                          type={hide ? "text" : "password"}
                          value={addForm.confirm_password}
                          onChange={(e) =>
                            handleOnAdddata("confirm_password", e.target.value)
                          }
                          className="form-control input-lg"
                          placeholder="Enter Confirm Password"
                          invalid={formErrors?.key === "confirm_password"}
                          name="confirm_password"
                        />
                      </div>
                    </div>
                  </Col>
                  <div className="full_width mt-3">
                    <Input
                      type="checkbox"
                      className="check_check"
                      checked={addForm.agree_check}
                      onChange={(e) =>
                        handleOnAdddata("agree_check", e.target.checked)
                      }
                    />
                    <label className="accept_terms mt-2 mx-3">
                      {currentLang.fp_lang_13}
                      <a target="_blank" href="https://www.foloosi.com/terms">
                        {" "}
                        {currentLang.fp_lang_14}
                      </a>
                    </label>
                    <div name="agree_check"></div>
                    <div className="full_width mt-4 p-0">
                      {!signUpLoader ? (
                        <Button
                        disabled={isDisabled}
                          className="sign_up_button"
                          onClick={(e) => {
                            signInProcess();
                          }}
                        >
                          {currentLang.fp_lang_15}
                        </Button>
                      ) : (
                        <Button className="sign_up_button">
                          <i
                            class="fa fa-spinner fa-pulse"
                            aria-hidden="true"
                          ></i>
                        </Button>
                      )}
                    </div>
                  </div>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Footer />
      </div>
    </>
  );
}
export default Signup;
