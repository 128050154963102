import React, { useEffect } from "react";
import Routes from './Route/Routers';
import "./assets/scss/theme.scss";
import "./assets/style.css";
import { getCookie, removeCookie, setCookie } from "./Utils/index";
import { ToastContainer } from "react-toastify";
import { reloadWindowToPath } from "gfdu";

const App = () => {
    useEffect(() => {
        if (getCookie('foloosi_lang') == 'ar') {
            document.querySelector("body").classList.add("rtl");
        } else {
            document.querySelector("body").classList.remove("rtl");
        }

    }, [])

    
    function deleteAllCookies() {

        removeCookie("authentication_auth");
    }

    return (
        <React.Fragment>
            {
                <ToastContainer
                    autoClose={1000}
                />
            }
            <Routes />

        </React.Fragment>
    );
};
export default App;