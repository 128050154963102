import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "../Components/Header";

const Layout = () => {
    return (
        <React.Fragment>
            <Header/>
            <div className="main-content">
            <Outlet/>
            </div>
        </React.Fragment>
    );
};
export default Layout;