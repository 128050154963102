import { ActionTypes } from "../../Constants/ActionTypes";

const dashboardstate = {
  businessListInfo: {},
  valueauth:[],
  checkList:[]
}
export function dashboardlist(state = dashboardstate, action) {
  switch (action.type) {
    case ActionTypes.DASHBOARD:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}