import React,{useState, useEffect,useRef} from "react";
import {
    Row,
    Col,
    CardBody,
    Card,
    Input,
    Button,
    FormFeedback,
  } from "reactstrap";
  import { ToastContainer, toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  import PhoneInput from "react-phone-input-2";
  import "react-phone-input-2/lib/style.css";
  import Select from "react-select";
  import Recaptcha from "react-google-invisible-recaptcha";
  import axios from "axios";
  import ApiGateWay from "../../Utils/ApiCall";
  import { Link, useNavigate } from "react-router-dom";
  import {
    disableSubmit,
    getCookie,
    removeToast,
    setCookie,
    showToast,
    toastShow,
  } from "../../Utils/index";
  import { reloadWindowToPath } from "gfdu";
  import { enLang, arLang } from "../../Constants/en_lang";
  import Footer from "../Footer";
  import { message } from "antd";
  import { useSelector, useDispatch } from "react-redux";


const Otp = () =>{
    document.title = "OTP";
    const {signup,login} = useSelector((state)=>state);
    const navigate = useNavigate();
    const [mobileOtp, setMobileOtp] = useState(new Array(6).fill(""));
    const [verifiedOtp, setVerifiedOtp] = useState("");
    const [signUpLoader, setSignupLoader] = useState(false);
    const [errors, setErrors] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);

    const inputRefs = useRef([]);

    const currentLang = getCookie("foloosi_lang") === "ar" ? arLang : enLang;
    const changeLang = (id) => {
        setCookie(id, "foloosi_lang");
        window.location.reload();
      };

      useEffect(() => {
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
        console.log(login?.email);
        
    }, []);

    const otpverify = (e) =>{
        setIsDisabled(true);
        e.preventDefault();
        var data = {
             email:signup?.businessemail ===""? login?.email : signup?.businessemail,
             email_otp:verifiedOtp
        }
        ApiGateWay.post("merchant/business/verify-signup-otp", data, (response) => {
            if (response?.data) {
                let { authentication } = response.data;
                setCookie(authentication, "authentication_auth");
                toast.success(response?.message);
                setTimeout(() => {
                    reloadWindowToPath("/business-details");
                }, 3000);
                setSignupLoader(false);
              } else {
                setSignupLoader(false);
                setMobileOtp(Array(6).fill(""));
                setIsDisabled(false);
                if (response.message ==="Provide valid otp number") {
                    setErrors("please enter valid OTP"); 
                }else{
                    setErrors(response.message); 
                }
               
              }
        })
    }
    const resendotp = (e) =>{
        e.preventDefault();
        var data = {
             email:signup?.businessemail ===""? login?.email : signup?.businessemail,
        }
        ApiGateWay.post("merchant/business/resend-signup-otp", data, (response) => {
            if (response?.data) {
                toast.success(response?.message);
                setSignupLoader(false);
              } else {
                setSignupLoader(false);
                
                toast.error(response?.data?.message);
                toastShow(response?.error?.message,response?.error?.field);
                // console.log(response?.error?.,"response?.data");
              }
        })
    }
      const handleMobileotp = (index, e) => {
        const value = e.target.value;
        if (isNaN(value)) return;

        const newOtp = [...mobileOtp];
        newOtp[index] = value.substring(value.length - 1);
        setMobileOtp(newOtp);

        const combinedOtp = newOtp.join("");
        if (combinedOtp.length === 6) {
            setVerifiedOtp(combinedOtp);
            setIsDisabled(false);
        }else{
            setVerifiedOtp("");
            setIsDisabled(true);
        }
        if (value && index < 5 && inputRefs.current[index + 1]) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleClick = (index) => {
        inputRefs.current[index].setSelectionRange(1, 1);

        if (index > 0 && !mobileOtp[index - 1]) {
            inputRefs.current[mobileOtp.indexOf("")].focus();
        }
    };

    const handleKeyDown = (index, e) => {
        if (
            e.key === "Backspace" &&
            !mobileOtp[index] &&
            index > 0 &&
            inputRefs.current[index - 1]
        ) {
            inputRefs.current[index - 1].focus();
        }
    };
    const handleFocus = () => {
        // Hide the error message when any input field is focused
        setErrors("");
      };
return(
    <>
       <Card className="m-0 h-100 login_bg">
        <CardBody className="container h-100">
          <Row className="justify-content-center h-100 align-items-center">
            <div className="login_box_wrap">
              <div className="login_box">
                <div className="full_width">
                  <a className="login_logo float-start">
                    {/* <img src={ippopay} alt="" /> */}
                  </a>
                  <div className="d-flex float-end sign_up_left_margin mt-2 ar_float_right">
                    {getCookie("foloosi_lang") == "ar" ? (
                      <a
                        onClick={() => changeLang("en")}
                        className="arabic_a ar_float_right"
                      >
                        English
                      </a>
                    ) : (
                      <a onClick={() => changeLang("ar")} className="arabic_a">
                        العربية
                      </a>
                    )}
                  </div>
                </div>
                <div className="full_width you_acc_margin">
                  <p className="creat_acc_header">{currentLang.fp_lang_185}</p>
                </div>
                <Row className="input_mar">
                  <Col md="12">
                    <div className="d-flex justify-content-between">
                      <div className="form-group">
                        <label className="ar_float_right">
                          {signup?.businessemail ===""? login?.email : signup?.businessemail}
                        </label>
                      </div>
                      <Link to={`/email-change`}>
                            Change
                        </Link>
                    </div>
                  </Col>
                </Row>
                <Row className="input_mar">
                  <Col md="12">
                    <div>
                      <div className="form-group">
                        <div className="otp-base">
                            {mobileOtp.map((value, index) => (
                                <input
                                    key={index}
                                     name="otp"
                                    type="text"
                                    ref={(input) => (inputRefs.current[index] = input)}
                                    value={value}
                                    onChange={(e) => handleMobileotp(index, e)}
                                    onClick={() => handleClick(index)}
                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                    onFocus={handleFocus}
                                    maxLength="1"
                                />
                            ))}
                        </div>
                           {errors && <div className="error">{errors}</div>}
                      </div>
                      <Link onClick={resendotp}className="forgot_password">
                        {currentLang.fp_lang_187}
                      </Link>
                    </div>
                  </Col>
                </Row>
                <div className="full_width">
                  <Button
                  disabled={isDisabled}
                    className="sign_up_button"
                    onClick={(e) => {
                      otpverify(e);
                      disableSubmit(e);
                    }}
                  >
                    {currentLang.fp_lang_18}
                  </Button>
                </div>
              </div>
              <div className="tems-login add-form">
                <a href="https://www.foloosi.com/policy" target="_blank" className="ar_float_left">
                  {currentLang?.fp_lang_143}
                </a>
                <a href="https://www.foloosi.com/terms" target="_blank" className="ar_float_left">
                  {currentLang?.fp_lang_144}
                </a>
              </div>
            </div>
          </Row>
        </CardBody>
      </Card>
    </>
)
}

export default Otp