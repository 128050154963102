import { ActionTypes } from "../../Constants/ActionTypes";

const blogstate = {
  blogList: [],
};

const addBlogState = {
  blog_list: [],
  title: "",
  author_name: "",
  blog_category: null,
  blog_description: "",
  blog_content: null,
  blog_image: "",
  blog_website: "",
  blog_websitelist: [],
  blog_categorylist: [],
  category: "",
  tags: [],
  blog_category_id: "",
  blog_keyword: "",
  text: "",
};

const editBlogState = {
  edit_list: {},
  edit_blog_content: null,
  title: "",
  edit_blog_category_id:"",
  edit_blog_category_name:"",
  edit_blog_categorylist: [],
  edit_author_name: "",
  edit_blog_description: "",
  edit_blog_category: "",
  edit_keywords:"",
  edit_tag_list:"",
  edit_image:"",
  edit_blog_website: "",
  edit_blog_websitelist: [],
};



const categorystate = {
  categoryList: [],
  category_name: "",
  website_id: "",
  edit_category: "",
  id: "",
};



export function csrlist(state = blogstate, action) {
  switch (action.type) {
    case ActionTypes.CSR_PAGES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export function csrcategorylist(state = categorystate, action) {
  switch (action.type) {
    case ActionTypes.CSR_BLOG_CATEGORY:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export function addcsr(state = addBlogState, action) {
  switch (action.type) {
    case ActionTypes.CSR_BLOG_ADD:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export function editcsr(state = editBlogState, action) {
  switch (action.type) {
    case ActionTypes.CSR_EDIT_BLOG:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
