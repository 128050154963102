import React, { useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import Loading from "../assets/images/loader.json"
function Loader() {
    return (
        <React.Fragment>

            <div id="ippopay_loading">
                <Lottie

                    animationData={Loading}
                    play
                    loop={false}
                    style={{ width: "160px", height: "160px" }}
                />
            </div>
        </React.Fragment>
    );
}

export default Loader;