import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
} from "reactstrap";
import { enLang, arLang } from "../../../../Constants/en_lang";
import ApiGateWay from "../../../../Utils/ApiCall";
import Select from "react-select";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes } from "../../../../Constants/ActionTypes";
import { StepOneUnified } from "./StepOneBenificaryCard/StepOneUnified";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  disableSubmit,
  getCookie,
  removeToast,
  toastShow,
} from "../../../../Utils/index";
import Loader from "../../../Loader";
import Modal from "react-modal";

export const StepOne = () => {
  const Navigate = useNavigate();
  const currentLang = getCookie("foloosi_lang") === "ar" ? arLang : enLang;
  const { dashboardlist } = useSelector((state) => state);
  const dispatch = useDispatch();
  const updateState = (actionType, value) => () => {
    dispatch({ type: actionType, payload: value });
    return Promise.resolve();
  };
  const [previousAuthorizedType, setPreviousAuthorizedType] = useState(null);
  const [uploadindex, setUploadIndex] = useState("");
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      paddingTop: "0px",
      paddingLeft: "0px",
      paddingRight: "0px",
      width: "90%", // Adjust the percentage as needed for responsiveness
      maxWidth: "616px", // Optional: Set a maximum width for larger screens
    },
  };
  const today = new Date();
  today.setDate(today.getDate() - 1);
  const [loading, setLoading] = useState(true);
  const [addForm, setaddForm] = useState({});
  const [spinLoader, setSpinLoader] = useState(false);
  const [addressForm, setAddressForm] = useState({});
  const [property, setProperty] = useState();
  const [uploadTradeLicense, setUploadTradeLicense] = useState("");
  const [selectedFileOffice, setSelectedFileOffice] = useState("");
  const [previewUrlOffice, setPreviewUrlOffice] = useState(null);
  const [selectedFileIncorp, setSelectedFileIncorp] = useState("");
  const [previewUrlIncorp, setPreviewUrlIncorp] = useState(null);
  const [selectedFilePassport, setSelectedFilePassport] = useState(null);
  const [previewUrlPassport, setPreviewUrlPassport] = useState(null);
  const [selectedFileEmiratesFront, setSelectedFileEmiratesFront] =
    useState(null);
  const [previewUrlEmiratesFront, setPreviewUrlEmiratesFront] = useState(null);
  const fileInputRefIncorp = useRef(null);
  const fileInputRefPassport = useRef(null);
  const fileInputRefEmiratesFront = useRef(null);
  const fileInputRefEmiratesBack = useRef(null);
  const [formError, setFormError] = useState({});
  const [unified, setUnified] = useState([
    {
      nationality: "United Arab Emirates",
      first_name: "",
      middle_name: "",
      last_name: "",
      dob: "",
      passport_number: "",
      passport_exp_date: "",
      passport_doc: "",
      emirates_number: "",
      emirates_exp_date: "",
      emirates_id_front_doc: "",
      emirates_id_back_doc: "",
      visa_id_doc: "",
      visa_exp_date: "",
      company_share: "",
    },
  ]);
  const [authorizedType, setAuthorizedType] = useState({
    label: "(Choose one)",
    value: "",
  });
  const [unifiedOthers, setUnifiedOthers] = useState([]);

  const [defaultCountry, setDefault] = useState({
    value: "United Arab Emirates",
    label: "United Arab Emirates",
  });
  const industryOptions = [
    { label: "(Choose one)", value: "" },
    { label: "Automotive", value: "Automotive" },
    {
      label: "Business Support & Supplies",
      value: "Business Support & Supplies",
    },
    { label: "Computer & Electronics", value: "Computer & Electronics" },
    {
      label: "Construction & Contractors",
      value: "Construction & Contractors",
    },
    { label: "Education", value: "Education" },
    { label: "Entertainment", value: "Entertainment" },
    { label: "Food & Dining", value: "Food & Dining" },
    { label: "Health & Medicine", value: "Health & Medicine" },
    { label: "Home & Garden", value: "Home & Garden" },
    { label: "Legal & Financial", value: "Legal & Financial" },
    { label: "Manufacturing", value: "Manufacturing" },
    { label: "Wholesale & Distribution", value: "Whole sale & Distribution" },
    { label: "Retail", value: "Retail" },
    { label: "Miscellaneous", value: "Miscellaneous" },
    { label: "Personal Care & Service", value: "Personal care & service" },
    { label: "Real Estate", value: "Real Estate" },
    {
      label: "Travel, Tourism & Transportation",
      value: "Travel, Tourism & Transportation",
    },
  ];

  const [industry, setIndustry] = useState("");
  const [isDownloadOpenconfirm, setIsDownloadOpenconfirm] = useState(false);

  const yearsInBusinessOptions = [
    { label: "(Choose one)", value: "" },
    { label: "Below 1 year", value: "Below 1 year" },
    { label: "1 - 2 years", value: "1 - 2 years" },
    { label: "2 - 3 years", value: "2 - 3 years" },
    { label: "3 - 5 years", value: "3 - 5 years" },
    { label: "5 - 10 years", value: "5 - 10 years" },
    { label: "Above 10 years", value: "Above 10 years" },
  ];
  const [yearsInBusiness, setYearsInBusiness] = useState("");

  const SelectBackground = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#007bff" : "white",
      color: state.isSelected ? "white" : "black",
      cursor: "pointer",
    }),
  };
  console.log(selectedFileOffice, "selectedFileOffice");
  const businessFormationOptions = [
    { label: "(Choose one)", value: "" },
    { label: "FZE", value: "FZE" },
    { label: "FZC", value: "FZC" },
    { label: "Sole Proprietorship", value: "Sole Proprietorship" },
    { label: "Partnership", value: "Partnership" },
    {
      label: "Limited Liability Company (LLC)",
      value: "Limited Liability Company (LLC)",
    },
    { label: "Corporation", value: "Corporation" },
    { label: "Non-Profit Organization", value: "Non-Profit Organization" },
    { label: "Trust", value: "Trust" },
    { label: "Others", value: "Others" },
  ];
  const [businessType, setBusinessType] = useState("");
  const currencyList = require("../../../../assets/images/currencyList.json");

  const selectedCurrency =
    currencyList?.map((country) => ({
      value: country?.country,
      label: country?.country,
    })) || [];

  const selectCity = [
    { value: "", label: "(Choose One)" },
    { value: "Dubai", label: "Dubai" },
    { value: "Sharjah", label: "Sharjah" },
    { value: "Abu Dhabi", label: "Abu Dhabi" },
    { value: "Ajmān", label: "Ajmān" },
    { value: "Al Fujayrah", label: "Al Fujayrah" },
    { value: "Umm al Qaywayn", label: "Umm al Qaywayn" },
  ];
  const [city, setCity] = useState("");
  const transformedData = Array.isArray(unified)
    ? unified
        .filter((item) => item.first_name && item.user_type !== "manager")
        .map((item, i) => ({ label: item.first_name, value: i }))
    : [];

  transformedData.push({ label: "others", value: "others" });
  console.log(transformedData, "transformedData");
  const handleOnAdddata = (fieldName, value) => {
    console.log(fieldName, value, "value");
    setaddForm({
      ...addForm,
      [fieldName]: value,
    });
  };
  console.log(unifiedOthers, "dsafas");

  const handleAddressChange = (fieldName, value) => {
    console.log(fieldName, value);
    setAddressForm({
      ...addressForm,
      [fieldName]: value,
    });
    console.log(addressForm, "ass");
  };
  const handleOnData = (e, primaryIndex, selectedData) => {
    console.log(e, primaryIndex, selectedData, "indexindex");
    const { value } = e.target;
    setUnified((prevUnified) =>
      prevUnified?.map((item, index) =>
        index === primaryIndex ? { ...item, [selectedData]: value } : item
      )
    );
  };
  const handleOnDataOthers = (e, primaryIndex, selectedData) => {
    const { value } = e.target;
    setUnifiedOthers((prevUnified) =>
      prevUnified?.map((item, index) =>
        index === primaryIndex ? { ...item, [selectedData]: value } : item
      )
    );
  };
  const handleCancelpaymentlink = () => {
    setIsDownloadOpenconfirm(!isDownloadOpenconfirm);
    if (previousAuthorizedType) {
      setAuthorizedType(previousAuthorizedType);
    }
  };

  useEffect(() => {
    getUserAuth();
  }, []);
  const getBusinessinfo = (id) => {
    ApiGateWay.get(`aggregator-details?q={"user_no":"${id}"}`, (response) => {
      if (response?.success === 1) {
        dispatch(
          updateState(ActionTypes.DASHBOARD, {
            businessListInfo: response?.data,
            businessData: response?.data?.aggregator_busines,
          })
        );
        setUploadTradeLicense(
          response?.data?.aggregator_busines?.trade_license_doc
        );
        setSelectedFileOffice(
          response?.data?.aggregator_busines?.lease_agreement_doc
        );
        setSelectedFileIncorp(
          response?.data?.aggregator_busines?.certificate_doc
        );
        setBusinessType({
          value: response?.data?.aggregator_busines?.type_of_business || "",
          label:
            response?.data?.aggregator_busines?.type_of_business ||
            businessFormationOptions[0]?.label,
        });
        setCity({
          value:
            response?.data?.aggregator_busines?.aggregator_business_addres
              ?.city || "",
          label:
            response?.data?.aggregator_busines?.aggregator_business_addres
              ?.city || selectCity[0]?.label,
        });
        setIndustry({
          value: response?.data?.aggregator_busines?.industry || "",
          label:
            response?.data?.aggregator_busines?.industry ||
            industryOptions[0]?.label,
        });
        setYearsInBusiness({
          value: response?.data?.aggregator_busines?.no_of_years || "",
          label:
            response?.data?.aggregator_busines?.no_of_years ||
            yearsInBusinessOptions[0]?.label,
        });

        let value =
          response?.data?.aggregator_busines?.aggregator_business_owners;
        value?.map((auth, i) => {
          if (auth?.user_type === "manager") {
            setAuthorizedType({
              value: "others",
              label: "others",
            });
          } else if (auth?.authorized_signatory === "Yes") {
            setAuthorizedType({
              value: i,
              label: auth?.first_name,
            });
          }
        });
        console.log(authorizedType, value, "udhayaauth");
        const filteredOwners =
          response?.data?.aggregator_busines?.aggregator_business_owners?.filter(
            (check) => check?.user_type === "manager"
          ) || [];
        setUnifiedOthers(filteredOwners);
        const filteredOwnersunified =
          response?.data?.aggregator_busines?.aggregator_business_owners?.filter(
            (check) => check?.user_type === "owner"
          ) || [{ nationality: "United Arab Emirates" }];
        setUnified(filteredOwnersunified);
        setLoading(false);
        setaddForm(response?.data?.aggregator_busines);
        setAddressForm(
          response?.data?.aggregator_busines?.aggregator_business_addres
        );
        setProperty(response?.data?.aggregator_busines?.office_occupied);
      } else {
        setLoading(false);
      }
    });
  };
  console.log(unifiedOthers, "unifiedOthers");
  const getUserAuth = () => {
    ApiGateWay.get(`merchant/user`, (response) => {
      if (response?.data) {
        dispatch(
          updateState(ActionTypes.DASHBOARD, {
            valueauth: response?.data?.user,
          })
        );
        getBusinessinfo(response?.data?.user?.user_no);
      }
    });
  };
  const handlechangeCurrency = (e, primaryIndex, selectedData) => {
    setUnified((prevUnified) =>
      prevUnified?.map((item, index) =>
        index === primaryIndex ? { ...item, [selectedData]: e.value } : item
      )
    );
  };

  const handlechangeCurrencyOther = (e, primaryIndex, selectedData) => {
    setUnifiedOthers((prevUnified) =>
      prevUnified?.map((item, index) =>
        index === primaryIndex ? { ...item, [selectedData]: e.value } : item
      )
    );
  };
  console.log(unifiedOthers, "unifiedothers");
  console.log(unified, "unified");
  const handleonChartChangeIndustry = (e) => {
    setIndustry(e);
  };
  const handleBusinessYearchange = (e) => {
    setYearsInBusiness(e);
  };
  const handleBusinessFormationOptions = (e) => {
    setBusinessType(e);
  };
  const validImageTypes = [
    "image/jpeg",
    "image/gif",
    "image/png",
    "application/pdf",
  ];
  const handleFileUpload = (event) => {
    removeToast();
    const file = event.target.files[0];
    const fileType = file.type;

    if (!validImageTypes.includes(fileType)) {
      toastShow(
        "Please upload jpeg, gif, png, or pdf format",
        "uploadTradeLicense"
      );
    } else if (file.size < 5120) {
      toastShow(
        "Min upload size should be greater than 5 KB",
        "uploadTradeLicense"
      );
    } else if (file.size > 2048000) {
      toastShow(
        "Max upload size should be less than 2 MB",
        "uploadTradeLicense"
      );
    } else {
      let data = new FormData();
      data.append("file", file);
      data.append("upload_preset", "ifslewdl");
      data.append("api_key", "724539947133222");

      axios
        .post("https://api.cloudinary.com/v1_1/dzoacacm1/raw/upload", data)
        .then((response) => {
          setUploadTradeLicense(response?.data?.secure_url);
          // setUploadTradeLicense("");
        })
        .catch((error) => {
          setUploadTradeLicense(null);
        });
    }
  };
  const handleFileAgreement = (event) => {
    removeToast();
    const file = event.target.files[0];
    const fileType = file.type;
    if (!validImageTypes.includes(fileType)) {
      toastShow(
        "Please upload jpeg, gif, png, or pdf format",
        "selectedFileOffice"
      );
    } else if (file.size < 5120) {
      toastShow(
        "Min upload size should be greater than 5 KB",
        "selectedFileOffice"
      );
    } else if (file.size > 2048000) {
      toastShow(
        "Max upload size should be less than 2 MB",
        "selectedFileOffice"
      );
    } else {
      let data = new FormData();
      data.append("file", file);
      data.append("upload_preset", "ifslewdl");
      data.append("api_key", "724539947133222");
      axios
        .post("https://api.cloudinary.com/v1_1/dzoacacm1/raw/upload", data)
        .then((response) => {
          setSelectedFileOffice(response?.data?.secure_url);
        });
    }
  };

  const handleCertify = (event) => {
    const file = event.target.files[0];
    let data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "ifslewdl");
    data.append("api_key", "724539947133222");
    axios
      .post("https://api.cloudinary.com/v1_1/dzoacacm1/raw/upload", data)
      .then((response) => {
        setSelectedFileIncorp(response?.data?.secure_url);
      });
  };

  const handleCitySelect = (e) => {
    setCity(e);
  };
  const handleAuthorized = (e) => {
    setPreviousAuthorizedType(authorizedType);
    setAuthorizedType(e);
    if (e.value !== authorizedType?.value) {
      setIsDownloadOpenconfirm(!isDownloadOpenconfirm);
    }
    if (authorizedType?.value !== "others") {
      setUnifiedOthers([]);
    }
  };
  const uploadToCloudinary = async (file) => {
    console.log(file, "url");
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ifslewdl");
    formData.append("api_key", "724539947133222");

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dzoacacm1/raw/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        const errorResponse = await response.text();
        console.error("Upload error:", errorResponse);
        throw new Error("Upload failed");
      }

      const data = await response.json();
      console.log("Cloudinary response:", data);

      if (!data.secure_url) {
        console.error("Secure URL not found in Cloudinary response");
        throw new Error("Upload failed");
      }

      return data.secure_url;
    } catch (error) {
      console.error("Error uploading to Cloudinary", error);
      return null;
    }
  };
  const handleFileChange = async (event, index, key) => {
    removeToast();
    console.log(event, index, key, "indexindex");
    const selectedFile = event.target.files[0];

    const fileType = selectedFile.type;

    if (!validImageTypes.includes(fileType)) {
      toastShow("Please upload jpeg, gif, png, or pdf format", key, index);
      return;
    } else if (selectedFile.size < 5120) {
      toastShow("Min upload size should be greater than 5 KB", key, index);
      return;
    } else if (selectedFile.size > 2048000) {
      toastShow("Max upload size should be less than 2 MB", key, index);
      return;
    }

    try {
      const cloudinaryUrl = await uploadToCloudinary(selectedFile);

      if (!cloudinaryUrl) {
        console.error("Error uploading file");
        return;
      }

      setUnified((prevUnified) => {
        const newUnified = [...prevUnified];
        const updatedItem = {
          ...newUnified[index],
          [`${key}`]: cloudinaryUrl,
        };
        newUnified[index] = updatedItem;
        return newUnified;
      });
    } catch (error) {
      console.error("Error handling file change", error);
    }
  };
  console.log(unified, "unifieldfiled");
  const handleFileChangeOthers = async (event, index, key) => {
    removeToast();
    const selectedFile = event.target.files[0];
    const fileType = selectedFile.type;
    if (!validImageTypes.includes(fileType)) {
      toastShow(
        "Please upload jpeg, gif, png, or pdf format",
        `${key}_other`,
        index
      );
      return;
    } else if (selectedFile.size < 5120) {
      toastShow(
        "Min upload size should be greater than 5 KB",
        `${key}_other`,
        index
      );
      return;
    } else if (selectedFile.size > 2048000) {
      toastShow(
        "Max upload size should be less than 2 MB",
        `${key}_other`,
        index
      );
      return;
    }
    const cloudinaryUrl = await uploadToCloudinary(selectedFile);
    if (!cloudinaryUrl) {
      console.error("Error uploading file");
      return;
    }
    setUnifiedOthers((prevUnified) => {
      const newUnified = [...prevUnified];
      const updatedItem = { ...newUnified[index], [`${key}`]: cloudinaryUrl };
      newUnified[index] = updatedItem;
      return newUnified;
    });
  };

  const clearSelection = (key) => {
    if (key === "") {
      setSelectedFilePassport(null);
      setPreviewUrlPassport(null);
    } else {
      setSelectedFileEmiratesFront(null);
      setPreviewUrlEmiratesFront(null);
    }
    if (key === "" && fileInputRefPassport.current) {
      fileInputRefPassport.current.value = null;
    } else if (key === "emifront" && fileInputRefEmiratesFront.current) {
      fileInputRefEmiratesFront.current.value = null;
    } else {
      fileInputRefEmiratesBack.current.value = null;
    }
  };
  useEffect(() => {
    return () => {
      if (previewUrlOffice) {
        URL.revokeObjectURL(previewUrlOffice);
      } else {
        URL.revokeObjectURL(previewUrlIncorp);
      }
    };
  }, []);

  console.log(authorizedType, unified, transformedData, "udhaya");
  const isAtLeast18YearsOld = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age >= 18;
  };
  const gccCountries = [
    "Bahrain",
    "Saudi Arabia",
    "Kuwait",
    "Qatar",
    "Oman",
    "United Arab Emirates",
  ];
  const nameRegex = /^[A-Za-z_ ]+$/;
  const urlRegex =
    /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
  const isNotEmpty = (value) => value && value.trim() !== "";
  const isValidName = (name) => nameRegex.test(name?.trim());
  const isValidateZero = (value) => value != 0;

  const validateField = (
    field,
    message,
    validator = (val) => val != null && val !== ""
  ) => {
    console.log(field, message, validator, "validationfield");
    let emptyFields = [];
    let emptyFieldsOthers = [];

    unified?.forEach((obj, index) => {
      if (!validator(obj[field])) {
        emptyFields.push({ index, field, message });
      }
    });

    if (authorizedType?.value === "others") {
      unifiedOthers?.forEach((obj, index) => {
        if (!validator(obj[field])) {
          emptyFieldsOthers.push({ index, field, message });
        }
      });
    }

    if (emptyFields.length > 0) {
      emptyFields.forEach(({ index, field, message }) => {
        console.log(index, field, message, "messagemessage");
        toastShow(message, field, index);
      });
      return false;
    }

    if (emptyFieldsOthers.length > 0) {
      emptyFieldsOthers.forEach(({ index, field, message }) => {
        console.log(index, field, message, "messagemessage");
        toastShow(message, `${field}_other`, index);
      });
      return false;
    }

    return true;
  };

  const handlesubmit = () => {
    removeToast();
    const today = new Date().toISOString().split("T")[0];
    if (!addForm?.legal_name) {
      toastShow("Please enter legal name", "legal_name");
    } else if (!nameRegex.test((addForm?.legal_name).trim())) {
      toastShow(
        "Please enter valid legal name, Special characters not allowed",
        "legal_name"
      );
    } else if (!addForm?.operating_name) {
      toastShow("Please enter operating name", "operating_name");
    } else if (!nameRegex.test((addForm?.operating_name).trim())) {
      toastShow(
        "Please enter valid operating name, Special characters not allowed",
        "operating_name"
      );
    } else if (!industry.value) {
      toastShow("Please select the industry", "industry");
    } else if (!yearsInBusiness.value) {
      toastShow("Please select the years in business", "yearsInBusiness");
    } else if (!addForm?.website) {
      toastShow("Please enter the website", "website");
    } else if (/\s/.test(addForm?.website.trim())) {
      toastShow("Please enter a valid website", "website");
    } else if (!urlRegex.test(addForm?.website.replace(/ /g, ""))) {
      toastShow("Please enter valid website", "website");
    } else if (!addForm?.description) {
      toastShow("Please add description", "descriptions");
    } else if (addForm?.description == 0) {
      toastShow("Please enter valid description", "descriptions");
    } else if (!businessType.value) {
      toastShow("Please select type business", "businessType");
    } else if (
      businessType.value === "Others" &&
      !addForm?.type_of_business_text
    ) {
      toastShow("Please enter type of business text", "type_of_business_text");
    } else if (!uploadTradeLicense) {
      toastShow("Please upload trade license", "uploadTradeLicense");
    } else if (!addForm?.trade_license_no) {
      toastShow("Please enter trade license number", "trade_license_no");
    } else if (addForm?.trade_license_no == 0) {
      toastShow("Please enter valid trade license number", "trade_license_no");
    } else if (!addForm?.trade_license_issue) {
      toastShow("Please enter trade license issue date", "trade_license_issue");
    } else if (addForm?.trade_license_issue > today) {
      toastShow(
        "Trade license issue date cannot be in the future date",
        "trade_license_issue"
      );
    } else if (!addForm?.trade_license_exp) {
      toastShow("Please enter trade license expiry date", "trade_license_exp");
    } else if (!defaultCountry.value) {
      toastShow("Please enter your country", "defaultCountry");
    } else if (!addressForm?.address_line_1) {
      toastShow("Please enter address", "address_line_1");
    } else if (addressForm?.address_line_1 == 0) {
      toastShow("Please enter valid address", "address_line_1");
    } else if (!city.value) {
      toastShow("Please enter your city", "city");
    } else if (!addressForm.po_box) {
      toastShow("Please enter po box", "po_box");
    } else if (addressForm.po_box == 0) {
      toastShow("Please enter valid po box", "po_box");
    } else if (property !== "Rented" && property !== "Owned") {
      toastShow("Please select type of office", "property_error");
    } else if (!selectedFileOffice) {
      toastShow("Please upload lease agreement", "selectedFileOffice");
    } else if (
      !validateField("first_name", "Please enter first name") ||
      !validateField("last_name", "Please enter last name") ||
      !validateField(
        "first_name",
        "Please enter valid first name. Special characters not allowed",
        isValidName
      ) ||
      !validateField(
        "last_name",
        "Please enter valid last name. Special characters not allowed",
        isValidName
      ) ||
      !validateField("dob", "Please enter date of birth") ||
      !validateField("dob", "The minimum age is 18", isAtLeast18YearsOld) ||
      !validateField("nationality", "Please select nationality") ||
      !validateField("passport_number", "Please enter passport number") ||
      !validateField(
        "passport_number",
        "Please enter valid passport number",
        isValidateZero
      ) ||
      !validateField(
        "passport_exp_date",
        "Please enter passport expiry date"
      ) ||
      !validateField("passport_doc", "Please upload passport copy") ||
      !validateField(
        "emirates_number",
        "Please enter emirates number",
        isValidateZero
      ) ||
      !validateField(
        "emirates_exp_date",
        "Please enter emirates id expiry date"
      ) ||
      !validateField(
        "emirates_id_front_doc",
        "Please upload emirates id (front)"
      ) ||
      !validateField("emirates_id_back_doc", "Please upload emirates id (back)")
    ) {
      return;
    } else if (gccCountries) {
      for (let index = 0; index < unified.length; index++) {
        const applicant = unified[index];
        if (!gccCountries.includes(applicant.nationality)) {
          if (!applicant.visa_id_doc) {
            toastShow("Please upload visa copy", "visa_id_doc", index);
            return;
          }
          if (!applicant.visa_exp_date) {
            toastShow("Please enter visa expiry date", "visa_exp_date", index);
            return;
          }
        }
      }

      if (authorizedType.value === "others") {
        for (let index = 0; index < unifiedOthers.length; index++) {
          const applicant = unifiedOthers[index];
          console.log(applicant, "applicant");
          if (!gccCountries.includes(applicant.nationality)) {
            if (!applicant.visa_id_doc) {
              toastShow("Please upload visa copy", "visa_id_doc_other", index);
              return;
            }
            if (!applicant.visa_exp_date) {
              toastShow(
                "Please enter visa expiry date",
                "visa_exp_date_other",
                index
              );
              return;
            }
          }
        }
      }
      if (unified?.length > 0) {
        let allConditionsSatisfied = true;

        unified?.forEach((share, i) => {
          if (i !== 4 || share.user_type !== "manager") {
            const companyShare = share.company_share;

            if (!companyShare) {
              toastShow("Please enter % of share", "company_share", i);
              allConditionsSatisfied = false;
              return;
            }

            if (isNaN(companyShare)) {
              toastShow("Please enter valid % of share", "company_share", i);
              allConditionsSatisfied = false;
              return;
            } else if (companyShare <= 0) {
              toastShow(
                "Please enter a number above 0% of share",
                "company_share",
                i
              );

              allConditionsSatisfied = false;
              return;
            } else if (companyShare > 100) {
              toastShow(
                "Please enter a number below 100% of share",
                "company_share",
                i
              );
              allConditionsSatisfied = false;
              return;
            }
          }
        });

        if (allConditionsSatisfied) {
          if (authorizedType?.value === "") {
            toastShow(
              "Please select atleast 1 authorised signatory",
              "authorised"
            );
            allConditionsSatisfied = false;
          } else {
            // alert("api hit");
            // return false;
            setSpinLoader(true);
            postUPdateAPi();
          }
        }
      }
    } else {
    }
  };
  console.log(unified, "unifiled");
  const postUPdateAPi = () => {
    let updatedUnifiedOthers;
    if (authorizedType?.value === "others") {
      updatedUnifiedOthers = unifiedOthers?.map((obj) => ({
        ...obj,
        user_type: obj?.user_type || "manager",
      }));
    }

    const updatedUnified = unified?.map((obj) => ({
      ...obj,
      user_type: obj?.user_type || "owner",
    }));
    const combinedArray = [...(updatedUnifiedOthers || []), ...updatedUnified];
    const filterdata = combinedArray?.map((list, i) => {
      const visaFields = !gccCountries?.includes(list?.nationality)
        ? {
            visa_id_proof: list?.visa_id_doc,
            visa_exp_date: list?.visa_exp_date,
          }
        : {
            visa_id_proof: "",
            visa_exp_date: "",
          };
      return {
        first_name: list?.first_name,
        last_name: list?.last_name,
        nationality: list?.nationality,
        middle_name: list?.middle_name,
        dob: list?.dob,
        emirates_exp_date: list?.emirates_exp_date,
        passport_exp_date: list?.passport_exp_date,
        passport_number: list?.passport_number,
        emirates_number: list?.emirates_number,
        company_share: list?.company_share,
        authorized_signatory: authorizedType?.value === i ? "Yes" : "No",
        user_type: list?.user_type,
        passport_proof: list?.passport_doc,
        emirates_id_front_proof: list?.emirates_id_front_doc,
        emirates_id_back_proof: list?.emirates_id_back_doc,
        ...visaFields,
      };
    });

    console.log(combinedArray, filterdata, "updatedUnifiedOthers");
    let data = {
      trade_license_proof: uploadTradeLicense,
      lease_agreement_proof: selectedFileOffice,
      share_certificate_proof: selectedFileIncorp,
      aggregator_business_id: dashboardlist.businessData?.id,
      merchant_id: dashboardlist.valueauth?.id,
      legal_name: addForm?.legal_name,
      operating_name: addForm?.operating_name,
      industry: industry.value,
      years_in_business: yearsInBusiness?.value,
      website: addForm?.website,
      description: addForm?.description,
      type_of_business: businessType.value,
      type_of_business_text: addForm?.type_of_business_text,
      trade_license_number: addForm?.trade_license_no,
      trade_license_issue: addForm?.trade_license_issue,
      trade_license_exp: addForm?.trade_license_exp,
      aggregator_business_address_id: dashboardlist.businessData
        ?.aggregator_business_addres?.id
        ? dashboardlist.businessData?.aggregator_business_addres?.id
        : "",
      country: defaultCountry.value,
      city: city.value,
      po_box: addressForm?.po_box,
      address_line_1: addressForm?.address_line_1,
      address_line_2: addressForm?.address_line_2,
      address_line_3: addressForm?.address_line_3,
      property: property,
      shareholderdetails: filterdata,
    };
    console.log(data, "dataa");
    // return false
    postBusinessData(data);
  };
  const postBusinessData = (data) => {
    ApiGateWay.post("business-details", data, (response) => {
      setSpinLoader(true);
      if (response?.success === 1) {
        Navigate("/business-service");
        setSpinLoader(false);
        toast.success("Your business details updated successfully ");
      } else {
        setSpinLoader(false);
        toast.error(response?.message);
      }
    });
  };

  console.log(unifiedOthers, "unifiedOthers");

  const handleonDetelechange = () => {
    if (authorizedType?.value === "others") {
      if (unifiedOthers?.length === 0) {
        setUnifiedOthers((prevOthers) => [
          ...prevOthers,
          { nationality: "United Arab Emirates", user_type: "manager" },
        ]);
      }
      setIsDownloadOpenconfirm(!isDownloadOpenconfirm);
    } else {
      setIsDownloadOpenconfirm(!isDownloadOpenconfirm);
    }
  };
  console.log(selectedFileIncorp, "authorizedTypeauthorizedType");
  return (
    <div className="container">
      {loading && <Loader />}

      <div className="float-start float_none">
        <p className="right_part_title arr_full_width ar_float_right">
          {currentLang.fp_lang_36}
        </p>
        <p className="right_part_desc ar_float_right">
          {currentLang.fp_lang_37}
        </p>
        <div className="white_box_title">{currentLang.fp_lang_38}</div>
      </div>
      <div className="with_100">
        <Card>
          <CardBody>
            <Row>
              <Col md="6" lg="4" className="mb-4">
                <p className="control_labels control_labels_star">
                  {currentLang.fp_lang_39}
                </p>
                <Input
                  type="text"
                  placeholder="Legal Name"
                  value={addForm?.legal_name}
                  onChange={(e) =>
                    handleOnAdddata("legal_name", e.target.value)
                  }
                  name="legal_name"
                />
              </Col>
              <Col md="6" lg="4" className="mb-4">
                <p className="control_labels control_labels_star">
                  {currentLang.fp_lang_40}
                </p>
                <Input
                  type="text"
                  placeholder="Operating Name"
                  value={addForm?.operating_name}
                  onChange={(e) =>
                    handleOnAdddata("operating_name", e.target.value)
                  }
                  name="operating_name"
                />
              </Col>
              <Col md="6" lg="4" className="mb-4">
                <p className="control_labels control_labels_star">
                  {currentLang.fp_lang_41}
                </p>
                <Select
                  styles={SelectBackground}
                  options={industryOptions}
                  onChange={handleonChartChangeIndustry}
                  value={industry}
                  name="industry"
                />
              </Col>
              <Col md="6" lg="4" className="mb-4">
                <p
                  className="control_labels  mb-0 control_labels_star"
                  style={{ margin: "48px 0 0 0 !important" }}
                >
                  {currentLang.fp_lang_43}
                </p>
                <Select
                  styles={SelectBackground}
                  options={yearsInBusinessOptions}
                  onChange={handleBusinessYearchange}
                  value={yearsInBusiness}
                  className="margin_top_type"
                  name="yearsInBusiness"
                />
              </Col>

              <Col md="6" lg="4" className="mb-4">
                <p className="control_labels control_labels_star">
                  {currentLang.fp_lang_42}
                </p>
                <Input
                  className="comment_box"
                  placeholder="Description"
                  value={addForm?.description}
                  name="descriptions"
                  type="text"
                  onChange={(e) =>
                    handleOnAdddata("description", e.target.value)
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md="6" lg="4" className="mb-4">
                <p className="control_labels control_labels_star">
                  {currentLang.fp_lang_44}
                </p>
                <Input
                  type="text"
                  placeholder="Website URL"
                  value={addForm?.website}
                  onChange={(e) => handleOnAdddata("website", e.target.value)}
                  name="website"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
      <div className="float-start arr_full_width">
        <p className="white_box_title arr_full_width ar_float_right">
          {currentLang.fp_lang_45}
        </p>
      </div>
      <div className="col-md-12">
        <Card className="col-12">
          <CardBody>
            <Row md="12">
              <Col className="mb-4">
                <p className="control_labels control_labels_star">
                  {currentLang.fp_lang_46}
                </p>
                <Select
                  styles={SelectBackground}
                  options={businessFormationOptions}
                  onChange={handleBusinessFormationOptions}
                  value={businessType}
                  className="morgin_top_head"
                  name="businessType"
                />
              </Col>
            </Row>
            {businessType.value === "Others" && (
              <Row>
                <Col md="6" lg="4" className="mb-4">
                  <p className="control_labels">{currentLang.fp_lang_53}</p>
                  <Input
                    type="text"
                    value={addForm?.type_of_business_text}
                    onChange={(e) =>
                      handleOnAdddata("type_of_business_text", e.target.value)
                    }
                    name="type_of_business_text"
                  />
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </div>

      <div className="float-start white_box_title arr_full_width">
        <p className="white_box_title arr_full_width ar_float_right">
          {currentLang.fp_lang_47}
        </p>
      </div>
      <div className="with_100">
        <Card>
          <CardBody>
            <Row>
              <Col md="6" lg="4" className="date-input-container mb-4">
                <p className="control_labels control_labels_star">
                  {currentLang.fp_lang_48}
                </p>
                <label
                  htmlFor="imagesbanner"
                  name="uploadTradeLicense"
                  className="upload_img_banner"
                >
                  Trade License
                </label>
                <Input
                  type="file"
                  onChange={handleFileUpload}
                  className="d-none"
                  placeholder="DD/MM/YYYY"
                  id="imagesbanner"
                />
                {!uploadTradeLicense ? (
                  <FormText color="muted" className="font_choose">
                    {currentLang?.fp_lang_145}
                  </FormText>
                ) : (
                  <a
                    className="img_down"
                    target="_blank"
                    href={uploadTradeLicense}
                  >
                    {currentLang.fp_lang_52}
                  </a>
                )}
              </Col>
              <Col md="6" lg="4" className="mb-4">
                <p className="control_labels control_labels_star">
                  {currentLang.fp_lang_49}
                </p>
                <Input
                  type="text"
                  placeholder="Trade License No"
                  value={addForm?.trade_license_no}
                  onChange={(e) =>
                    handleOnAdddata("trade_license_no", e.target.value)
                  }
                  name="trade_license_no"
                />
              </Col>
              <Col md="6" lg="4" className="mb-4">
                <p className="control_labels control_labels_star">
                  {currentLang.fp_lang_50}
                </p>
                <Input
                  type="date"
                  placeholder=""
                  value={addForm?.trade_license_issue}
                  onChange={(e) =>
                    handleOnAdddata("trade_license_issue", e.target.value)
                  }
                  name="trade_license_issue"
                  max={new Date().toISOString().split("T")[0]}
                />
              </Col>
              <Col md="6" lg="4" className="mb-4">
                <p className="control_labels control_labels_star">
                  {currentLang.fp_lang_51}
                </p>
                <Input
                  type="date"
                  placeholder=""
                  value={addForm?.trade_license_exp}
                  name="trade_license_exp"
                  onChange={(e) =>
                    handleOnAdddata("trade_license_exp", e.target.value)
                  }
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
      <div className="float-start right_part_title arr_full_width">
        <p className="white_box_title arr_full_width ar_float_right">
          {currentLang.fp_lang_54}
        </p>
      </div>
      <div className="with_100">
        <Card>
          <CardBody>
            <Row>
              <Col md="6" lg="4" className="mb-4">
                <p className="control_labels control_labels_star">
                  {currentLang.fp_lang_55}
                </p>
                <Select
                  value={defaultCountry}
                  className="morgin_top_head"
                  isDisabled={true}
                  name="defaultCountry"
                />
              </Col>
              <Col md="6" lg="4" className="mb-4">
                <p className="control_labels control_labels_star">
                  {currentLang.fp_lang_56}
                </p>
                <Input
                  type="text"
                  placeholder="Address Line 1"
                  value={addressForm?.address_line_1}
                  onChange={(e) =>
                    handleAddressChange("address_line_1", e.target.value)
                  }
                  name="address_line_1"
                />
              </Col>
              <Col md="6" lg="4" className="mb-4">
                <p className="control_labels control_labels_star">
                  {currentLang.fp_lang_57}
                </p>
                <Select
                  styles={SelectBackground}
                  options={selectCity}
                  onChange={handleCitySelect}
                  value={city}
                  className="morgin_top_head"
                  name="city"
                />
              </Col>
              <Col md="6" lg="4" className="mb-4">
                <p className="control_labels">{currentLang.fp_lang_58}</p>
                <Input
                  type="text"
                  placeholder="Address Line 2"
                  value={addressForm?.address_line_3}
                  onChange={(e) =>
                    handleAddressChange("address_line_3", e.target.value)
                  }
                />
              </Col>
              <Col md="6" lg="4" className="mb-4">
                <p className="control_labels control_labels_star">
                  {currentLang.fp_lang_59}
                </p>
                <Input
                  type="text"
                  placeholder="PO BOX"
                  value={addressForm?.po_box}
                  onChange={(e) =>
                    handleAddressChange("po_box", e.target.value)
                  }
                  name="po_box"
                />
              </Col>
              <Col md="6" lg="4" className="mb-4">
                <p className="control_labels">{currentLang.fp_lang_60}</p>
                <Input
                  type="text"
                  placeholder="Address Line 3"
                  value={addressForm?.address_line_2}
                  onChange={(e) =>
                    handleAddressChange("address_line_2", e.target.value)
                  }
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
      <div className="float-start right_part_title arr_full_width">
        <p className="white_box_title arr_full_width ar_float_right">
          {currentLang.fp_lang_61}
        </p>
      </div>
      <div className="with_100">
        <Card>
          <CardBody>
            <Row>
              <Col md="3" sm="6" xs="6" className="mb-4">
                <Label check className="mx-1 control_labels d-flex">
                  <input
                    type="radio"
                    checked={property === "Rented" ? true : false}
                    onChange={() => setProperty("Rented")}
                    name="property"
                  />
                  <span> {currentLang.fp_lang_62}</span>
                </Label>
                <div name="property_error"></div>
              </Col>
              <Col md="3" sm="6" xs="6" className="mb-4">
                <Label check className="mx-1 control_labels d-flex">
                  <input
                    type="radio"
                    checked={property === "Owned" ? true : false}
                    onChange={() => setProperty("Owned")}
                    name="property"
                  />
                  <span>{currentLang.fp_lang_63}</span>
                </Label>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md="6" lg="4" className="mb-4">
                <p className="control_labels_star control_labels">
                  {currentLang.fp_lang_64}
                </p>
                <FormGroup>
                  <div style={{ position: "relative" }}>
                    <label
                      htmlFor="lease"
                      className="upload_img_banner"
                      name="selectedFileOffice"
                    >
                      Upload lease agreement / Title deed
                    </label>
                    <Input
                      type="file"
                      name="file"
                      id="lease"
                      className="d-none"
                      onChange={(e) => handleFileAgreement(e)}
                    />
                  </div>
                  {!selectedFileOffice ? (
                    <FormText color="muted" className="choose_file">
                      {currentLang.fp_lang_145}
                    </FormText>
                  ) : (
                    <FormText color="blue">
                      <a
                        href={selectedFileOffice}
                        target="_blank"
                        alt="Preview"
                        className="img_down"
                      >
                        {currentLang.fp_lang_52}
                      </a>
                    </FormText>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
      <div className="float-start right_part_title arr_full_width">
        <p className="white_box_title arr_full_width ar_float_right">
          {currentLang.fp_lang_65}
        </p>
        <span className="right_part_desc">{currentLang.fp_lang_66}</span>
        <p className="white_box_title_alt arr_full_width">
          {currentLang.fp_lang_67}
        </p>
      </div>
      <div className="full_width">
        <Card>
          {/* <CardBody> */}
          <Row className="p-3">
            <p className="control_labels">{currentLang.fp_lang_68}</p>
            <Col md="6" lg="4" className="mb-4">
              <FormGroup>
                <div style={{ position: "relative" }}>
                  <label
                    htmlFor="certificate"
                    className="upload_img_banner"
                    name="selectedFileIncorp"
                  >
                    Upload Certificate
                  </label>
                  <Input
                    type="file"
                    name="file"
                    id="certificate"
                    className="d-none"
                    onChange={(e) => handleCertify(e)}
                    // ref={fileInputRefIncorp}
                  />
                </div>
                {!selectedFileIncorp ? (
                  <FormText color="muted" className="choose_file">
                    {currentLang.fp_lang_145}
                  </FormText>
                ) : (
                  <FormText color="blue" className="ar_float_right">
                    <a
                      href={selectedFileIncorp}
                      target="_blank"
                      alt="Preview"
                      className="img_down"
                    >
                      {currentLang.fp_lang_52}
                    </a>
                  </FormText>
                )}
              </FormGroup>
            </Col>
          </Row>
          {/* </CardBody> */}
        </Card>
      </div>
      <div className="with_100">
        <StepOneUnified
          dashboardList={dashboardlist}
          handleOnAdddata={handleOnAdddata}
          handleFileChange={handleFileChange}
          SelectBackground={SelectBackground}
          unified={unified}
          setUnified={setUnified}
          handleOnData={handleOnData}
          clearSelection={clearSelection}
          handlechangeCurrency={handlechangeCurrency}
          setAuthorizedType={setAuthorizedType}
          setUploadIndex={setUploadIndex}
        />
        <div className="float-start ar_float_right arr_full_width">
          <p className="white_box_title arr_full_width ar_float_right">
            {currentLang.fp_lang_69}
          </p>
        </div>
        <div className="col-12">
          <Card className="col-12">
            <CardBody>
              <p className="control_labels control_labels_star">
                {currentLang.fp_lang_70}
              </p>
              <Select
                styles={SelectBackground}
                options={transformedData}
                onChange={handleAuthorized}
                value={authorizedType}
                className="morgin_top_head"
                name="authorised"
                menuPlacement={
                  authorizedType?.value === "others" ? "auto" : "top"
                }
              />
            </CardBody>
          </Card>
          {authorizedType?.value === "others" && (
            <div className="with_100 mt-3">
              {unifiedOthers?.map((list, i) => (
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="6" lg="4" className="mb-4">
                        <p className="control_labels control_labels_star">
                          {currentLang.fp_lang_71}
                        </p>
                        <Input
                          type="text"
                          placeholder="First Name"
                          value={list?.first_name}
                          onChange={(e) =>
                            handleOnDataOthers(e, i, "first_name")
                          }
                          name="first_name_other"
                        />
                      </Col>
                      <Col md="6" lg="4" className="mb-4">
                        <p className="control_labels">
                          {currentLang.fp_lang_72}
                        </p>
                        <Input
                          type="text"
                          placeholder="Middle Name"
                          value={list?.middle_name}
                          onChange={(e) =>
                            handleOnDataOthers(e, i, "middle_name")
                          }
                        />
                      </Col>
                      <Col md="6" lg="4" className="mb-4">
                        <p className="control_labels control_labels_star">
                          {currentLang.fp_lang_73}
                        </p>
                        <Input
                          type="text"
                          placeholder="Last Name"
                          value={list?.last_name}
                          onChange={(e) =>
                            handleOnDataOthers(e, i, "last_name")
                          }
                          name="last_name_other"
                        />
                      </Col>
                      <Col md="6" lg="4" className="mb-4">
                        <p className="control_labels control_labels_star">
                          {currentLang.fp_lang_74}
                        </p>
                        <Input
                          type="date"
                          value={list?.dob}
                          onChange={(e) => handleOnDataOthers(e, i, "dob")}
                          name="dob_other"
                        />
                      </Col>
                      <Col md="6" lg="4" className="mb-4">
                        <p className="control_labels control_labels_star">
                          {currentLang.fp_lang_75}
                        </p>
                        <Select
                          styles={SelectBackground}
                          options={selectedCurrency}
                          onChange={(e) =>
                            handlechangeCurrencyOther(e, i, "nationality")
                          }
                          name="nationality_other"
                          value={{
                            label: list?.nationality,
                            value: list?.nationality,
                          }}
                        />
                      </Col>
                      <Col md="6" lg="4" className="mb-4">
                        <p className="control_labels control_labels_star">
                          {currentLang.fp_lang_76}
                        </p>
                        <Input
                          type="text"
                          placeholder="Passport"
                          value={list?.passport_number}
                          onChange={(e) =>
                            handleOnDataOthers(e, i, "passport_number")
                          }
                          name="passport_number_other"
                        />
                      </Col>
                      <Col md="6" lg="4" className="mb-4">
                        <p className="control_labels control_labels_star">
                          {currentLang.fp_lang_77}
                        </p>
                        <Input
                          type="date"
                          placeholder=""
                          value={list?.passport_exp_date}
                          onChange={(e) =>
                            handleOnDataOthers(e, i, "passport_exp_date")
                          }
                          name="passport_exp_date_other"
                        />
                      </Col>
                      <Col md="6" lg="4" className="mb-4">
                        <FormGroup>
                          <p className="control_labels control_labels_star">
                            {currentLang.fp_lang_78}
                          </p>
                          <div style={{ position: "relative" }}>
                            <label
                              htmlFor="pass_doc"
                              className="upload_img_banner"
                              name="passport_doc_other"
                            >
                              Upload Passport Copy
                            </label>
                            <Input
                              type="file"
                              name="file"
                              id="pass_doc"
                              className="d-none"
                              onChange={(e) =>
                                handleFileChangeOthers(e, i, "passport_doc")
                              }
                              ref={fileInputRefPassport}
                            />
                          </div>
                          {!list?.passport_doc ? (
                            <FormText
                              color="muted"
                              className="font_choose choose_file"
                            >
                              {currentLang.fp_lang_145}
                            </FormText>
                          ) : (
                            <FormText color="blue">
                              <a
                                href={list?.passport_doc}
                                target="_blank"
                                alt="Preview"
                                className="img_down"
                              >
                                {currentLang.fp_lang_52}
                              </a>
                            </FormText>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <div>
                      <hr></hr>
                    </div>
                    <Row>
                      <Col md="6" lg="4" className="mb-4">
                        <p className="control_labels control_labels_star">
                          {currentLang.fp_lang_79}
                        </p>
                        <Input
                          type="text"
                          placeholder="Emirates Number"
                          value={list?.emirates_number}
                          onChange={(e) =>
                            handleOnDataOthers(e, i, "emirates_number")
                          }
                          name="emirates_number_other"
                        />
                      </Col>
                      <Col md="6" lg="4" className="mb-4">
                        <p className="control_labels control_labels_star">
                          {currentLang.fp_lang_80}
                        </p>
                        <Input
                          type="date"
                          placeholder="emiratesExpiryDate"
                          value={list?.emirates_exp_date}
                          onChange={(e) =>
                            handleOnDataOthers(e, i, "emirates_exp_date")
                          }
                          name="emirates_exp_date_other"
                        />
                      </Col>
                      <Col md="6" lg="4" className="mb-4">
                        <FormGroup>
                          <p className="control_labels control_labels_star">
                            {currentLang.fp_lang_81}
                          </p>
                          <div style={{ position: "relative" }}>
                            <label
                              htmlFor="emirate_front"
                              className="upload_img_banner"
                              name="emirates_id_front_doc_other"
                            >
                              Upload Emirates ID (Front Side)
                            </label>
                            <Input
                              type="file"
                              name="file"
                              id="emirate_front"
                              className="d-none"
                              ref={fileInputRefEmiratesFront}
                              onChange={(e) =>
                                handleFileChangeOthers(
                                  e,
                                  i,
                                  "emirates_id_front_doc"
                                )
                              }
                            />
                          </div>
                          {!list?.emirates_id_front_doc ? (
                            <FormText
                              color="muted"
                              className="font_choose choose_file"
                            >
                              {currentLang.fp_lang_145}
                            </FormText>
                          ) : (
                            <FormText color="blue">
                              <a
                                href={list?.emirates_id_front_doc}
                                target="_blank"
                                alt="Preview"
                                className="img_down"
                              >
                                {currentLang.fp_lang_52}{" "}
                              </a>
                            </FormText>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="6" lg="4" className="mb-4">
                        <FormGroup>
                          <p className="control_labels control_labels_star">
                            {currentLang.fp_lang_82}
                          </p>
                          <div style={{ position: "relative" }}>
                            <label
                              htmlFor="emirate_back"
                              className="upload_img_banner"
                              name="emirates_id_back_doc_other"
                            >
                              Upload Emirates ID (Front Side)
                            </label>
                            <Input
                              type="file"
                              name="file"
                              id="emirate_back"
                              className="d-none"
                              onChange={(e) =>
                                handleFileChangeOthers(
                                  e,
                                  i,
                                  "emirates_id_back_doc"
                                )
                              }
                              ref={fileInputRefEmiratesBack}
                            />
                          </div>
                          {!list?.emirates_id_back_doc ? (
                            <FormText
                              color="muted"
                              className="font_choose choose_file"
                            >
                              {currentLang.fp_lang_145}
                            </FormText>
                          ) : (
                            <FormText color="blue">
                              <a
                                href={list?.emirates_id_back_doc}
                                target="_blank"
                                alt="Preview"
                                className="img_down"
                              >
                                {currentLang.fp_lang_52}
                              </a>
                            </FormText>
                          )}
                        </FormGroup>
                      </Col>
                      {list?.nationality &&
                        !gccCountries.includes(list?.nationality) && (
                          <>
                            <Col md="6" lg="4" className="mb-4">
                              <FormGroup>
                                <p className="control_labels control_labels_star">
                                  {currentLang.fp_lang_83}
                                </p>
                                <div style={{ position: "relative" }}>
                                  <label
                                    htmlFor="visa"
                                    className="upload_img_banner"
                                    name="visa_id_doc_other"
                                  >
                                    Upload Visa Copy
                                  </label>
                                  <Input
                                    type="file"
                                    name="file"
                                    id="visa"
                                    onChange={(e) =>
                                      handleFileChangeOthers(
                                        e,
                                        i,
                                        "visa_id_doc"
                                      )
                                    }
                                    className="d-none"
                                    ref={fileInputRefEmiratesBack}
                                  />
                                </div>
                                <FormText
                                  color={list?.visa_id_doc ? "blue" : "muted"}
                                  className="font_choose choose_file"
                                >
                                  {list?.visa_id_doc ? (
                                    <a
                                      href={list.visa_id_doc}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      alt="Preview"
                                      className="img_down"
                                    >
                                      {currentLang.fp_lang_52}
                                    </a>
                                  ) : (
                                    currentLang.fp_lang_145
                                  )}
                                </FormText>
                              </FormGroup>
                            </Col>
                            <Col md="6" lg="4" className="mb-4">
                              <p className="control_labels">
                                {currentLang.fp_lang_84}
                              </p>
                              <Input
                                type="date"
                                placeholder="emiratesExpiryDate"
                                value={list?.visa_exp_date}
                                onChange={(e) =>
                                  handleOnDataOthers(e, i, "visa_exp_date")
                                }
                                name="visa_exp_date_other"
                              />
                            </Col>
                          </>
                        )}
                    </Row>
                  </CardBody>
                </Card>
              ))}
            </div>
          )}
          {/* <Button
            onClick={(e) => {
              handlesubmit();
              disableSubmit(e);
            }}  
            className="submit_buttom submit_process"
          >
            {spinLoader ? (
              <i class="fa fa-spinner fa-pulse" aria-hidden="true"></i>
            ) : (
              currentLang.fp_lang_92
            )}
          </Button> */}
            {!spinLoader ? (
              <Button
                className="submit_buttom submit_process"
                onClick={(e) => {
                  handlesubmit();
                  disableSubmit(e);
                }}
              >
                {currentLang.fp_lang_92}
              </Button>
            ) : (
              <Button className="submit_buttom submit_process">
                <i class="fa fa-spinner fa-pulse" aria-hidden="true"></i>
              </Button>
            )}
        </div>
      </div>
      <Modal
        ariaHideApp={false}
        isOpen={isDownloadOpenconfirm}
        onRequestClose={handleCancelpaymentlink}
        style={customStyles}
        // className="  modal_alt"
      >
        <Row className="modal-title-container justify-content-between">
          <Col>
            <p className="modal-title no_wrap ">
              Authorized signatory confirmation
            </p>
          </Col>
          <Col className="text-end">
            <div onClick={handleCancelpaymentlink}>
              <img
                className="cursor_pointer cross_cancel"
                src={require("../../../../assets/images/cross.svg").default}
              />
            </div>
          </Col>
        </Row>

        <p style={{ margin: "20px 0 20px 0" }}>
          <p className="bulk_notify">Do want to change authorized signatory?</p>
        </p>

        <Row
          className="d-flex justify-content-center"
          style={{ padding: "0 0 12px 0" }}
        >
          <Col className="float-right">
            <Button
              type="primary"
              className="report float-right"
              style={{ float: "right" }}
              onClick={handleonDetelechange}
            >
              OK
            </Button>
          </Col>
          <Col className="">
            <Button
              type="primary"
              className="report gray_color"
              onClick={handleCancelpaymentlink}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};
