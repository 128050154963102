import React from "react";
import ippoerror from "../assets/images/404_error.jpg";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
const Error = () => {
  return (
    <div id="ippopay_loading">
      <div className="color-white ippopay_loading_img errorPage">
        <img src={ippoerror} />
        <div className="float_left">
          <h1 className="float_left">
            <b className="light_black">Oops,page not found</b>
          </h1>
          <div className="float_left margin_14">
            <Link to={"/" || "/login"} className="color_white error_button">
              BACK TO HOMEPAGE
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Error;
