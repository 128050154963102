export const enLang = {
  fp_lang_1: "Join Foloosi Business!",
  fp_lang_2:
    "Transform & Grow your Business with UAE's Largest Online payment gateway.",
  fp_lang_3: "First Name",
  fp_lang_4: "Last Name",
  fp_lang_5: "Business Name",
  fp_lang_6: "Website Url",
  fp_lang_7: "Business Email",
  fp_lang_8: "Secondary Email",
  fp_lang_9: "Mobile Number",
  fp_lang_10: "Secondary Mobile Number",
  fp_lang_11: "Country",
  fp_lang_12: "City",
  fp_lang_13: "I agree to the",
  fp_lang_14: "Terms & Conditions",
  fp_lang_15: "Sign Up",
  fp_lang_16: "Create Your Account",
  fp_lang_17: "Enter Your Details to Create an Account  ", 
  fp_lang_18: "Login",
  fp_lang_19: " Merchant Login",
  fp_lang_20: "Email",
  fp_lang_21: "Password",
  fp_lang_22: "Forgot Password",
  fp_lang_23: "Sign in to your account",
  fp_lang_24: "Don't have an account?",
  fp_lang_25: "Register Here",
  fp_lang_26: "Back to Login",
  fp_lang_27: "Activate Your Account",
  fp_lang_28: "Please complete the below ",
  fp_lang_29: "steps to activate your account",
  fp_lang_30: "Your Business",
  fp_lang_31:
    "We collect this information to comply with requirements from regulators and financial partners.",
  fp_lang_32: "Service",
  fp_lang_33: "Transaction Information",
  fp_lang_34: "Bank Details",
  fp_lang_35: "ACTIVATE YOUR ACCOUNT",
  // Your Business
  fp_lang_36: "Your Business",
  fp_lang_37: "Tell us about your business details.",
  fp_lang_38: "Business Details",
  fp_lang_39: "Legal Name",
  fp_lang_40: "Operating Name",
  fp_lang_41: "Industry",
  fp_lang_42: "Product / Service Description",
  fp_lang_43: "Years in business",
  fp_lang_44: "Website to link with Payment Gateway / Social Media URL",

  fp_lang_45: "Business Information",
  fp_lang_46: "Type of Business",
  fp_lang_47: "Trade License Details",
  fp_lang_48: "Trade License",
  fp_lang_49: "Trade License Number",
  fp_lang_50: "Trade License Issue date",
  fp_lang_51: "Trade License Expiry date",
  fp_lang_52: "click here to view uploaded file",
  fp_lang_53: "Enter Type of Business",

  fp_lang_54: "Registered business address",
  fp_lang_55: "Country",
  fp_lang_56: "Address Line 1",
  fp_lang_57: "City",
  fp_lang_58: "Address Line 2",
  fp_lang_59: "PO Box",
  fp_lang_60: "Address Line 3",

  fp_lang_61: "Type of office occupied",
  fp_lang_62: "Rented",
  fp_lang_63: "Owned",
  fp_lang_64: "Upload lease agreement / Title deed",

  fp_lang_65: "Business Owners",
  fp_lang_66:
    "Due to regulatory guidelines, we're required to collect information on anyone who has significant ownership of your business.",
  fp_lang_67: "Business Details",
  fp_lang_68:
    "Upload the share certificate / Articles of Association / Memorandum of association / Certificate of incorporation",

  fp_lang_69: "Authorized Signatory",
  fp_lang_70: "Choose any one from the list",

  fp_lang_71: "First Name",
  fp_lang_72: "Middle Name",
  fp_lang_73: "Last Name",
  fp_lang_74: "Date of birth",
  fp_lang_75: "Nationality",
  fp_lang_76: "Passport Number",
  fp_lang_77: "Passport expiry date",
  fp_lang_78: "Upload Passport Copy",
  fp_lang_79: " Emirates Number",
  fp_lang_80: "Emirates expiry date",
  fp_lang_81: "Upload Emirates Id(Front side)",
  fp_lang_82: "Upload Emirates Id(Back side)",
  fp_lang_83: " Upload Visa (non-locals)",
  fp_lang_84: "Visa Expiry date",
  fp_lang_85: "Upload Emirates Id(Back side)",
  fp_lang_86: "% of share in the company",
  fp_lang_87: "Service",
  fp_lang_88:
    "Please indicate the service you would like to use from the Foloosi",
  fp_lang_89: "Select Service",
  fp_lang_90: "Select All",
  fp_lang_91: "Unselect All",
  fp_lang_92: "Submit & Continue",

  fp_lang_93: "Payment Links",
  fp_lang_94: "API/SDK integration",
  fp_lang_95: "Subscription",
  fp_lang_96: "Product Links",
  fp_lang_97: "Foloosi Store",
  fp_lang_98: "Marketplace",
  fp_lang_99: "Foloosi Book",
  fp_lang_100: "Invoice",
  fp_lang_101: "QR Code",
  fp_lang_102: "Tap on Phone",

  fp_lang_103:
    "( Generate and share payment links across common platforms to your customers. )",
  fp_lang_104:
    "( Receive payments directly from your Business Website and Mobile Applications. )",
  fp_lang_105:
    "( Activate a subscription based plan for receiving recurring payments. )",
  fp_lang_106:
    "( Create a link to sell a specific product over social media platforms. )",
  fp_lang_107:
    "( Build your online store using our User-friendly Software for Stores. )",
  fp_lang_108:
    "( Sell Online Without a Website, You can sell your products on our Ready-to-Use Marketplace. )",
  fp_lang_109:
    "( Add payment entries, channelize records and view business fund flows in an instant. )",
  fp_lang_110:
    "( Get a commercial document for free that itemizes and records a transaction between a buyer and a seller. )",
  fp_lang_111:
    "( Enables customers to make online payments to merchants by scanning the QR Code. )",
  fp_lang_112:
    "( Accept payments from any contactless card or mobile wallet right from their NFC-enabled device. )",

  fp_lang_113: "Transaction Information",
  fp_lang_114:
    "We collect these details to configure your account with almost accuracy",
  fp_lang_115: "Transaction Information",
  fp_lang_116: "Total expected annual volume",
  fp_lang_117: "Average Single Transaction Value",
  fp_lang_118: "Base Currency",
  fp_lang_119: "Regions from which you would like to accept card transactions",

  fp_lang_120: "Bank Details",
  fp_lang_121:
    "Provide your company bank account details for the transfer of funds from Foloosi to your bank account.",
  fp_lang_122: "Bank Details",
  fp_lang_123: "Bank",
  fp_lang_124: "Account Title",
  fp_lang_125: "Account Number",
  fp_lang_126: "IBAN",
  fp_lang_127: "Swift Code",
  fp_lang_128: "Branch Location",
  fp_lang_129:
    "Upload a copy of the bank statement with the IBAN number for verification.",
  fp_lang_130:
    "Kindly read Merchant Agreement and Agree the Terms and Conditions",

  fp_lang_131: "Thank you for submitting ",
  fp_lang_132: " required details",
  fp_lang_133: "Please cross check all submitted documents.",
  fp_lang_134:
    "Modifications, if any, cannot be made once the documents are submitted for verification.",
  fp_lang_135: "Proceed for verification",
  fp_lang_136:
    "We shall confirm the status of Go-Live within 48 hours of verification",

  fp_lang_137: "Step 1 is pending, click to continue",
  fp_lang_138: "Step 2 is pending, click to continue",
  fp_lang_139: "Step 3 is pending, click to continue",
  fp_lang_140: "Step 4 is pending, click to continue",
  fp_lang_141:
    "All the required documents have been submitted. Proceed for verification",

  fp_lang_142: "Logout",
  fp_lang_143: "Privacy Policy",
  fp_lang_144: "Terms and condition",
  fp_lang_145: "Choose a file to upload.",

  // footer
  fp_lang_146:
    "Foloosi is a technology company that builds economic infrastructure online to enable businesses for a simple, convenient and advanced digital transformation and empower to innovate in the market and unlock business growth.",
  fp_lang_147:
    "Foloosi is headquartered in the Dubai International Financial Centre (the DIFC) in the United Arab Emirates (the UAE) and with an online presence on the Web, Android (Google Play) and iOS (Apple) app stores.",
  fp_lang_148: "FIND US ONLINE",

  fp_lang_149: "About",
  fp_lang_150: "Company",
  fp_lang_151: "Contact Us",
  fp_lang_152: "Documentation",
  fp_lang_153: "Blog",

  fp_lang_154: "Help & Supports",
  fp_lang_155: "Terms & Conditions",
  fp_lang_156: "Privacy Policy",
  fp_lang_157: "Cancellation & Refunds",
  fp_lang_158: "Delivery Services Terms",
  fp_lang_159: "Compliance",
  fp_lang_160: "General Merchant Checklist",

  fp_lang_161: "Marketplace",
  fp_lang_162: "QR Code",
  fp_lang_163: "Payment Links",
  fp_lang_164: "Product Links",
  fp_lang_165: "Subscriptions",
  fp_lang_166: "International Payments",
  fp_lang_167: "Solutions",
  fp_lang_168: "Point of Sale",
  fp_lang_169: "Retail",
  fp_lang_171: "eCommerce",
  fp_lang_172: "Marketplace",
  fp_lang_173: "Online Store",

  fp_lang_174: "SiteMap",
  fp_lang_175: "Terms & Conditions",
  fp_lang_176: "Media",
  fp_lang_177: "Checkout Demo",
  fp_lang_178: "Foloosi 2024 All Rights Reserved",
  fp_lang_179:"We seek information about the services you offer. This helps us understand your business model, allowing us to better tailor our services and ensure that you receive the most relevant support.",
  fp_lang_180:"To uphold regulatory requirements and maintain financial integrity, we request transaction-related details. This information assists us in monitoring and securing transactions, providing a secure environment for your business and ensuring adherence to regulatory standards.",
  fp_lang_181:"Providing your bank details facilitating secure transactions and complying with financial regulations. This information is securely processed for the settlement based on the frequency",
  fp_lang_182:"Add New",
  fp_lang_183:"Change Password",
  fp_lang_184:"Confirm Password",
  fp_lang_185:"OTP Verify",
  fp_lang_186:"OTP",
  fp_lang_187:"Resend OTP",
  fp_lang_188:"Change Email"
};

export const arLang = {
  fp_lang_1: "انضم الى تجارة فلوسي!",
  fp_lang_2:
    "يمكن لنظام Foloosi POS أن يساعد الشركات بكفاءة معالجة المعاملات وإدارة بيانات المبيعات والتحسين تجارب العملاء في مواقع البيع بالتجزئة الفعلية.",
  fp_lang_3: "الاسم الاول",
  fp_lang_4: "الاسم الاخير",
  fp_lang_5: "الاسم التجاري",
  fp_lang_6: "رابط الموقع الالكتروني Url",
  fp_lang_7: "البريد الالكتروني للمتجر",
  fp_lang_8: "بريد الكتروني ثانوي",
  fp_lang_9: "رقم الهاتف",
  fp_lang_10: "رقم هاتف ثانوي",
  fp_lang_11: "الدولة",
  fp_lang_12: "المدينة",
  fp_lang_13: "وافق على",
  fp_lang_14: "الشروط والأحكام .",
  fp_lang_15: "سجل",
  fp_lang_16: "أنشئ حسابك",
  fp_lang_17: "أدخل التفاصيل الخاصة بك لإنشاء حساب",
  fp_lang_18: "تسجيل الدخول",
  fp_lang_19: "تسجيل دخول التاجر",
  fp_lang_20: "بريد إلكتروني",
  fp_lang_21: "كلمة المرور",
  fp_lang_22: "هل نسيت كلمة السر",
  fp_lang_23: "تسجيل الدخول إلى حسابك",
  fp_lang_24: "ليس لديك حساب؟",
  fp_lang_25: "سجل هنا",
  fp_lang_26: "العودة إلى تسجيل الدخول",
  fp_lang_27: "قم بتنشيط حسابك",
  fp_lang_28: "يرجى إكمال ما يلي",
  fp_lang_29: "خطوات تفعيل حسابك",
  fp_lang_30: "عملك التجاري",
  fp_lang_31:
    "نقوم بجمع هذه المعلومات للامتثال لمتطلبات المنظمين والشركاء الماليين.",
  fp_lang_32: " الخدمة",
  fp_lang_33: " معلومات المعاملة",
  fp_lang_34: " التفاصيل البنكية",
  fp_lang_35: "قم بتنشيط حسابك",

  fp_lang_36: "قم بتنشيط حسابك",
  fp_lang_37: "أخبرنا عن تفاصيل عملك.",
  fp_lang_38: "تفاصيل العمل التجاري ",
  fp_lang_39: "الاسم القانوني",
  fp_lang_40: "الاسم التشغيلي",
  fp_lang_41: "الصناعة",
  fp_lang_42: "وصف المنتج / الخدمة",
  fp_lang_43: "سنوات العمل",
  fp_lang_44:
    "موقع ويب للربط ببوابة الدفع / عنوان URL لوسائل التواصل الاجتماعي",

  fp_lang_45: "معلومات تجارية",
  fp_lang_46: "نوع العمل",
  fp_lang_47: "تفاصيل الرخصة التجارية",
  fp_lang_48: "الرخصة التجارية",
  fp_lang_49: "رقم الرخصة التجارية ",
  fp_lang_50: "تاريخ إصدار الرخصة التجارية",
  fp_lang_51: "تاريخ انتهاء صلاحية الرخصة التجارية",
  fp_lang_52: "انقر هنا لعرض الملف الذي تم تحميله",
  fp_lang_53: "أدخل نوع العمل",

  fp_lang_54: "عنوان العمل المسجل",
  fp_lang_55: "الدولة",
  fp_lang_56: "العنوان 1",
  fp_lang_57: "المدينة",
  fp_lang_58: "العنوان 2 ",
  fp_lang_59: "صندوق البريد",
  fp_lang_60: "العنوان 3",

  fp_lang_61: "نوع المكتب المشغول",
  fp_lang_62: "مستأجر",
  fp_lang_63: "ملك",
  fp_lang_64: "تحميل عقد الإيجار / سند الملكية ",

  fp_lang_65: "أصحاب الأعمال",
  fp_lang_66:
    "وفقًا للإرشادات التنظيمية ، نحن مطالبون بجمع معلومات عن أي شخص لديه ملكية كبيرة لنشاطه التجاري.",
  fp_lang_67: "تفاصيل العمل",
  fp_lang_68:
    "قم بتحميل شهادة المشاركة / النظام الأساسي / عقد التأسيس / شهادة التأسيس",

  fp_lang_69: "المفوض بالتوقيع",
  fp_lang_70: "اختر أي واحد من القائمة*",

  fp_lang_71: "الاسم الأول",
  fp_lang_72: "الاسم الأوسط",
  fp_lang_73: "اسم العائلة",
  fp_lang_74: "تاريخ الميلاد",
  fp_lang_75: "جنسية",
  fp_lang_76: "رقم جواز السفر",
  fp_lang_77: "وقت نفاذ صلاحيه جواز السفر",
  fp_lang_78: "تحميل نسخة جواز السفر",
  fp_lang_79: " رقم الإمارات",
  fp_lang_80: "الإمارات تاريخ انتهاء الصلاحية",
  fp_lang_81: "تحميل الهوية الإماراتية (الجانب الأمامي)",
  fp_lang_82: "تحميل الهوية الإماراتية (الجانب الخلفي)",
  fp_lang_83: "رفع التأشيرة (لغير المواطنين)",
  fp_lang_84: "تاريخ انتهاء التأشيرة",
  fp_lang_85: "تحميل الهوية الإماراتية (الجانب الخلفي)",
  fp_lang_86: "نسبة المساهمة في الشركة",

  fp_lang_87: "الخدمة",
  fp_lang_88: "يرجى الإشارة إلى الخدمة التي ترغب في استخدامها من فلوسي",
  fp_lang_89: "حدد الخدمةe",
  fp_lang_90: "ر الكل",
  fp_lang_91: " إلغاء تحديد الكل",
  fp_lang_92: "إرسال ومتابعة",

  fp_lang_93: "روابط الدفع",
  fp_lang_94: "دمج API/SDK",
  fp_lang_95: "اشتراك",
  fp_lang_96: "روابط المنتج",
  fp_lang_97: "متجر فلوسي",
  fp_lang_98: "المتجر",
  fp_lang_99: "كتاب فلوسي",
  fp_lang_100: "فواتير",
  fp_lang_101: "كود QR",
  fp_lang_102: "اضغط على الهاتف",

  fp_lang_103:
    "(إنشاء روابط الدفع ومشاركتها عبر الأنظمة الأساسية المشتركة لعملائك.)",
  fp_lang_104:
    "(تلقي المدفوعات مباشرة من موقع الويب الخاص بالعمل وتطبيقات الهاتف المحمول.)",
  fp_lang_105: "(قم بتنشيط خطة قائمة على الاشتراك لتلقي المدفوعات المتكررة.)",
  fp_lang_106: "(قم بإنشاء رابط لبيع منتج معين عبر منصات التواصل الاجتماعي. )",
  fp_lang_107:
    "(قم ببناء متجرك عبر الإنترنت باستخدام برنامجنا سهل الاستخدام للمتاجر.)",
  fp_lang_108:
    "(البيع عبر الإنترنت بدون موقع ويب ، يمكنك بيع منتجاتك في سوقنا الجاهز للاستخدام.)",
  fp_lang_109:
    "(أضف إدخالات الدفع ، وقم بتوجيه السجلات وعرض تدفقات الأموال التجارية في لحظة.)",
  fp_lang_110:
    "(احصل على مستند تجاري مجانًا يفصل ويسجل معاملة بين المشتري والبائع.)",
  fp_lang_111:
    "(يمكّن العملاء من إجراء مدفوعات عبر الإنترنت للتجار عن طريق مسح رمز الاستجابة السريعة.)",
  fp_lang_112:
    "(اقبل المدفوعات من أي بطاقة بدون لمس أو من محفظة الهاتف المحمول مباشرة من أجهزتهم التي تدعم تقنية NFC.)",
  fp_lang_113: "معلومات المعاملة",
  fp_lang_114: "نقوم بجمع هذه التفاصيل لتهيئة حسابك بدقة تقريبًا",
  fp_lang_115: "معلومات المعاملة",
  fp_lang_116: "إجمالي الدخل السنوي المتوقع",
  fp_lang_117: "متوسط قيمة الصفقة الواحدة",
  fp_lang_118: "العملة الأساسية",
  fp_lang_119: "المناطق التي ترغب في قبول معاملات البطاقة منها",

  fp_lang_120: "تفاصيل بنكية",
  fp_lang_121:
    "قدم تفاصيل الحساب المصرفي لشركتك لتحويل الأموال من فلوسي إلى حسابك المصرفي.",
  fp_lang_122: "تفاصيل بنكية",
  fp_lang_123: "البنك",
  fp_lang_124: "عنوان الحساب ",
  fp_lang_125: "رقم الحساب",
  fp_lang_126: "رقم الحساب المصرفي IBAN",
  fp_lang_127: "رمز سويفت",
  fp_lang_128: "موقع الفرع",
  fp_lang_129: "قم بتحميل نسخة من كشف الحساب المصرفي برقم IBAN للتحقق.",
  fp_lang_130: " يرجى قراءة اتفاقية التاجر والموافقة على الشروط والأحكام",

  fp_lang_131: "شكرا لك على التقديم ",
  fp_lang_132: "  التفاصيل المطلوبة",
  fp_lang_133: "يرجى التحقق من جميع المستندات المقدمة.",
  fp_lang_134: "التعديلات إن وجدت ، لا يمكن إجراؤها بمجرد إرسالها للتحقق.",
  fp_lang_135: "المضي قدما للتحقق",
  fp_lang_136: "سنؤكد حالة Go-Live في غضون 48 ساعة من التحقق",

  fp_lang_137: "الخطوة 1 معلقة، انقر للمتابعةe",
  fp_lang_138: "الخطوة 2 معلقة، انقر للمتابعة",
  fp_lang_139: "الخطوة 3 معلقة، انقر للمتابعة",
  fp_lang_140: "الخطوة 4 معلقة، انقر للمتابعة",
  fp_lang_141: "تم تقديم كافة المستندات المطلوبة. المضي قدما للتحقق",
  fp_lang_142: "تسجيل خروج",
  fp_lang_143: "سياسة الخصوصية",
  fp_lang_144: "أحكام وشروط",
  fp_lang_145: "اختر ملفًا لتحميله.",
  fp_lang_146:
    ".فلوسي هي شركة تقنية تقوم ببناء بنية تحتية اقتصادية عبر الإنترنت لتمكين الشركات من إجراء تحول رقمي بسيط ومريح ومتقدم وتمكين الابتكار في السوق وإطلاق العنان لنمو الأعمال",
  fp_lang_147:
    ".فلوسي هي شركة تقنية تقوم ببناء بنية تحتية اقتصادية عبر الإنترنت لتمكين الشركات من إجراء تحول رقمي بسيط ومريح ومتقدم وتمكين الابتكار في السوق وإطلاق العنان لنمو الأعمال",
  fp_lang_148: "ابحث عنا في النت",
  fp_lang_149: "عن",
  fp_lang_150: "شركة",
  fp_lang_151: "اتصل بنا",
  fp_lang_152: "توثيق",
  fp_lang_153: "مدونة",
  //
  fp_lang_154: "المساعدة والدعم",
  fp_lang_155: "البنود و الظروف",
  fp_lang_156: "سياسة الخصوصية",
  fp_lang_157: "الإلغاء واسترداد الأموال",
  fp_lang_158: "شروط خدمات التوصيل",
  fp_lang_159: "امتثال",
  fp_lang_160: "قائمة مراجعة التاجر العامة",

  fp_lang_161: "عن",
  fp_lang_162: "رمز الاستجابة السريعة",
  fp_lang_163: "روابط الدفع",
  fp_lang_164: "روابط المنتج",
  fp_lang_165: "الاشتراكات",
  fp_lang_166: "المدفوعات الدولية",
  fp_lang_167: "حلول",
  fp_lang_168: "نقطة البيع",
  fp_lang_169: "بيع بالتجزئة",
  fp_lang_171: "التجارة الإلكترونية",
  fp_lang_172: "المتجر",
  fp_lang_173: "متجر على الانترنت",

  fp_lang_174: "خريطة الموقع",
  fp_lang_175: "البنود و الظروف",
  fp_lang_176: "وسائط",
  fp_lang_177: "الخروج التجريبي",
  fp_lang_178: "فولوسي 2024 جميع الحقوق محفوظة",
  fp_lang_179:"نحن نسعى للحصول على معلومات حول الخدمات التي تقدمها. وهذا يساعدنا على فهم نموذج عملك، مما يسمح لنا بتخصيص خدماتنا بشكل أفضل والتأكد من حصولك على الدعم الأكثر صلة.",
  fp_lang_180:"لدعم المتطلبات من المؤسسات التنظيمية والحفاظ على النزاهة المالية، نطلب التفاصيل المتعلقة بالمعاملة. تساعدنا هذه المعلومات في مراقبة المعاملات وتأمينها، وتوفير بيئة آمنة لأعمالك وضمان الالتزام بالمعايير التنظيمية.",
  fp_lang_181:"توفير التفاصيل المصرفية الخاصة بك لتسهيل المعاملات الآمنة والامتثال للوائح المالية. تتم معالجة هذه المعلومات بشكل آمن للتسوية بناءً على مدة التحويل.",
fp_lang_182:"اضف جديد",
fp_lang_183:"تغيير كلمة المرور",
fp_lang_184:"تأكيد كلمة المرور",
fp_lang_185:"تأكيد كلمة المرور لمرة واحدة",
fp_lang_186:"OTP",
fp_lang_187:"إعادة إرسال كلمة المرور لمرة واحدة",
fp_lang_188:"تغيير البريد الالكتروني"


};
  
