import React,{useState, useEffect,useRef} from "react";
import {
    Row,
    Col,
    CardBody,
    Card,
    Input,
    Button,
    FormFeedback,
  } from "reactstrap";
  import { ToastContainer, toast } from "react-toastify";
  import "react-toastify/dist/ReactToastify.css";
  import PhoneInput from "react-phone-input-2";
  import "react-phone-input-2/lib/style.css";
  import Select from "react-select";
  import Recaptcha from "react-google-invisible-recaptcha";
  import axios from "axios";
  import ApiGateWay from "../../Utils/ApiCall";
  import { Link, useNavigate } from "react-router-dom";
  import {
    disableSubmit,
    getCookie,
    removeToast,
    setCookie,
    showToast,
    toastShow,
  } from "../../Utils/index";
  import { enLang, arLang } from "../../Constants/en_lang";
  import Footer from "../Footer";
  import { message } from "antd";
  import { useSelector, useDispatch } from "react-redux";
  import { ActionTypes } from "../../Constants/ActionTypes"


const Otpresend = () =>{
    document.title = "Email";
    const {signup,login} =useSelector((state)=> state);
    const dispatch = useDispatch();
  
    const updateState = (actionType, value) => () => {
        dispatch({ type: actionType, payload: value });
        return Promise.resolve();
    }
  
    useEffect(() => {
      dispatch(updateState(ActionTypes.SIGNUP, { businessemail:"" }));
  }, []);

    const navigate = useNavigate();
    const recaptchaRef = useRef(null);
    const [signUpLoader, setSignupLoader] = useState(false);
    const [addForm, setaddForm] = useState({ agree_check: false });
    const [errors, setErrors] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);

    const currentLang = getCookie("foloosi_lang") === "ar" ? arLang : enLang;
    const changeLang = (id) => {
        setCookie(id, "foloosi_lang");
        window.location.reload();
      };
      const validateEmail = (email, fieldName) => {
        if (!email) {
          return `Please enter ${fieldName}`;
        }
        if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)) {
          return `Please enter a valid ${fieldName}`;
        }
        return null;
      };
      const handleOnAdddata = (fieldName, value) => {
        setaddForm({
          ...addForm,
          [fieldName]: value,
        });
        console.log(addForm);
      };
      const nutrinoEmailverify = (email) => {
        const emailID = email.trim();
        return axios
          .get(
            `https://api.foloosi.com/merchant/bulk-link/phone-verify?q={"email":"${emailID}","type":"email"}`
          )
          .then((response) => {
            return response?.data?.data?.is_email_verified;
          })
          .catch((error) => {
            console.error(error);
            return false;
          });
      };
      const nutrinoEmailExit = async (email) => {
        return axios
          .get(`https://aflsuser.foloosi.com/v2/check-email-exist/${email}`)
          .then((response) => {
            return response?.data?.data?.user;
          })
          .catch((error) => {
            console.error(error);
            return false;
          });
      };
      const signInProcess = async () => {
        setIsDisabled(true);
        setSignupLoader(true);
        removeToast();
        recaptchaRef.current.execute();
        const errors = [

          {
            key: "business_email",
            message: validateEmail(addForm.business_email, "primary email"),
          },
          
        ];
        console.log(errors, "errorss");
        const errorMessage = errors.find((error) => error?.message);
        console.log(errorMessage, "errorMessage");
        if (errorMessage) {
          setSignupLoader(false);
          setIsDisabled(false);
          toastShow(errorMessage?.message, errorMessage?.key);
          setSignupLoader(false);
          return;
        }
        if (addForm?.business_email) {
          const isEmailExit = await nutrinoEmailExit(addForm.business_email);
          if (!isEmailExit) {
            setSignupLoader(false);
            setIsDisabled(false);
            toastShow("Email ID is already registered", "business_email");
    
            return;
          }
        }
    
        const isEmailValid = await nutrinoEmailverify(addForm.business_email);
        if (addForm?.business_email) {
          if (!isEmailValid) {
            setSignupLoader(false);
            setIsDisabled(false);
            toastShow("Please enter valid primary email id", "business_email");
            
            return;
          }
        }
    
        if (!errorMessage) {
          let data = {
            email: addForm.business_email.toLowerCase(),
            user_no: signup?.merchant_id ===""? login?.merchant_id:signup?.merchant_id 
          };
          console.log(data, "data");
          // return false;
          setSignupLoader(true);
          dispatch(updateState(ActionTypes.SIGNUP, { businessemail:addForm.business_email }));
          ApiGateWay.post("v2/merchant/business-signup", data, (response) => {
            if (response?.data) {
              toast.success(response?.message);
              setSignupLoader(false);
              setTimeout(() => {
                navigate("/otp-screen");
              }, 3000);
            } else {
              setIsDisabled(false);
              setSignupLoader(false);
            setErrors(response.message); 
            setIsDisabled(false);            
            }
          });
        }
      };
      const onResolved = () => {
        clearTimeout(captchaTimeout);
        setCaptchaToken(recaptchaRef.current.getResponse());
      };
      const [captchaTimeout, setCaptchaTimeout] = useState(null);
      const [captchaToken, setCaptchaToken] = useState("");

      useEffect(() => {
        const timeout = setTimeout(() => {
          console.log("Captcha resolution timed out");
        }, 60000);
        setCaptchaTimeout(timeout);
    
        return () => clearTimeout(timeout);
      }, []);
      const handleFocus = () => {
        // Hide the error message when any input field is focused
        setIsDisabled(false);
        setErrors("");
      };
return(
    <>
       <Card className="m-0 h-100 login_bg">
        <CardBody className="container h-100">
        <Recaptcha
              ref={recaptchaRef}
              sitekey="6Lcid84ZAAAAAMfYOPzXid-iZmoRtyH1pTfAxZiG"
              onResolved={onResolved}
            />
          <Row className="justify-content-center h-100 align-items-center">
            <div className="login_box_wrap">
              <div className="login_box">
                <div className="full_width">
                  <a className="login_logo float-start">
                    {/* <img src={ippopay} alt="" /> */}
                  </a>
                  <div className="d-flex float-end sign_up_left_margin mt-2 ar_float_right">
                    {getCookie("foloosi_lang") == "ar" ? (
                      <a
                        onClick={() => changeLang("en")}
                        className="arabic_a ar_float_right"
                      >
                        English
                      </a>
                    ) : (
                      <a onClick={() => changeLang("ar")} className="arabic_a">
                        العربية
                      </a>
                    )}
                  </div>
                </div>
                <div className="full_width you_acc_margin">
                  <p className="creat_acc_header">{currentLang.fp_lang_188}</p>
                </div>
                <Row className="input_mar">
                  <Col md="12">
                  <div>
                      <div className="form-group">
                        <label className="control_labels_star control_labels margin_sign_up">
                          {" "}
                          {currentLang.fp_lang_7}
                        </label>
                        <Input
                          value={addForm.business_email}
                          onChange={(e) =>
                            handleOnAdddata("business_email", e.target.value)
                          }
                          onFocus={handleFocus}
                          type="text"
                          name="business_email"
                          className="form-control input-lg"
                          placeholder="Enter Business Email"
                        />
                        <div name="Email"></div>
                        {errors && <div className="error">{errors}</div>}
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="full_width">
                  <Button
                  disabled={isDisabled}
                    className="sign_up_button"
                    onClick={(e) => {
                        signInProcess();
                      disableSubmit(e);
                    }}
                  >
                    {currentLang.fp_lang_92}
                  </Button>
                </div>
              </div>
              <div className="tems-login add-form">
                <a href="https://www.foloosi.com/policy" target="_blank" className="ar_float_left">
                  {currentLang?.fp_lang_143}
                </a>
                <a href="https://www.foloosi.com/terms" target="_blank" className="ar_float_left">
                  {currentLang?.fp_lang_144}
                </a>
              </div>
            </div>
          </Row>
        </CardBody>
      </Card>
    </>
)
}

export default Otpresend;