import { ActionTypes } from "../../Constants/ActionTypes";

const blogstate = {
  blogList: [],
  websiteList:[],
  website_id:""
};

const addBlogState = {
  blog_list: [],
  title: "",
  author_name: "",
  blog_category: null,
  blog_description: "",
  blog_content: null,
  blog_image: "",
  blog_website: null,
  blog_websitelist: [],
  blog_categorylist: [],
  category: "",
  tags: [],
  blog_category_id: "",
  blog_keyword: "",
  text: "",
  website_id:"",
  
};

const editBlogState = {
  edit_list: {},
  edit_blog_content:"",
  title: "",
  edit_blog_category_id:"",
  edit_blog_category_name:"",
  edit_blog_categorylist: [],
  edit_author_name: "",
  edit_blog_description: "",
  edit_blog_category: "",
  edit_keywords:"",
  edit_tag_list:"",
  edit_image:"",
  edit_blog_website_name: "",
  edit_blog_website_id:"",
  edit_blog_websitelist: [],
};



const categorystate = {
  categoryList: [],
  websiteList:[],
  blog_website:null,
  category_name: "",
  website_id: "",
  edit_category: "",
  edit_website_name:"",
  id: "",
  blog_websiteModal:"",
  website_idModal:"",
  edit_blog_websiteModal:"",
  edit_website_idModal:"",
  blog_website_select:null

};



export function bloglist(state = blogstate, action) {
  switch (action.type) {
    case ActionTypes.PAGES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export function categorylist(state = categorystate, action) {
  switch (action.type) {
    case ActionTypes.BLOG_CATEGORY:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export function addblog(state = addBlogState, action) {
  switch (action.type) {
    case ActionTypes.BLOG_ADD:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export function editblog(state = editBlogState, action) {
  switch (action.type) {
    case ActionTypes.EDIT_BLOG:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
