import {combineReducers} from "redux";
import {layout, showRight} from "./../Reducers/Layout";
import {signup} from "./../Reducers/Login";
import {bloglist,categorylist,addblog,editblog} from "./../Reducers/Pages"
import { csrlist, csrcategorylist, addcsr, editcsr } from "../Reducers/Pages/Csr";
import {website} from "../Reducers/Pages/Website";
import {dashboardlist} from "../Reducers/Pages/Dashboard"
import { login } from "./../Reducers/Login/log";
const rootReducer = combineReducers({
  layout,
  showRight,
  bloglist,
  categorylist,
  addblog,
  editblog,
  csrlist, 
  csrcategorylist, 
  addcsr, 
  editcsr,
  website,
  dashboardlist,
  signup,
  login
});

export default rootReducer;