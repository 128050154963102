import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes } from "./../Constants/ActionTypes";
import { Link, useLocation } from "react-router-dom";
import ippopay from "../assets/images/logo_new_lg.svg";
import { reloadWindowToPath, removeCookie } from "gfdu";
import ApiGateWay from "../Utils/ApiCall";
import { getCookie, setCookie } from "../Utils/index";
import { enLang, arLang } from "../Constants/en_lang";
import { IdleTimerProvider } from "react-idle-timer";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";

const currentLang = getCookie("foloosi_lang") === "ar" ? arLang : enLang;
const Header = () => {
  const { dashboardlist } = useSelector((state) => state);
  const dispatch = useDispatch();
  const updateState = (actionType, value) => () => {
    dispatch({ type: actionType, payload: value });
    return Promise.resolve();
  };
  const location = useLocation();
  const currentPath = location.pathname;
  function deleteAllCookies() {
    removeCookie("authentication_auth");
  }

  const handlelogout = () => {
    deleteAllCookies();
    reloadWindowToPath("/login");
  };
  useEffect(() => {
    getUserAuth();
  }, []);

  const getUserAuth = () => {
    ApiGateWay.get(`merchant/user`, (response) => {
      if (response?.data) {
        dispatch(
          updateState(ActionTypes.DASHBOARD, {
            valueauth: response?.data?.user,
          })
        );
      }
    });
  };
  const changeLang = (id) => {
    setCookie(id, "foloosi_lang");
    window.location.reload();
  };
  const onIdle = () => {
    toast.error("Your session timed out");
    deleteAllCookies();
    window.location.href = "/login";
  };
  return (
    <React.Fragment>
      <header id="page-topbar">
        <IdleTimerProvider
          timeout={60 * 60 * 1000}
          onIdle={onIdle}
        ></IdleTimerProvider>
        <div className="container">
          <Row className="nav_header_alt">
            <Col xs="6" sm="6" md="3" lg="2">
              <Link to="/">
                <span className="logo-lg">
                  <img src={ippopay} alt="" className="img-filter" />
                </span>
              </Link>
            </Col>
            <Col className="d-lg-none d-md-none " sm="6" xs="6" md="6" lg="3">
              <div className="d-flex float_left_arabic m-0 mt-2 justify-content-end">
                {getCookie("foloosi_lang") == "ar" ? (
                  <a onClick={() => changeLang("en")} className="arabic_a">
                    English
                  </a>
                ) : (
                  <a onClick={() => changeLang("ar")} className="arabic_a">
                    العربية
                  </a>
                )}
                <span className="ms-2 me-1 ">
                  <Link onClick={handlelogout} className="logout border_radius">
                    <i className="bx bx-power-off font-size-16 align-middle me-1 bg_color_white"></i>
                    <span className="me-1">{currentLang.fp_lang_142}</span>
                  </Link>
                </span>
              </div>
            </Col>
            <Col md="6" lg="8">
              <span className="ms-2 me-1 proceed_stage">
                {dashboardlist.businessListInfo?.aggregator_signup_status
                  ?.step_1_status === "pending" &&
                  currentPath !== "/business-details" && (
                    <Link to={`/business-details`}>
                      {currentLang.fp_lang_137}
                    </Link>
                  )}
                {dashboardlist.businessListInfo?.aggregator_signup_status
                  ?.step_1_status === "completed" &&
                  dashboardlist.businessListInfo?.aggregator_signup_status
                    ?.step_2_status === "pending" &&
                  currentPath !== "/business-service" && (
                    <Link to={`/business-service`}>
                      {currentLang.fp_lang_138}
                    </Link>
                  )}
                {dashboardlist.businessListInfo?.aggregator_signup_status
                  ?.step_1_status === "completed" &&
                  dashboardlist.businessListInfo?.aggregator_signup_status
                    ?.step_2_status === "completed" &&
                  dashboardlist.businessListInfo?.aggregator_signup_status
                    ?.step_3_status === "pending" &&
                  currentPath !== "/transaction-information" && (
                    <Link to={`/transaction-information`}>
                      {currentLang.fp_lang_139}
                    </Link>
                  )}
                {dashboardlist.businessListInfo?.aggregator_signup_status
                  ?.step_1_status === "completed" &&
                  dashboardlist.businessListInfo?.aggregator_signup_status
                    ?.step_2_status === "completed" &&
                  dashboardlist.businessListInfo?.aggregator_signup_status
                    ?.step_3_status === "completed" &&
                  dashboardlist.businessListInfo?.aggregator_signup_status
                    ?.step_4_status === "pending" &&
                  currentPath !== "/bank-details" && (
                    <Link to={`/bank-details`}>{currentLang.fp_lang_140}</Link>
                  )}
                {dashboardlist.businessListInfo?.aggregator_signup_status
                  ?.step_1_status === "completed" &&
                  dashboardlist.businessListInfo?.aggregator_signup_status
                    ?.step_2_status === "completed" &&
                  dashboardlist.businessListInfo?.aggregator_signup_status
                    ?.step_3_status === "completed" &&
                  dashboardlist.businessListInfo?.aggregator_signup_status
                    ?.step_4_status === "completed" &&
                  currentPath !== "/proceed-verification" && (
                    <Link to={`/proceed-verification`}>
                      {currentLang.fp_lang_141}
                    </Link>
                  )}
              </span>
            </Col>
            <Col className="d-none d-lg-block d-md-block" md="3" lg="2">
              <div className="d-flex float_left_arabic m-0 mt-2">
                {getCookie("foloosi_lang") == "ar" ? (
                  <a onClick={() => changeLang("en")} className="arabic_a">
                    English
                  </a>
                ) : (
                  <a onClick={() => changeLang("ar")} className="arabic_a">
                    العربية
                  </a>
                )}
                <span className="ms-2 me-1 ">
                  <Link
                    onClick={handlelogout}
                    className="logout border_radius no_wrap"
                  >
                    <i className="bx bx-power-off font-size-16 align-middle me-1 bg_color_white"></i>
                    <span className="me-1">{currentLang.fp_lang_142}</span>
                  </Link>
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </header>
      <div className="container">
        <p className="left_sidebar_head">{currentLang.fp_lang_27}</p>
        <div class="left_part_desc">
          {currentLang.fp_lang_28} {currentLang.fp_lang_29}
        </div>
        <ul className="topStepBar">
          <Link to="/business-details">
            <li
              className={
                currentPath === "/business-details" || currentPath === "/"
                  ? "active"
                  : ""
              }
            >
              <div>
                <span>{currentLang.fp_lang_30}</span>
                <i className="fa fa-info-circle"></i>
                <div className="list_disc">{currentLang.fp_lang_31}</div>
              </div>
            </li>
          </Link>
          <Link to="/business-service">
            <li className={currentPath == "/business-service" ? "active" : ""}>
              <div>
                <span>{currentLang.fp_lang_32}</span>
                <i className="fa fa-info-circle"></i>
                <div className="list_disc">{currentLang.fp_lang_179}</div>
              </div>
            </li>
          </Link>
          <Link to="/transaction-information">
            <li
              className={
                currentPath == "/transaction-information" ? "active" : ""
              }
            >
              <div>
                <span>{currentLang.fp_lang_33}</span>
                <i className="fa fa-info-circle"></i>
                <div className="list_disc">{currentLang.fp_lang_180}</div>
              </div>
            </li>
          </Link>
          <Link to="/bank-details">
            <li className={currentPath == "/bank-details" ? "active" : ""}>
              <div>
                <span>{currentLang.fp_lang_34}</span>
                <i className="fa fa-info-circle"></i>
                <div className="list_disc">{currentLang.fp_lang_181}</div>
              </div>
            </li>
          </Link>

          <li
            className={currentPath == "/proceed-verification" ? "active" : ""}
          >
            <Link to="javascript:void(0)">
              <span>{currentLang.fp_lang_35}</span>
            </Link>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default Header;
