import axios from "axios";
import { getCookie, removeCookie, setCookie } from "../Utils/index";

class ApiCall {
    handleAuthorization = (callback, error) => {
        if (error?.response?.status === 401 || error?.response?.status === 406) {
            removeCookie("authentication_auth");
        } else if (error.response?.status === 422) {
            callback(error.response?.data);
        }
        else {
            callback(error.response?.data);
        }
    };
    get(url, callback) {
        this.createInstance(url, null, 0)
            .then((result) => {
                console.log(result);
                callback(result.data);
            })
            .catch((error) => {
                this.handleAuthorization(callback, error);
            });
    }

    post(url, data, callback) {
        console.log(callback, data);
        this.createInstance(url, data, 1)
            .then((result) => {
                console.log(result);
                callback(result.data);
            })
            .catch((error) => {
                this.handleAuthorization(callback, error);
            });
    }

    patch(url, data, callback) {
        console.log(url, data);
        this.createInstance(url, data, 3)
            .then((result) => {
                callback(result.data);
            })
            .catch((err) => {
                this.handleAuthorization(callback, err);
            });
    }

    delete(url, callback) {
        console.log(url);
        this.createInstance(url, 4)
            .then((result) => {
                callback(result.data);
            })
            .catch((error) => {
                this.handleAuthorization(callback, error);
            });
    }

    createInstance(append, data, type) {
        var instance = axios.create();
        instance.defaults.headers.common["Authorization"] = "Bearer " + getCookie("authentication_auth");
        instance.defaults.headers.common["platform"] = "web";

        var url = "https://api.foloosi.com/onboard/" + append;
        console.log(append, data, type);
        switch (type) {
            case 0:
                return instance.get(url);
            case 1:
                return instance.post(url, data);
            case 2:
                return instance.put(url, data);
            case 3:
                return instance.patch(url, data);
            case 4:
                return instance.delete(url, data);
            default:
                return instance.delete(url, data);
        }
    }
}


export default new ApiCall();
