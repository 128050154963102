import React, { useEffect, useState, useRef } from "react";
import { Button, Card, CardBody, Col, Input, Row } from "reactstrap";
import ApiGateWay from "../../../Utils/ApiCall";
import Select from "react-select";
import { enLang, arLang } from "../../../Constants/en_lang";
import {
  disableSubmit,
  getCookie,
  removeToast,
  toastShow,
} from "../../../Utils/index";
import { useDispatch, useSelector } from "react-redux";
import { ActionTypes } from "../../../Constants/ActionTypes";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader";
export const StepThree = () => {
  const dispatch = useDispatch();
  const updateState = (actionType, value) => () => {
    dispatch({ type: actionType, payload: value });
    return Promise.resolve();
  };
  const [spinLoader, setSpinLoader] = useState(false);
  const currentLang = getCookie("foloosi_lang") === "ar" ? arLang : enLang;
  const Navigate = useNavigate();
  const { dashboardlist } = useSelector((state) => state);
  const [loading, setLoading] = useState(true);
  const [addForm, setaddForm] = useState({});
  const handleOnAdddata = (fieldName, value) => {
    console.log(fieldName, value, "value");
    setaddForm({
      ...addForm,
      [fieldName]: value,
    });
    console.log(addForm);
  };
  const baseCurrency = [
    { value: "", label: "(Choose One)" },
    { value: "AED", label: "AED" },
    { value: "USD", label: "USD" },
  ];
  const selectregion = [
    { value: "", label: "(Choose One)" },
    { value: "UAE", label: "UAE" },
    { value: "GCC", label: "GCC" },
    { value: "Middle East", label: "Middle East" },
    {
      value: "Worldwide except USD & Canada",
      label: "Worldwide except USD & Canada",
    },
    { value: "Worldwide + USA & Canada", label: "Worldwide + USA & Canada" },
  ];

  const [region, setRegion] = useState({
    value:
      dashboardlist?.businessListInfo?.aggregator_transaction_info?.regions ||
      "",
    label:
      dashboardlist?.businessListInfo?.aggregator_transaction_info?.regions ||
      selectregion[0]?.label,
  });
  const [baseCurrencyselect, setBaseCurrencyselect] = useState({
    value:
      dashboardlist?.businessListInfo?.aggregator_transaction_info?.currency ||
      "AED",
    label:
      dashboardlist?.businessListInfo?.aggregator_transaction_info?.currency ||
      "AED",
  });
  const SelectBackground = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#007bff" : "white",
      color: state.isSelected ? "white" : "black",
      cursor: "pointer",
    }),
    menu: (provided, state) => ({
      ...provided,
      maxHeight: "500px",
      zIndex: 9999,
    }),
  };
  useEffect(() => {
    getBusinessinfo();
  }, []);

  console.log(addForm, "setaddForm");

  const handlesubmit = () => {
    removeToast();
    if (!addForm?.annual_volume) {
      toastShow("Please enter your annual income", "annual_volume");
    } else if (isNaN(addForm?.annual_volume)) {
      toastShow("Please enter value in numbers only", "annual_volume");
    } else if (addForm?.annual_volume <= 0) {
      toastShow("Please enter value greater than 0", "annual_volume");
    } else if (!addForm?.avg_transaction_value) {
      toastShow(
        "Please enter your average transaction value",
        "avg_transaction_value"
      );
    } else if (isNaN(addForm?.avg_transaction_value)) {
      toastShow("Please enter value in numbers only", "avg_transaction_value");
    } else if (addForm?.avg_transaction_value <= 0) {
      toastShow("Please enter value greater than 0", "avg_transaction_value");
    } else if (!baseCurrencyselect.value) {
      toastShow("Please enter your base currency", "baseCurrencyselect");
    } else if (!region.value) {
      toastShow("Please enter region", "region");
    } else {
      let data = {
        aggregator_transaction_info_id: addForm?.aggregator_transaction_info_id
          ? addForm?.aggregator_transaction_info_id
          : "",
        merchant_id: dashboardlist?.valueauth?.id,
        annual_income: addForm?.annual_volume,
        transaction_value: addForm?.avg_transaction_value,
        base_currency: baseCurrencyselect?.value,
        regions: region?.value,
      };
      console.log(data, "data");
      // return false
      setSpinLoader(true);
      ApiGateWay.post("transaction-info", data, (response) => {
        console.log(response, "udhaya");
        setSpinLoader(false);
        if (response?.success === 1) {
          toast.success("Transaction Info updated succssfully");
          Navigate("/bank-details");
          getBusinessinfo();
        } else {
          toast.error(response?.response?.data?.message);
        }
      });
    }
  };
  const getBusinessinfo = () => {
    const userNo = dashboardlist.valueauth?.user_no;
    ApiGateWay.get(
      `aggregator-details?q={"user_no":"${userNo}"}`,
      (response) => {
        if (response?.success === 1) {
          dispatch(
            updateState(ActionTypes.DASHBOARD, {
              businessListInfo: response?.data,
              businessData: response?.data?.aggregator_busines,
            })
          );
          setLoading(false);
          setaddForm(response?.data?.aggregator_transaction_info);
        } else {
          toast.error(response.message);
          setLoading(false);
        }
      }
    );
  };
  return (
    <div className="container">
      {loading && <Loader />}
      <div className="float-start arr_full_width">
        <p className="right_part_title arr_full_width">
          {currentLang.fp_lang_113}
        </p>
        <p className="right_part_desc">{currentLang.fp_lang_114}</p>
        <div className="white_box_title">{currentLang.fp_lang_115}</div>
      </div>
      <div className="with_100">
        <Card>
          <CardBody>
            <Row>
              <Col md="6" lg="4" className="mb-4">
                <p className="control_labels control_labels_star">
                  {currentLang.fp_lang_116}
                </p>
                <Input
                  type="text"
                  placeholder="Annual Volume in AED"
                  value={addForm?.annual_volume}
                  onChange={(e) =>
                    handleOnAdddata("annual_volume", e.target.value)
                  }
                  name="annual_volume"
                  id="annual_volume"
                />
              </Col>
              <Col md="6" lg="4" className="mb-4">
                <p className="control_labels control_labels_star">
                  {currentLang.fp_lang_117}
                </p>
                <Input
                  type="text"
                  placeholder="Annual Value in AED"
                  value={addForm?.avg_transaction_value}
                  id="tranction_volume"
                  onChange={(e) =>
                    handleOnAdddata("avg_transaction_value", e.target.value)
                  }
                  name="avg_transaction_value"
                />
              </Col>
              <Col className="mb-4" md="8">
                <p className="control_labels control_labels_star">
                  {currentLang.fp_lang_118}
                </p>

                <Select
                  styles={SelectBackground}
                  options={baseCurrency}
                  onChange={(e) => setBaseCurrencyselect(e)}
                  value={baseCurrencyselect}
                  name="baseCurrencyselect"
                />
              </Col>
            </Row>
            <Row>
              <Col className="mb-4" md="8">
                <p className="control_labels control_labels_star">
                  {currentLang.fp_lang_119}
                </p>

                <Select
                  styles={SelectBackground}
                  options={selectregion}
                  menuPlacement="auto"
                  onChange={(e) => setRegion(e)}
                  value={region}
                  name="region"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
        {!spinLoader ? (
          <Button
            className="submit_process"
            onClick={(e) => {
              handlesubmit();

              disableSubmit(e);
            }}
          >
            {currentLang.fp_lang_92}
          </Button>
        ) : (
          <Button className="submit_process">
            <i class="fa fa-spinner fa-pulse" aria-hidden="true"></i>
          </Button>
        )}
      </div>
    </div>
  );
};
