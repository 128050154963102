import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Toast,
  Button,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiGateWay from "../../Utils/ApiCall";
import {
  disableSubmit,
  getCookie,
  removeToast,
  setCookie,
  toastShow,
} from "../../Utils";
import { Link } from "react-router-dom";
import { reloadWindowToPath } from "gfdu";
import { enLang, arLang } from "../../Constants/en_lang";
import ippopay from "./../../assets/images/logo.png";

function SecureLogin() {
  const [hidden, setHidden] = useState(false);
  const currentLang = getCookie("foloosi_lang") === "ar" ? arLang : enLang;
  const [addForm, setaddForm] = useState({});
  const [checkIcon, setCheckIcon] = useState(false);
  const handleOnAdddata = (fieldName, value) => {
    setaddForm({
      ...addForm,
      [fieldName]: value,
    });
    console.log(addForm);
  };
  const handleLogin = async (e) => {
    setCheckIcon(false);
    removeToast();
    e.preventDefault();
    var data = {
      user_no: addForm.email_address,
      account_access_code: addForm.password,
      platform: "web",
    };
    console.log(data, "data");
    if (!addForm.email_address) {
      toastShow("please Merchant ID", "email_address");
    } else if (!addForm.password) {
      setCheckIcon(true);
      toastShow("Please Enter Password", "password");
    } else {
      ApiGateWay.post("secure/login", data, (response) => {
        if (response?.data) {
          let { authentication } = response.data;
          setCookie(authentication, "authentication_auth");
          reloadWindowToPath("/business-details");
        } else {
          toast.error(response?.response?.data?.message);
        }
      });
    }
  };
  return (
    <>
      <Card className="m-0 h-100 login_bg">
        <CardBody className="container h-100">
          <Row className="justify-content-center h-100 align-items-center">
            <div className="login_box_wrap">
              <div className="login_box">
                <div className="full_width">
                  <a className="login_logo float-start">
                    <img src={ippopay} alt="" />
                  </a>
                </div>
                <div className="full_width you_acc_margin">
                  <p className="creat_acc_header">Secure Merchant Login</p>
                  <p className="create_acc_p_process">
                  Sign in to your account
                  </p>
                </div>
                <Row className="input_mar">
                  <Col md="12">
                    <div>
                      <div className="form-group">
                        <label className="ar_float_right">Merchant ID</label>
                        <input
                          type="text"
                          className="form-control input-lg"
                          placeholder="Enter the Merchant ID"
                          value={addForm.email_address}
                          onChange={(e) =>
                            handleOnAdddata("email_address", e.target.value)
                          }
                          name="email_address"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="input_mar">
                  <Col md="12">
                    <div>
                      <div className="form-group">
                        <label className="ar_float_right">Password</label>
                        <input
                          type={hidden ? "text" : "password"}
                          className="form-control input-lg"
                          placeholder="Enter the password"
                          value={addForm.password}
                          onChange={(e) =>
                            handleOnAdddata("password", e.target.value)
                          }
                          name="password"
                        />
                        {hidden ? (
                          <i
                            class={`hide_show fa fa-eye ${
                              checkIcon ? "hide_show_pos" : ""
                            }`}
                            aria-hidden="true"
                            onClick={() => setHidden(!hidden)}
                          ></i>
                        ) : (
                          <i
                            class={`hide_show fa fa-eye-slash ${
                              checkIcon ? "hide_show_pos" : ""
                            }`}
                            onClick={() => setHidden(!hidden)}
                          />
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="full_width">
                  <Button
                    className="sign_up_button"
                    onClick={(e) => {
                      handleLogin(e);
                      disableSubmit(e);
                    }}
                  >
                  LOGIN
                  </Button>
                </div>
              </div>
              <div className="tems-login add-form">
                <a
                  href="https://www.foloosi.com/policy"
                  target="_blank"
                  className="ar_float_left"
                >
                  Privacy Policy
                </a>
                <a
                  href="https://www.foloosi.com/terms"
                  target="_blank"
                  className="ar_float_left"
                >
                  Terms and condition
                </a>
              </div>
            </div>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
export default SecureLogin;
