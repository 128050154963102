import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookie from "cookie-universal";
const cookies = Cookie();

export const isLoggedIn = () => {
  return getAuth();
};

export const getAuth = () => {
  return cookies.get("authentication_auth") ? true : false;
};
export const setCookie = (response, key) => {
  cookies.set(key, response, {
    path: "/",
  });
};
export const removeCookie = (key) => {
  cookies.remove(key, { path: "/" });
};
export const getCookie = (key) => {
  return cookies.get(key);
};
export const showToast = (type, msg) => {
  return toast[type](msg);
};
export function returnFirstDegreeObjValue(obj, key) {
  return obj && (obj[key] || obj[key] === 0) ? obj[key] : "N/A";
}

export function returnThirdDegreeObjValue(masterobj, obj, key, postion) {
  if (
    masterobj &&
    masterobj[obj] &&
    masterobj[obj][key] &&
    (masterobj[obj][key][postion] || masterobj[obj][key][postion] === 0)
  )
    return masterobj[obj][key][postion];
  else return "N/A";
}
export function returnSecondDegreeObjValue(obj, key, postion) {
  if (obj && obj[key] && (obj[key][postion] || obj[key][postion] === 0))
    return obj[key][postion];
  else return "N/A";
}
export function returnSimpleFormatedDateTime(dateString) {
  return dateString === ""
    ? ""
    : moment(dateString).format("DD MMM YYYY h:mm a");
}
export const isInvalidName = (value) => {
  let nameRegex = /^[a-zA-Z ]+$/;
  if (!nameRegex.test(value)) {
    return true;
  } else {
    return false;
  }
};
export const isInvalidEmail = (email) => {
  let eRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!eRegex.test(email)) return true;
  else return false;
};

export function textCapitalize(data) {
  if (data !== undefined && data !== null && data !== "") {
    return data.charAt(0).toUpperCase() + data.slice(1);
  } else {
    return data;
  }
}
export function currencyFormatter(amount, code) {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: code,
  }).format(amount);
}
export function returnFixed(value) {
  const priceRegex = /[\d]+\.[\d]+/;

  if (priceRegex.test(value)) {
    const price = parseFloat(value);
    return price;
  } else if (value === "" || isNaN(value)) {
    return 0;
  } else {
    const price = parseFloat(value);
    const newPrice =
      "" +
      Math.round(price * 100) / 100 +
      (value?.indexOf(".") === value?.length - 1 ? "." : "");
    return newPrice;
  }
}
export function disableSubmit(e) {
  e.target.classList.add("disabled-btn");
  setTimeout(() => {
    e.target.classList.remove("disabled-btn");
  }, [1000]);
}
export const removeToast = () => {
  document
    .querySelectorAll(".toast-error-foloosi")
    .forEach((el) => el.remove());
  const focusedElements = document.querySelectorAll(".focused");
  focusedElements.forEach((el) => {
    el.classList.remove("focused");
  });
};

export const toastShow = (msg, id, index) => {
  console.log(msg, id, index, "udhayakumar");
  if (index !== undefined) {
    // alert("hii");
    const errSection = document.createElement("div");
    errSection.classList.add("toast-error-foloosi");
    errSection.innerHTML = msg;
    const inputFields = document.getElementsByName(id);
    if (inputFields && inputFields.length > index) {
      inputFields[index].insertAdjacentElement("afterend", errSection);
      inputFields[index].classList.add("focused");
      inputFields[index].focus();
    }
  } else {
    const errSection = document.createElement("div");
    errSection.classList.add("toast-error-foloosi");
    errSection.innerHTML = msg;
    document
      .getElementsByName(id)[0]
      .insertAdjacentElement("afterend", errSection);
    const inputElement = document.getElementsByName(id)[0];
    inputElement.focus();
    inputElement.classList.add("focused");
  }
};
